import React, { Component, Fragment } from 'react';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import formated_timezones from '../assets/json/formated_timezones.json';
import UploadImage from '../components/image-upload';
import { userRegister } from '../services/userRegister';
import AccountTiktok from '../moduleFiles/accountTiktok';
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import AccountTwitter from '../moduleFiles/accountTwitter';
import AccountGoogle from '../moduleFiles/accountGoogle';
import AccountFacebook from '../moduleFiles/accountFacebook';
import AccountLinkedin from '../moduleFiles/accountLinkedin';
import AccountGoogleAnalytics from '../moduleFiles/accountGoogleAnalytics';
import AccountAdform from '../moduleFiles/accountAdform';
import AccountGoogleAnalytics4 from '../moduleFiles/accountGoogleAnalytics4';
import AccountSnapchat from "../moduleFiles/accountSnapchat";
import AccountBing from '../moduleFiles/accountBing';
import AccountDv360 from '../moduleFiles/accountDv360';
import AccountBidtheatre from '../moduleFiles/accountBidtheatre';
import phone_codes from '../assets/json/phone-countries.json';
import TagManager from '../moduleFiles/tagManager';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import AccountGoogleSearchConsole from '../moduleFiles/accountGoogleSearchConsole';
import MultiDropdown from '../moduleFiles/multiDropdownTailwind';
import { Transition, Menu } from '@headlessui/react'
import { createChangelog } from '../services/createChangelog';
import SlideDown from 'react-slidedown';
var moment = require('moment');

class Client extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            loading: true,
            timezones: formated_timezones,
            view: 'overview',
            agents: [],
            loading_agents_search: "",
            throttling: {},
            phone_error: false,
            phone_exists: false,
            phone_codes: phone_codes,
            tab: { id: 1, name: "Information" },
            tabs: [
                { id: 1, name: "Information" },
                //{ id: 5, name: "Actions" },
                //{ id: 2, name: "Agents" },
                { id: 3, name: "Platform connections" },
                //{ id: 4, name: "Email settings" },
                //{ id: 4, name: "Settings" }
                { id: 5, name: "Change logs" }
            ],
            languages: [
                { id: 1, name: "English", value: "en" },
                { id: 2, name: "Svenska", value: "sv" },
                { id: 3, name: "Dansk", value: "dk" },
                { id: 4, name: "Norsk", value: "no" }
            ],
            language: { id: 0, name: "Select..." },
            days: [
                { id: 1, name: "Monday", value: "monday" },
                { id: 2, name: "Tuesday", value: "tuesday" },
                { id: 3, name: "Wednesday", value: "wednesday" },
                { id: 4, name: "Thursday", value: "thursday" },
                { id: 5, name: "Friday", value: "friday" },
                { id: 6, name: "Saturday", value: "saturday" },
                { id: 7, name: "Sunday", value: "sunday" }
            ],
            scheduler: {},
            show_pause_modal: false,
            loading_button: {},
            show_delete_modal: false,
            // CHANGELOG STUFF
            changelog_loading: {
                logs: true,
            },
            changelog_error: { message: "", show: false },
            sort: "asc",
            logs: [],
            meta: {},
            page: 1,
            limit: 10,
            search: "",
            advancedFilters: [
                {
                    multiSelect: true,
                    maxWidth: true,
                    type: "channel",
                    placeholder: "All channels (default)",
                    defaultOptions: [],
                    defaultSelected: { id: 0, name: "All channels (default)", value: "all" },
                    options: [],
                    selected: []
                },
            ],
            initial_data: {},
            updated_data: {},
            pause_all_campaigns: { id: 1, name: "Yes, pause everything", value: true },
            log_index: null
        };
    };

    async componentDidMount() {
        this.functions.getUser();
        this.functions.getExternalLogs();
    }

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
            try {
                let response = await this.calls.getUser();
                await this.promisedSetState({
                    user: { ...this.state.user, ...response.data }
                });
            } catch (error) { }
            this.functions.getClient(window.location.pathname.replace("/v2/client/", ""));
        },
        getClient: (id) => {
            this.calls.getClient(id).then((response) => {
                if (!response.data.scheduler) {
                    response.data.scheduler = { enabled: false, frequency: { id: 0, name: "Select ..." }, day: { id: 0, name: "Select ..." } };
                }
                this.setState({
                    client: response.data,
                    loading: false,
                    initial_data: JSON.parse(JSON.stringify(response.data))
                })
            }, (error) => { });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        },
        update: async () => {
            await this.promisedSetState({
                loading_update: true
            });
            try {
                let res = await this.calls.updateClient();
                if (res.status === 200) {
                    this.functions.handleCreateChangelog()
                }
            } catch (error) {
                if (error.body && error.body.message && error.body.message === "Phone number already in use") {
                    await this.promisedSetState({
                        phone_error: true,
                        phone_exists: true
                    });
                }
            }
            await this.promisedSetState({
                loading_update: false
            });
        },
        udpdateAction: async (item) => {
            await this.promisedSetState({
                loading_update: true
            });
            try {
                await this.calls.updateAction(item);
            } catch (error) { }
            await this.promisedSetState({
                loading_update: false
            });
        },
        removeAction: async (item) => {
            await this.promisedSetState({
                loading_update: true
            });
            try {
                await this.calls.removeAction(item);
                this.state.client.actions = this.state.client.actions.filter((inner_item) => { return inner_item.id !== item.id });
                await this.promisedSetState({
                    client: this.state.client
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_update: false
            });
        },
        showPauseModal: async () => {
            await this.promisedSetState({
                show_pause_modal: true
            });
        },
        updateStatus: async (status) => {
            await this.promisedSetState({
                loading_button: {
                    pause_client: status === 'disabled',
                    activate_client: status === 'active',
                    archive_client: status === 'archived'
                }
            });
            try {
                let res;
                if (status === "paused") {
                    this.state.client.status = "disabled";
                    await this.calls.pause(this.state.client.id, { status: "disabled", pause_campaigns: this.state.pause_all_campaigns.value });
                } else {
                    this.state.client.status = status;
                    res = await this.calls.updateClient();
                }
                await this.promisedSetState({ client: this.state.client, show_pause_modal: false });
                if (res.status === 200) {
                    this.functions.handleCreateChangelog();
                }

                await this.promisedSetState({ client: this.state.client, show_pause_modal: false, pause_all_campaigns: { id: 1, name: "Yes, pause everything", value: true } });
            } catch (error) {
                console.log(error)
            }
            await this.promisedSetState({
                loading_button: {
                    pause_client: false,
                    activate_client: false,
                    archive_client: false,
                    pause_all_campaigns: { id: 1, name: "Yes, pause everything", value: true }
                }
            });
            if (this.props.updateStatistics) {
                this.props.updateStatistics();
            }
        },
        showDeleteModal: async () => {
            await this.promisedSetState({
                show_delete_modal: true
            });
        },
        delete: async () => {
            try {
                await this.calls.delete(this.state.client.id);
                this.props.history.push("/v2/clients/active");
            } catch (error) {
                console.log(error)
            }
            if (this.props.updateStatistics) {
                this.props.updateStatistics();
            }
        },

        // CHANGELOG STUFF
        getExternalLogs: async () => {
            this.state.changelog_loading.logs = true;
            await this.promisedSetState({
                changelog_loading: this.state.changelog_loading
            });
            try {
                let response;
                let id = window.location.pathname.split('/').pop();
                if (id !== "") {
                    response = await this.calls.getExternalLogs(id, "client");
                    if (response.data && response.data.length > 0) {
                        this.state.logs = response.data;
                        let channels = [
                            { id: 1, name: "All channels (default)", value: "all" },
                            { id: 2, name: "Adcredo", value: "adcredo" }
                        ]
                        response.data.map((log) => {
                            if (log.channel && log.channel !== "" && log.channel !== "adcredo" && log.channel !== "linkedin" && log.channel !== "tiktok" && log.channel !== "twitter") {
                                if (!channels.some(channel => channel.value === log.channel.toLowerCase())) {
                                    channels.push({ id: channels.length + 1, name: log.channel.charAt(0).toUpperCase() + log.channel.slice(1), value: log.channel.toLowerCase() });
                                }
                            }
                        })
                        this.state.advancedFilters[0].options = channels
                    }
                    await this.promisedSetState({
                        logs: this.state.logs,
                        advancedFilters: this.state.advancedFilters
                    });
                    this.functions.calcTotal();
                    this.functions.sortLogs();
                }
            } catch (error) {
                console.log(error)
                this.state.changelog_error.message = error.message;
                this.state.changelog_error.show = true;
                await this.promisedSetState({
                    changelog_error: this.state.changelog_error
                });
            }
            this.state.changelog_loading.logs = false;
            await this.promisedSetState({
                changelog_loading: this.state.changelog_loading
            });
        },
        sortLogs: async () => {
            await this.promisedSetState({
                sort: this.state.sort === "asc" ? "desc" : "asc"
            });

            let sortedLogs = [...this.state.logs];

            if (this.state.sort === "asc") {
                sortedLogs.sort((a, b) => {
                    return new Date(a.date) - new Date(b.date);
                });
            } else {
                sortedLogs.sort((a, b) => {
                    return new Date(b.date) - new Date(a.date);
                });
            }

            await this.promisedSetState({
                logs: sortedLogs
            });
        },
        calcTotal: async () => {
            if (Array.isArray(this.state.logs) && this.state.logs.length > 0) {
                let filteredLogs = this.state.logs;

                if (this.state.advancedFilters[0].selected.length > 0) {
                    filteredLogs = filteredLogs.filter((item) => Array.isArray(this.state.advancedFilters[0].selected) && this.state.advancedFilters[0].selected.length > 0 ? this.state.advancedFilters[0].selected.map(option => option.value && option.value.toLowerCase()).some(value => item.channel && value === item.channel.toLowerCase()) : true);
                }

                if (this.state.search) {
                    filteredLogs = filteredLogs.filter((log) => Object.values(log).some(value => String(value).toLowerCase().includes(this.state.search.toLowerCase())));
                }

                this.state.meta.total = filteredLogs.length;
            }

            await this.promisedSetState({
                meta: this.state.meta
            })
        },
        handleCreateChangelog: async (action) => {
            try {
                this.state.updated_data = this.state.client
                if (action === "removed") {
                    this.state.updated_data.status = action
                }
                createChangelog({ initial_data: this.state.initial_data, updated_data: this.state.updated_data, client: this.state.client.id, order: null, item: this.state.client.id, level: "client" });
            } catch (error) { }

        }

    };

    renders = {
        countryCode: () => {
            if (this.state.client.country_code) {
                let buff = this.state.phone_codes.filter(country => {
                    return country.code === this.state.client.country_code
                })
                if (buff.length > 0) {
                    return { name: `${buff[0].iso} (${buff[0].code})`, value: buff[0].code, id: buff[0].code }
                } else {
                    return { id: 0, name: "Select..." }
                }
            } else {
                return { id: 0, name: "Select..." }
            }
        },
        language: () => {
            try {
                if (this.state.client && this.state.client.language) {
                    return this.state.client.language
                } else if (this.state.language.id !== 0) {
                    return this.state.language;
                } else {
                    return { id: 0, name: "Select ..." }
                }
            } catch (error) {
                console.log(error);
            }
        },
        frequency: () => {
            try {
                if (this.state.client && this.state.client.scheduler.frequency) {
                    return this.state.client.scheduler.frequency
                } else {
                    return { id: 0, name: "Select ..." }
                }
            } catch (error) {
                return { id: 0, name: "Select ..." }
            }
        },
        day: () => {
            try {
                if (this.state.client && this.state.client.scheduler.day) {
                    return this.state.client.scheduler.day
                } else {
                    return { id: 0, name: "Select ..." }
                }
            } catch (error) {
                return { id: 0, name: "Select ..." }
            }
        },
        status: () => {
            try {
                if (this.state.client && (!this.state.client.status || this.state.client.status == "active")) {
                    return { id: "active", name: "Active", value: "active" }
                } else if (this.state.client && this.state.client.status == "archived") {
                    return { id: "archived", name: "Archived", value: "archived" }
                } else if (this.state.client && this.state.client.status == "disabled") {
                    return { id: "disabled", name: "Paused", value: "disabled" }
                }
            } catch (error) {
                return {}
            }
        },
        pauseAllCampaigns: (value) => {
            try {
                if (value) {
                    return value;
                } else if (this.state.pause_all_campaigns) {
                    return this.state.pause_all_campaigns;
                } else {
                    return { id: 1, name: "Yes, pause everything", value: true }
                }
            } catch (error) {
                return { id: 0, name: "Select ..." }
            }
        },

        // CHANGELOG STUFF
        time: (log) => {
            try {
                if (log.date) {
                    return moment(log.date).format("HH:mm");
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        date: (log) => {
            try {
                if (log.date) {
                    return moment(log.date).format("DD/MM/YYYY");
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        user: (log) => {
            try {
                if (log.user) {
                    return log.user.charAt(0).toUpperCase() + log.user.slice(1);
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        icon: (log) => {
            try {
                if (log.channel) {
                    return (
                        <div>
                            {
                                log.channel === "google" &&
                                <div
                                    className="bg-google-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/google_icon.svg')} />
                                </div>
                            }
                            {
                                log.channel === "google_shopping" &&
                                <div
                                    className="bg-googleshopping-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/google-shopping.png')} />
                                </div>
                            }
                            {
                                log.channel === "linkedin" &&
                                <div
                                    className="bg-linkedin-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/linkedin_icon.svg')} />
                                </div>
                            }
                            {
                                log.channel === "facebook" &&
                                <div
                                    className="bg-facebook-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img style={{ width: '0.4rem' }}
                                        src={require('../assets/images/facebook_icon.svg')} />
                                </div>
                            }
                            {
                                log.channel === "google_analytics" &&
                                <div
                                    className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/google_analytics_icon.png')} />
                                </div>
                            }
                            {
                                log.channel === "google_analytics_4" &&
                                <div
                                    className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/ga_icon.png')} />
                                </div>
                            }
                            {
                                log.channel === "adform" &&
                                <div
                                    className="bg-adform-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/adform_icon.png')} />
                                </div>
                            }
                            {
                                log.channel === "twitter" &&
                                <div
                                    className="bg-twitter-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/twitter_icon.svg')} />
                                </div>
                            }
                            {
                                log.channel === "tiktok" &&
                                <div
                                    className="bg-tiktok-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/tiktok_icon.png')} />
                                </div>
                            }
                            {
                                log.channel === "bidtheatre" &&
                                <div
                                    className="bg-bidtheatre-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/bidtheatre_icon.png')} />
                                </div>
                            }
                            {
                                log.channel === "snapchat" &&
                                <div
                                    className="bg-snapchat-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/snapchat.svg')} />
                                </div>
                            }
                            {
                                log.channel === "bing" &&
                                <div
                                    className="bg-bing-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/bing-logo.png')} />
                                </div>
                            }
                            {
                                log.channel === "adcredo" &&
                                <div
                                    className="bg-white mr-2 rounded-full flex justify-center align-middle items-center">
                                    <img style={{ width: '2rem' }}
                                        src={require('../assets/images/adcredo_icon_purple.png')} />
                                </div>
                            }
                        </div>
                    )
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        channel: (log) => {
            try {
                if (log.channel !== undefined) {
                    return log.channel.charAt(0).toUpperCase() + log.channel.slice(1);
                } else {
                    return "Adcredo";
                }
            } catch (error) {
                return "";
            }
        },
        objectType: (log) => {
            try {
                if (log.object_type) {
                    let buff = log.object_type.replace(/_/g, " ");
                    return buff.charAt(0).toUpperCase() + buff.slice(1).toLowerCase();
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        objectName: (log) => {
            try {
                if (log.object_name) {
                    return log.object_name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        objectId: (log) => {
            try {
                if (log.id) {
                    return log.id;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        action: (log) => {
            try {
                if (log.action) {
                    return log.action.charAt(0).toUpperCase() + log.action.slice(1).toLowerCase();
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        details: (log) => {
            try {
                if (!log.internal) {
                    if (log.details) {
                        let raw = JSON.parse(log.details);
                        let changes = []
                        if (raw.type === "run_status" && typeof raw.old_value === "string" && typeof raw.new_value === "string") {
                            changes.push(<span className="text-xs">Old: {raw.old_value.charAt(0).toUpperCase() + raw.old_value.toLowerCase().slice(1)}, New: {raw.new_value.charAt(0).toUpperCase() + raw.new_value.toLowerCase().slice(1)}</span>);
                        } else if (raw.old_value && raw.new_value === null) {
                            changes.push(<span className="text-xs">Old: {((raw.old_value) / 100)}, New: Removed</span>);
                        } else if (raw.old_value === "" && raw.new_value) {
                            changes.push(<span className="text-xs">New: {raw.new_value}</span>);
                        } else if (!raw.old_value && raw.new_value) {
                            let buff = raw.new_value.replace(/_/g, " ");
                            buff = buff.charAt(0).toUpperCase() + buff.slice(1).toLowerCase();
                            changes.push(<span className="text-xs">New: {buff}</span>);
                        } else if (raw && raw.new_value && raw.new_value.currency) {
                            if (raw.new_value.new_value && raw.old_value.old_value) {
                                changes.push(<span className="text-xs">Old: {((raw.old_value.old_value) / 100)}, New: {(raw.new_value.new_value / 100)}</span>);
                            } else if (raw.new_value.new_value && !raw.old_value) {
                                changes.push(<span className="text-xs">New: {raw.new_value.new_value}</span>);
                            }
                        } else if (raw.old_value && raw.new_value) {
                            let buff_old = raw.old_value.replace(/_/g, " ");
                            buff_old = buff_old.charAt(0).toUpperCase() + buff_old.slice(1).toLowerCase();
                            let buff_new = raw.new_value.replace(/_/g, " ");
                            buff_new = buff_new.charAt(0).toUpperCase() + buff_new.slice(1).toLowerCase();
                            changes.push(<span className="text-xs">Old: {buff_old}, New: {buff_new}</span>);
                        } else if (raw.campaign_id) {
                            changes.push(<span className="text-xs">Old campaign ID: {raw.campaign_id.mutation_input}, new campaign ID: {raw.campaign_id.new}</span>);
                        } else {
                            return "";
                        }

                        let first_two = changes.slice(0, 2);

                        return {
                            first_two,
                            changes,
                            seeMore: changes.length > 2 ? <span className='text-sm text-gray-600 whitespace-no-wrap'>See more ...</span> : null
                        };

                    } else {
                        return "";
                    }
                } else {
                    let raw = JSON.parse(log.details);
                    let changes = raw
                        .filter(change => change.object_type !== 'loading')
                        .map((change, index) => {
                            if (log.action === "remove") {
                                if (change.object_type === "keyword (adgroup)" || change.object_type === "keyword (campaign)") {
                                    return (
                                        <div key={index}>
                                            <div className="text-xs"><span className='font-semibold'>Removed: </span>{change.new_value}</div>
                                        </div>
                                    );
                                    // REMOVED BUDGET ITEM
                                } else if (change.old_value.budget) {
                                    return (
                                        <div key={index}>
                                            <div className="text-xs"><span className='font-semibold'>Removed: </span>{change.old_value.reference} (budget item)</div>
                                        </div>
                                    );
                                }
                            }
                            let old_value = typeof change.old_value === 'string' ? change.old_value.charAt(0).toUpperCase() + change.old_value.toLowerCase().slice(1) : change.old_value;
                            let new_value = typeof change.new_value === 'string' ? change.new_value.charAt(0).toUpperCase() + change.new_value.toLowerCase().slice(1) : JSON.stringify(change.new_value);
                            let objectType = change.object_type.replace(/_/g, ' ');
                            let changeString = (
                                <div className='text-xs'>
                                    <span className="font-semibold">{objectType && objectType.charAt(0).toUpperCase() + objectType.toLowerCase().slice(1) + ": "}</span>
                                    {
                                        change.old_value !== null &&
                                        <span> Old: {String(old_value)}, </span>
                                    }
                                    {
                                        change.new_value !== null &&
                                        <span className="">New: {String(new_value)}</span>
                                    }
                                </div>
                            );
                            return (
                                <div key={index}>
                                    {changeString}
                                </div>
                            );
                        });

                    let first_two = changes.slice(0, 2);

                    return {
                        first_two,
                        changes,
                        see_more: changes.length > 2 ? <span className='text-xs text-gray-600 whitespace-no-wrap'>See more ...</span> : null
                    };

                }
            } catch (error) {
                return "";
            }
        }

    };

    calls = {
        getUser: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgent";
            return apiRegister.call(options, url);
        },
        getClient: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getClient?client=" + id;
            return apiRegister.call(options, url);
        },
        updateClient: () => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', this.state.client);
            let url = apiRegister.url.api + "/v3/adcredo/updateClient?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        pause: (id, data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateClient?client=" + id;
            return apiRegister.call(options, url);
        },
        updateAction: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAction?action=" + data.id;
            return apiRegister.call(options, url);
        },
        removeAction: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAction?action=" + data.id;
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        },
        delete: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeClient?client=" + id;
            return apiRegister.call(options, url);
        },

        // CHANGELOG STUFF
        getExternalLogs: (id, type) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExternalChangelog?client=" + id + "&type=" + type;
            return apiRegister.call(options, url);
        },
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="relative h-full flex flex-col">

                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === 'paused') {
                                this.functions.showPauseModal();
                            } else if (type === 'archived') {
                                this.functions.updateStatus('archived');
                            } else if (type === 'active') {
                                this.functions.updateStatus('active');
                            } else if (type === 'delete') {
                                this.functions.showDeleteModal();

                            }
                        }}
                        onSubtab={(tab) => {
                            this.setState({
                                tab: tab
                            })
                        }}
                        hideUserDropdown={true}
                        history={this.props.history}
                        showClient={true}
                        lockClient={true}
                        client={this.state.client}
                        subtab={this.state.tab}
                        show_subtabs={true}
                        subtabs={this.state.tabs.filter((item) => {
                            if (!this.state.user.manager) {
                                return item.id !== 2 && item.id !== 4
                            } else {
                                return true;
                            }
                        })}
                        buttonTypes={{
                            pause_client: !this.state.loading && this.state.client && (!this.state.client.status || this.state.client.status == "active"),
                            activate_client: !this.state.loading && this.state.client && (this.state.client.status === "disabled" || this.state.client.status === "archived"),
                            archive_client: !this.state.loading && this.state.client && this.state.client.status === "disabled",
                            delete_client: !this.state.loading && this.state.client && this.state.client.status === "archived"
                        }}
                        buttonLoaders={this.state.loading_button}

                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading Client ...</div>
                    </div>
                }

                {/*PAUSE MODAL*/}
                <WarningModalTailwind
                    open={this.state.show_pause_modal ? true : false}
                    title={"Pause"}
                    description={'Are you sure you want to pause this client? Related orders and campaigns will be paused by default.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Pause"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ show_pause_modal: false, pause_all_campaigns: { id: 1, name: "Yes, pause everything", value: true } });
                    }}
                    onSubmit={(value) => {
                        this.functions.updateStatus('paused');
                    }}
                    loading={this.state.loading_button ? this.state.loading_button.pause_client : false}
                    dropDown={true}
                    dropDownOptions={[{ id: 1, name: "Yes, pause everything", value: true }, { id: 2, name: "No, don't pause everything", value: false }]}
                    dropDownSelectedRender={this.renders.pauseAllCampaigns()}
                    onDropDownSelect={async (value) => {
                        this.state.pause_all_campaigns = value
                        await this.promisedSetState({ pause_all_campaigns: this.state.pause_all_campaigns });
                    }}
                />

                {/*DELETE MODAL*/}
                <WarningModalTailwind
                    open={this.state.show_delete_modal ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Delete"}
                    disableSubmitButton={!this.state.show_delete_modal || (this.state.remove_name != this.state.client.name)}
                    showInput={true}
                    inputTitle={"Type clients name to delete"}
                    copyInput={this.state.show_delete_modal ? this.state.client.name : ""}
                    inputError={(!this.state.show_delete_modal || (this.state.remove_name != this.state.client.name)) ? "Name does not match" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ show_delete_modal: false });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.show_delete_modal && this.state.remove_name == this.state.client.name) {
                            this.functions.handleCreateChangelog("removed");
                            this.functions.delete();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                    onSetTags={async (value) => {
                        this.functions.handleSetTags(value)
                    }}
                    showTagsDropdown={true}
                />

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        {
                            this.state.tab.id == 1 &&
                            <div className="grid grid-cols-12 gap-4">
                                <div className="shadow bg-white rounded-lg w-full h-full pb-4 col-span-6">
                                    <div className="flex items-center justify-between py-5 px-4 sm:px-6 border-b font-semibold text-sm">
                                        Client information
                                    </div>
                                    <div className="py-5 px-4 sm:px-6 pb-0">
                                        <div className="grid grid-cols-12 gap-6">
                                            {
                                                !this.state.client.timezone &&
                                                <div className="col-span-12 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100">
                                                    OBS! Select <b>Timezone</b>, system default is Europe/Stockholm.
                                                </div>
                                            }
                                            <div className='col-span-12 w-full'>
                                                <TagManager
                                                    client={this.state.client}
                                                    label={"Add tag"}
                                                    tags={this.state.client.tags ? this.state.client.tags : []}
                                                    tagType={"client"}
                                                />
                                            </div>
                                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                                <div className={`col-span-6`}>
                                                    <DropdownTailwind
                                                        label={"Status"}
                                                        locked={true}
                                                        small={false}
                                                        skipInternalSearch={false}
                                                        searchInput={true}
                                                        placeholder={"..."}
                                                        selected={this.state.client.status ? this.renders.status() : { id: "active", name: "Active", value: "active" }}
                                                        options={[
                                                            { id: "active", name: "Active", value: "active" },
                                                            { id: "archived", name: "Archived", value: "archived" },
                                                            { id: "disabled", name: "Paused", value: "disabled" }
                                                        ]}
                                                        onChange={async (option) => {
                                                            this.state.client.status = option.value;
                                                            await this.promisedSetState({
                                                                client: this.state.client
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        label={"Name"}
                                                        error={this.state.client.name === "" && this.state.error}
                                                        errorMessage={"Name is not valid"}
                                                        value={this.state.client.name}
                                                        onChange={(value) => {
                                                            this.state.client.name = value;
                                                            this.setState({ client: this.state.client })
                                                        }}
                                                    />
                                                </div>
                                                <div className={`col-span-12`}>
                                                    <UploadImage
                                                        title={"Upload logo (png)"}
                                                        size={"full"}
                                                        cover={false}
                                                        logo={true}
                                                        value={this.state.client.logo}
                                                        client={this.state.client.id}
                                                        onChange={(url) => {
                                                            this.state.client.logo = url;
                                                            this.setState({
                                                                client: this.state.client
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Timezone"}
                                                        small={false}
                                                        skipInternalSearch={false}
                                                        searchInput={true}
                                                        placeholder={"..."}
                                                        selected={this.state.client.timezone ? { name: this.state.client.timezone, value: this.state.client.timezone } : { id: 0, name: "Select timezone" }}
                                                        options={this.state.timezones.map((item) => {
                                                            return item.utc.map((item) => {
                                                                return { name: item, value: item };
                                                            });
                                                        }).flat()}
                                                        onChange={async (option) => {
                                                            this.state.client.timezone = option.value;
                                                            await this.promisedSetState({
                                                                client: this.state.client
                                                            });
                                                        }}
                                                        onPagination={async () => {

                                                        }}
                                                        onSearch={async (value) => {

                                                        }}
                                                    />
                                                </div>
                                                <div className={`col-span-6`}>
                                                    <DropdownTailwind
                                                        label={"Default language"}
                                                        selected={this.renders.language()}
                                                        options={this.state.languages}
                                                        onChange={async (language) => {
                                                            this.state.client.language = language;
                                                            await this.promisedSetState({
                                                                language: language
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className={`col-span-6`}>
                                                    <InputTailwind
                                                        label={"Website"}
                                                        value={this.state.client.website}
                                                        onChange={(value) => {
                                                            this.state.client.website = value;
                                                            this.setState({ client: this.state.client })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        label={"Company"}
                                                        error={this.state.client.name === "" && this.state.error}
                                                        errorMessage={"Company is not valid"}
                                                        value={this.state.client.company}
                                                        onChange={(value) => {
                                                            this.state.client.company = value;
                                                            this.setState({ client: this.state.client })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        label={"Email"}
                                                        value={this.state.client.email}
                                                        onChange={(value) => {
                                                            this.state.client.email = value;
                                                            this.setState({ client: this.state.client })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        label={"Org number"}
                                                        value={this.state.client.org_number}
                                                        onChange={(value) => {
                                                            this.state.client.org_number = value;
                                                            this.setState({ client: this.state.client })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        label={"Reference ID"}
                                                        value={this.state.client.internal_id}
                                                        onChange={(value) => {
                                                            this.state.client.internal_id = value;
                                                            this.setState({ client: this.state.client })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <div className='grid grid-cols-12 gap-2 opacity-50'>
                                                        <div className="col-span-4">
                                                            <DropdownTailwind
                                                                label={"Country code"}
                                                                small={false}
                                                                searchInput={true}
                                                                disabled={true}
                                                                selected={this.renders.countryCode()}
                                                                options={this.state.phone_codes.map((item) => {
                                                                    return { name: `${item.iso} (${item.code})`, value: item.code, id: item.code };
                                                                })}
                                                                onChange={async (country_code) => {
                                                                    this.state.client.country_code = country_code.value;
                                                                    await this.promisedSetState({
                                                                        client: this.state.client
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='col-span-8'>
                                                            <InputTailwind
                                                                label={"Phone number"}
                                                                value={this.state.client.phone}
                                                                error={this.state.phone_error}
                                                                disabled={true}
                                                                phone_codes={true}
                                                                phone_exists={this.state.phone_exists}
                                                                onChange={(value) => {
                                                                    if (value.length > 0 && /[^0-9]/.test(value)) {
                                                                        this.state.phone_error = true
                                                                    } else {
                                                                        this.state.phone_error = false
                                                                        this.state.phone_exists = false
                                                                    }
                                                                    this.state.client.phone = value;
                                                                    this.setState({ client: this.state.client })
                                                                }}
                                                                type={"tel"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-12">
                                                    <div className="w-full flex justify-end items-end">
                                                        <button
                                                            onClick={() => {
                                                                if (!this.state.loading_update) {
                                                                    this.functions.update()
                                                                }
                                                            }}
                                                            className={(!this.state.disabled ?
                                                                "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                            }
                                                        >
                                                            Save
                                                            {
                                                                this.state.loading_update &&
                                                                <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                </div>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-span-6'>
                                    {
                                        this.state.client.emailSettings &&
                                        this.state.client.emailSettings.agencyAutomatedEmails &&
                                        <div className='bg-white shadow rounded-lg mb-4'>
                                            <div class="py-5 px-4 border-b font-semibold text-sm">
                                                Automated report emails
                                            </div>
                                            <div className="p-4">
                                                <div className='grid w-full grid-cols-12 gap-4'>

                                                    <div className='col-span-6 relative'>
                                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Allow to send report emails</div>
                                                        <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                            <div className="flex flex-1 ml-5 text-sm font-medium">
                                                                {this.state.client.emailSettings.automatedEmails ? "Active" : "Disabled"}
                                                            </div>
                                                            <div className="relative overflow-hidden mr-5">
                                                                <SwitchTailwind
                                                                    value={this.state.client.emailSettings.automatedEmails}
                                                                    onSwitch={async () => {
                                                                        this.state.client.emailSettings.automatedEmails = !this.state.client.emailSettings.automatedEmails;
                                                                        await this.promisedSetState({
                                                                            client: this.state.client
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.client.emailSettings.automatedEmails &&
                                                        <div className={"col-span-6 relative"}>
                                                            <DropdownTailwind
                                                                label='Send report email (frequency)'
                                                                selected={this.state.client.emailSettings.automatedEmailsFrequency ? this.state.client.emailSettings.automatedEmailsFrequency : { id: 0, name: 'No frequency', value: "no_frequency" }}
                                                                options={[
                                                                    { id: 0, name: 'No frequency', value: "no_frequency" },
                                                                    { id: 1, name: 'Once a month (the 1st)', value: "once_month_start" },
                                                                ]}
                                                                onChange={async (value) => {
                                                                    this.state.client.emailSettings.automatedEmailsFrequency = value;
                                                                    await this.promisedSetState({
                                                                        client: this.state.client
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.client.emailSettings.automatedEmails &&
                                                        <div className='col-span-6 relative'>
                                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Send report email 14 days after new order is created</div>
                                                            <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                                <div className="flex flex-1 ml-5 text-sm font-medium">
                                                                    {this.state.client.emailSettings.sendAfter14Days ? "Active" : "Disabled"}
                                                                </div>
                                                                <div className="relative overflow-hidden mr-5">
                                                                    <SwitchTailwind
                                                                        value={this.state.client.emailSettings.sendAfter14Days}
                                                                        onSwitch={async () => {
                                                                            this.state.client.emailSettings.sendAfter14Days = !this.state.client.emailSettings.sendAfter14Days;
                                                                            await this.promisedSetState({
                                                                                client: this.state.client
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className='flex w-full justify-end p-4 bg-custom-input rounded-b-lg border-t'>
                                                <button className='bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                                    onClick={() => {
                                                        if (!this.state.loading_update) {
                                                            this.functions.update()
                                                        }
                                                    }}>
                                                    Save
                                                    {
                                                        this.state.loading_update &&
                                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    }

                                    <div className='shadow bg-white rounded-lg'>
                                        <div className="flex items-center justify-between py-5 px-4 sm:px-6 border-b font-semibold text-sm">
                                            Agents
                                        </div>
                                        <div className="py-5 px-4 sm:px-6 pb-0">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Agents"}
                                                        small={false}
                                                        skipInternalSearch={true}
                                                        loader={this.state.loading_agents}
                                                        loadingPagination={this.state.loading_agents_pagination}
                                                        loadingSearch={this.state.loading_agents_search}
                                                        total={this.state.total}
                                                        searchInput={true}
                                                        placeholder={"..."}
                                                        pagination={this.state.total > (this.state.page * this.state.limit)}
                                                        selected={{ id: 0, name: "Select agent" }}
                                                        options={this.state.agents}
                                                        onChange={async (value) => {
                                                            if (!Array.isArray(this.state.client.agents)) {
                                                                this.state.client.agents = [];
                                                            }
                                                            this.state.client.agents.unshift({
                                                                email: value.name,
                                                                id: value.id
                                                            });
                                                            await this.promisedSetState({
                                                                client: this.state.client
                                                            });
                                                        }}
                                                        onPagination={async () => {
                                                            if (!this.state.loading_agents_pagination) {
                                                                await this.promisedSetState({
                                                                    page: this.state.page + 1
                                                                });
                                                                this.functions.getAgents(false, true, false);
                                                            }
                                                        }}
                                                        onSearch={async (value) => {
                                                            if (value && value === "") {
                                                                await this.promisedSetState({
                                                                    search: "",
                                                                    page: 1
                                                                });
                                                                this.functions.getAgents(false, false, true);
                                                            } else {
                                                                if (this.state.throttling.current) {
                                                                    //SKIP
                                                                } else {
                                                                    if (value && value === "") {
                                                                        await this.promisedSetState({
                                                                            search: value,
                                                                            page: 1
                                                                        });
                                                                        this.functions.getAgents(false, false, true);
                                                                    } else {
                                                                        this.state.throttling.current = true;
                                                                        await this.promisedSetState({
                                                                            search: value,
                                                                            throttling: this.state.throttling
                                                                        });
                                                                        setTimeout(async () => {
                                                                            this.state.throttling.current = false;
                                                                            await this.promisedSetState({
                                                                                throttling: this.state.throttling
                                                                            });
                                                                            await this.promisedSetState({
                                                                                page: 1
                                                                            });
                                                                            this.functions.getAgents(false, false, true);
                                                                        }, 300);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    Array.isArray(this.state.client.agents) && this.state.client.agents.length > 0 &&
                                                    <div className="col-span-6 -mt-4">
                                                        {
                                                            this.state.client.agents.map((item, index) => {
                                                                return (
                                                                    <Fragment>
                                                                        <div className={"bg-indigo-100 text-indigo-700 mt-2 flex flex-1 rounded-md items-center p-3 text-sm font-medium "}>
                                                                            <div className="flex flex-1">{item.email}</div>
                                                                            <button
                                                                                onClick={() => {
                                                                                    this.state.client.agents = this.state.client.agents.filter((agent) => {
                                                                                        return agent.id !== item.id;
                                                                                    });
                                                                                    this.setState({
                                                                                        client: this.state.client
                                                                                    });
                                                                                }}
                                                                                type="button"
                                                                                className={"focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-8 w-8 rounded-md inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                            >
                                                                                <span className="sr-only">Remove agent</span>
                                                                                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }

                                                <div className="col-span-6 pb-4">
                                                    <div className="w-full flex justify-end items-end">
                                                        <button
                                                            onClick={() => {
                                                                if (!this.state.loading_update) {
                                                                    this.functions.update()
                                                                }
                                                            }}
                                                            className={(!this.state.disabled ?
                                                                "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                            }
                                                        >
                                                            Save
                                                            {
                                                                this.state.loading_update &&
                                                                <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                </div>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            this.state.tab.id === 3 &&
                            this.state.user.id &&
                            <>
                                <div className='shadow bg-white rounded-lg w-full h-full'>
                                    <div className="py-5 px-4 sm:px-6 border-b font-semibold text-sm pt-4">
                                        External platforms
                                    </div>
                                    <div className="py-5 px-4 sm:px-6 pb-40">
                                        <div className="grid grid-cols-4 gap-6">
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountTiktok
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountTwitter
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountGoogle
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountFacebook
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountLinkedin
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                            {
                                                false &&
                                                <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                    <AccountGoogleSearchConsole
                                                        me={this.state.user}
                                                        isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                        client={this.state.client}
                                                    />
                                                </div>
                                            }
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountGoogleAnalytics
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountGoogleAnalytics4
                                                    googleConnected={this.state.client && this.state.client.google}
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountAdform
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountBing
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountSnapchat
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountDv360
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                            <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                                <AccountBidtheatre
                                                    me={this.state.user}
                                                    isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                    client={this.state.client}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            this.state.tab.id === 5 &&
                            <>
                                {
                                    this.state.changelog_loading.logs &&
                                    <div className='min-h-screen flex flex-col justify-center items-center w-full'>
                                        <div style={{ borderTopColor: "transparent" }} className='w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin'></div>
                                        <div className='font-semibold mt-3'>Loading...</div>
                                    </div>
                                }

                                <div className='bg-white shadow-lg rounded-md'>
                                    <div className='flex items-center justify-between p-4 border-b'>
                                        <div style={{ minWidth: "225px" }}>
                                            <MultiDropdown
                                                fillOut={true}
                                                defaultSelected={{ id: 0, name: "All channels (default)", value: "all" }}
                                                defaultOptions={[]}
                                                placeholder={"All channels (default)"}
                                                selected={this.state.advancedFilters[0].selected}
                                                options={this.state.advancedFilters[0].options}
                                                onChange={async (option) => {
                                                    this.state.advancedFilters[0].selected = option;
                                                    await this.promisedSetState({
                                                        advancedFilters: this.state.advancedFilters
                                                    });
                                                    this.functions.calcTotal()
                                                }}
                                            />
                                        </div>
                                        <div className='text-sm text-gray-700'>
                                            LinkedIn, TikTok and Twitter logs are currently not supported.
                                        </div>
                                        <div className='flex space-x-4 items-center justify-center'>
                                            {
                                                this.state.meta && typeof this.state.meta.total === 'number' && this.state.meta.total !== 0 ?
                                                    <div className='flex items-center'>
                                                        <p className="text-sm text-gray-700">
                                                            Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > this.state.meta.total) ? this.state.meta.total : this.state.page * this.state.limit}</span> of{' '}
                                                            <span className="font-medium">{this.state.meta.total}</span> results
                                                        </p>
                                                    </div>
                                                    :
                                                    <div className='flex items-center'>
                                                        <p className="text-sm text-gray-700">
                                                            No logs yet
                                                        </p>
                                                    </div>
                                            }
                                            <div style={{ marginTop: "-3px" }}>
                                                <InputTailwind
                                                    noHeight={true}
                                                    search={true}
                                                    placeholder={"Search ..."}
                                                    value={this.state.search}
                                                    onChange={async (value) => {
                                                        await this.promisedSetState({
                                                            search: value
                                                        });
                                                        this.functions.calcTotal()
                                                    }}
                                                />
                                            </div>
                                            <div className='flex space-x-2 items-'>
                                                <div className="flex justify-center items-center">
                                                    <button
                                                        type="button"
                                                        onClick={async () => {
                                                            if (this.state.page !== 1) {
                                                                await this.promisedSetState({
                                                                    page: this.state.page - 1
                                                                });
                                                            }
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                    >
                                                        <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                                        Previous
                                                    </button>
                                                </div>

                                                <Menu as="div" className="relative items-center inline-block text-left mr-2">
                                                    <div>
                                                        <Menu.Button className={"cursor-pointer inline-flex relative justify-center items-center w-full h-10 rounded-md  border-1.5 px-4 bg-white text-sm font-medium text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                            Page limit: {this.state.limit}
                                                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="border absolute z-50 mt-2 w-56 max-h-40 overflow-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1">
                                                                {
                                                                    [5, 10, 20, 30, 50, 100, 500, 1000, 2000].map((item) => {
                                                                        return (
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <div
                                                                                        onClick={async () => {
                                                                                            this.state.limit = item;
                                                                                            await this.promisedSetState({
                                                                                                limit: this.state.limit,
                                                                                            });
                                                                                        }}
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                            'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                            this.state.limit == item ? "text-purple-500" : ""
                                                                                        )}
                                                                                    >
                                                                                        {item}
                                                                                    </div>
                                                                                )}
                                                                            </Menu.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>

                                                <div className="flex justify-center items-center">
                                                    <button
                                                        onClick={async () => {
                                                            if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                                await this.promisedSetState({
                                                                    page: this.state.page + 1
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                    >
                                                        <span>Next</span>
                                                        <ArrowRightIcon className="ml-2 h-5 w-5" />
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    {
                                        !this.state.changelog_loading.logs && this.state.logs.length === 0 &&
                                        <div className='flex items-center justify-center font-medium text-gray-500 p-4'>
                                            No logs to show
                                        </div>
                                    }

                                    {
                                        this.state.logs.length !== 0 &&
                                        <>
                                            <div className='table-overflow'>
                                                <table className='min-w-full divide-y divide-gray-300 border-gray-300'>
                                                    <thead className=''>
                                                        <tr className='select-none'>
                                                            <th
                                                                onClick={async () => {
                                                                    this.functions.sortLogs();
                                                                }}
                                                                style={{ width: 300, minWidth: 300 }}
                                                                scope="col" className='hover:bg-gray-100 hover:bg-opacity-75 px-6 py-3 border-gray-300 border-r text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider cursor-pointer bg-gray-100'>
                                                                <div className='flex space-x-4 items-center'>
                                                                    <div className='text-xs font-medium text-gray-700'>User/Date</div>
                                                                    <ArrowDownIcon className={`w-4 transform ${this.state.sort === "asc" && "rotate-180"}`} />
                                                                </div>
                                                            </th>
                                                            <th
                                                                style={{ minWidth: 200, width: 200 }}
                                                                scope="col" className='px-6 py-3 border-r border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider'>
                                                                Channel
                                                            </th>
                                                            <th
                                                                style={{ minWidth: 300, width: 300 }}
                                                                scope="col" className='px-6 py-3 border-r border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider'>
                                                                Item
                                                            </th>
                                                            <th scope="col" className='px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider'>
                                                                Change
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='divide-y divide-gray-300 w-full'>
                                                        {
                                                            this.state.logs
                                                                .filter((item) => Array.isArray(this.state.advancedFilters[0].selected) && this.state.advancedFilters[0].selected.length > 0 ? this.state.advancedFilters[0].selected.map(option => option.value && option.value.toLowerCase()).some(value => item.channel && value === item.channel.toLowerCase()) : true)
                                                                .filter((log) => {
                                                                    if (this.state.search) {
                                                                        return Object.values(log).some(value => String(value).includes(this.state.search));
                                                                    }
                                                                    return true;
                                                                })
                                                                .slice((this.state.page - 1) * this.state.limit, this.state.page * this.state.limit)
                                                                .map((log, index) => {
                                                                    return (
                                                                        <Fragment key={index}>
                                                                            <tr className='border-b'>
                                                                                <td className='px-4 sm:px-6 py-4 font-medium items-center text-sm border-gray-300 border-r bg-gray-50'>
                                                                                    <div className='flex flex-col text-sm'>
                                                                                        <span>{this.renders.user(log)}</span>
                                                                                        <span className='text-sm text-gray-600 whitespace-no-wrap truncate'>
                                                                                            Source: {log.internal ? "Adcredo" : log.channel.charAt(0).toUpperCase() + log.channel.slice(1)}
                                                                                        </span>
                                                                                        <span className='text-sm text-gray-600 whitespace-no-wrap truncate'>
                                                                                            {moment(log.date).format("DD/MM/YYYY HH:mm")}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td className='px-4 sm:px-6 py-4 font-medium items-center text-sm border-gray-300 border-r'>
                                                                                    <div className='flex items-center'>
                                                                                        {this.renders.icon(log)}
                                                                                        {this.renders.channel(log)}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='px-4 sm:px-6 py-4 font-medium items-center text-sm border-gray-300 truncate border-r'>
                                                                                    <div className='flex flex-col truncate'>
                                                                                        <span className='truncate'>
                                                                                            {this.renders.objectName(log)}
                                                                                        </span>
                                                                                        <span className='text-sm text-gray-600 whitespace-no-wrap truncate'>
                                                                                            {this.renders.objectType(log)}
                                                                                        </span>
                                                                                        <span className='text-sm text-gray-600 whitespace-no-wrap truncate'>
                                                                                            {/* ID: {this.renders.objectId(log)} */}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td
                                                                                    onClick={async () => {
                                                                                        if (!this.renders.details(log).see_more) {
                                                                                            return
                                                                                        }
                                                                                        if (this.state.log_index === index) {
                                                                                            this.state.log_index = null;
                                                                                        } else {
                                                                                            this.state.log_index = index;
                                                                                        }
                                                                                        await this.promisedSetState({
                                                                                            log_index: this.state.log_index
                                                                                        })
                                                                                    }}
                                                                                    className={`px-4 sm:px-6 py-4 font-medium items-center text-sm border-gray-300 ${this.renders.details(log).see_more && "cursor-pointer"}`}>
                                                                                    {
                                                                                        this.state.log_index !== index ?
                                                                                            <>
                                                                                                <div className='flex flex-col'>
                                                                                                    <span>
                                                                                                        {this.renders.action(log)}
                                                                                                    </span>
                                                                                                    <span className='text-sm text-gray-600 '>
                                                                                                        {this.renders.details(log).first_two}
                                                                                                        {this.renders.details(log).see_more}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <SlideDown closed={this.state.log_index !== index ? true : false}>
                                                                                                    <div className='flex flex-col'>
                                                                                                        <span>
                                                                                                            {this.renders.action(log)}
                                                                                                        </span>
                                                                                                        <span className='text-sm text-gray-600 '>
                                                                                                            {this.renders.details(log).changes}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </SlideDown>
                                                                                            </>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>

                                            {/* PAGINATION */}
                                            {
                                                <div className="flex p-4 items-center border-t">
                                                    <div className="flex justify-center items-center">
                                                        <button
                                                            type="button"
                                                            onClick={async () => {
                                                                if (this.state.page !== 1) {
                                                                    await this.promisedSetState({
                                                                        page: this.state.page - 1
                                                                    });
                                                                }
                                                            }}
                                                            className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                        >
                                                            <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                                            Previous
                                                        </button>
                                                    </div>
                                                    <div className="flex flex-1 items-center justify-center">
                                                        {
                                                            (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                                            <p className="text-sm text-gray-700">
                                                                Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                                                <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                                            </p>
                                                        }
                                                    </div>
                                                    <div className="flex justify-center items-center">
                                                        <button
                                                            onClick={async () => {
                                                                if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                                    await this.promisedSetState({
                                                                        page: this.state.page + 1
                                                                    });
                                                                }
                                                            }}
                                                            className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                        >
                                                            <span>Next</span>
                                                            <ArrowRightIcon className="ml-2 h-5 w-5" />
                                                        </button>
                                                    </div>
                                                </div>
                                            }


                                        </>
                                    }


                                </div>
                            </>
                        }

                    </div>
                }

            </div>
        );
    }
}

export default Client;
