import React, { Component, Fragment } from 'react';
import { BeatLoader } from "react-spinners";
import FeatherIcon from 'feather-icons-react';
import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import moment from 'moment';

export default class AdvancedReportCellTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sort: 'name',
            order: 'asc',
            cell: {},
            page: 1,
            page_limit: 10,
            items: [],
            show: false,
            show_component: false,
            load_status: 'Checking cached data',
        };
    }

    async componentDidMount() {

        await this.promisedSetState({
            cell: this.props.source ? JSON.parse(JSON.stringify(this.props.source)) : {},
            items: this.props.items ? this.props.items : [],
            transparent_cells: this.props.transparent_cells,
            report_background_color: this.props.report_background_color,
            show_component: this.props.show_component
        });

        if (this.state.cell.settings && this.state.cell.settings.sortTable) {
            await this.promisedSetState({
                sort: this.state.cell.settings.sortTable.sort,
                order: this.state.cell.settings.sortTable.order,
                page: this.state.cell.settings.sortTable.page
            });
        }
        this.compareNumber(this.state.cell);

        //ONLY SHOW CONTENT IN VIEW
        this.functions.showInView();

    }

    async componentWillReceiveProps(nextProps, nextContext) {
        await this.promisedSetState({
            cell: nextProps.source ? JSON.parse(JSON.stringify(nextProps.source)) : {},
            items: nextProps.items ? nextProps.items : [],
            transparent_cells: nextProps.transparent_cells,
            report_background_color: nextProps.report_background_color
        });
        this.getCellLoadStatus();
        this.compareNumber(this.state.cell);
    }

    setLoader() { }

    functions = {
        template: () => {
            try {
                return (this.state.cell.data_sources.channels && Object.keys(this.state.cell.data_sources.channels).length < 1) && Object.keys(this.state.cell.data_sources.accounts).length < 1 && Object.keys(this.state.cell.data_sources.campaigns).length < 1 && Object.keys(this.state.cell.data_sources.adgroups).length < 1 && Object.keys(this.state.cell.data_sources.ads).length < 1
            } catch (error) {
                return false;
            }
        },
        showInView: async () => {
            let self = this;
            if (this.state.show_component) {
                self.setState({ show: true });
            } else {
                await self.functions.sleep(2000);
                try {
                    let box = null;
                    if (window.innerWidth <= 767) {
                        box = document.getElementById(self.state.cell.i + "_preview_mobile");
                    } else {
                        box = document.getElementById(self.state.cell.i + "_preview");
                    }
                    const rect = box.getBoundingClientRect();
                    const isInView = rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                        rect.bottom > 0 &&
                        rect.right > 0;
                    if (isInView) {
                        self.setState({ show: isInView });
                    }
                } catch (error) {
                    self.setState({ show: true });
                }
                document.addEventListener('scroll', async function () {
                    try {
                        let box = null;
                        if (window.innerWidth <= 767) {
                            box = document.getElementById(self.state.cell.i + "_preview_mobile");
                        } else {
                            box = document.getElementById(self.state.cell.i + "_preview");
                        }
                        const rect = box.getBoundingClientRect();
                        const isInView = rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                            rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                            rect.bottom > 0 &&
                            rect.right > 0;
                        self.setState({ show: isInView });
                    } catch (error) {
                        self.setState({ show: true });
                    }
                }, {
                    capture: true,
                });
            }
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    };

    getCellLoadStatus(timeout = false) {
        if (this.state.cell.loading) {
            if (this.state.cell.load_status) {
                if (!timeout && this.state.load_status === 'Checking existing data') {
                    this.state.load_status = 'Requesting data from platforms';
                    setTimeout(() => {
                        this.getCellLoadStatus(true);
                    }, 10000);
                } else if (timeout) {
                    this.state.load_status = 'Waiting on data from platforms';
                }
            } else {
                this.state.load_status = 'Checking existing data';
            }
        }

        this.promisedSetState({
            load_status: this.state.load_status
        });
    }

    compareNumber(cell) {

        if (cell.data && cell.daterange && cell.daterange.compare_dates && cell.daterange.compare_dates.value === "enabled") {

            let compare_data_to_labels = {};
            let preceding_table = {};
            let orginal_table = JSON.parse(JSON.stringify(cell.data));

            //REMOVE OLD PRECIDING COLUMN
            let clean_columns = cell.columns.filter((column) => {
                return column.indexOf("##preciding##") === -1
            });

            this.state.items.map((item) => {
                if (item.abstract && item.parent_cell == cell.i && item.data) {
                    preceding_table = JSON.parse(JSON.stringify(item.data));
                }
            });

            orginal_table.map((item) => {
                item = JSON.parse(JSON.stringify(item));
                if (!((item.id + item.channel + item.breakdown_value) in compare_data_to_labels)) {
                    compare_data_to_labels[item.id + item.channel + item.breakdown_value] = { 'original': item, 'preciding': {} };
                }
            });

            if (Array.isArray(preceding_table) && preceding_table.length > 0) {
                var start = moment(preceding_table[0].breakdown_value, "YYYY-MM-DD");
                var end = moment(preceding_table[preceding_table.length - 1].breakdown_value, "YYYY-MM-DD");
                let days = moment.duration(end.diff(start)).asDays();
                preceding_table.map((item) => {
                    if (item.breakdown !== "date" && item.breakdown !== "FILTER_DATE") {
                        if (!compare_data_to_labels[item.id + item.channel + item.breakdown_value]) {
                            compare_data_to_labels[item.id + item.channel + item.breakdown_value] = { 'original': {}, 'preciding': item };
                        }
                        compare_data_to_labels[item.id + item.channel + item.breakdown_value].preciding = item;
                    } else {
                        let new_date = moment(item.breakdown_value).add(days + 1, 'days').format("YYYY-MM-DD");
                        if (compare_data_to_labels[item.id + item.channel + new_date]) {
                            compare_data_to_labels[item.id + item.channel + new_date].preciding = item;
                            compare_data_to_labels[item.id + item.channel + new_date].preciding_value = { date: item.breakdown_value, item }
                        }
                    }
                });
            }

            let new_columns = [];
            let new_rows = [];
            clean_columns.map((column) => {
                let exist = false;
                new_columns.push(column);
                Object.keys(cell.metrics).map((key) => {
                    if (!exist) {
                        let metric = cell.metrics[key];
                        if (metric.goal && metric.goal.value !== "none") {
                            if (key == column) {
                                exist = true;
                                new_columns.push("##preciding##" + key + "##preciding##");
                            }
                        }
                    }
                })
            });

            for (let key in compare_data_to_labels) {

                let original_missing = false;
                let new_row = compare_data_to_labels[key].original;

                if (!("breakdown" in new_row)) {
                    original_missing = true;
                    new_row["breakdown"] = compare_data_to_labels[key].preciding["breakdown"];
                }

                if (original_missing) {
                    new_row["name"] = compare_data_to_labels[key].preciding["name"];
                }

                if (original_missing) {
                    new_row["id"] = compare_data_to_labels[key].preciding["id"];
                }

                if (original_missing) {
                    new_row["channel"] = compare_data_to_labels[key].preciding["channel"];
                }

                if (original_missing) {
                    new_row["level"] = compare_data_to_labels[key].preciding["level"];
                }

                if (original_missing) {
                    new_row["preview"] = compare_data_to_labels[key].preciding["preview"];
                }

                if (!("breakdown_value" in new_row)) {
                    try {
                        let value = new_row.id + new_row.channel;
                        new_row["breakdown_value"] = key.replace(value.toString(), "");
                    } catch (error) { }
                }

                for (let item in compare_data_to_labels[key].preciding) {
                    new_row["##preciding##" + item + "##preciding##"] = comparedValue(cell, compare_data_to_labels[key].original[item], compare_data_to_labels[key].preciding[item], item);
                    new_row["##value##" + item + "##value##"] = compare_data_to_labels[key].preciding[item];
                    if (original_missing && item !== "breakdown" && item !== "breakdown_value" && item !== "name" && item !== "id" && item !== "channel" && item !== "level" && item !== "preview") {
                        new_row[item] = 0;
                    }
                }

                new_rows.push(new_row);

            }

            cell.columns = new_columns;
            cell.data = new_rows;

            this.setState({
                preciding_value: compare_data_to_labels,
                cell: cell ? cell : {}
            });

        } else {
            this.setState({
                cell: cell ? cell : {}
            });
        }

        function comparedValue(cell, org_number, preceding_number, metric_name) {
            let metric = cell.metrics[metric_name];
            preceding_number = +preceding_number;
            org_number = +org_number;
            if (metric) {
                if (metric.goal && metric.goal.value == "none") {
                    return "";
                } else {
                    try {
                        if (preceding_number > 0) {
                            if (metric.comparison && metric.comparison.value == "number") {
                                if (preceding_number % 1 != 0) {
                                    try {
                                        return preceding_number.toFixed(2);
                                    } catch (error) {
                                        return preceding_number;
                                    }
                                } else {
                                    return preceding_number;
                                }
                            } else {
                                let increase = org_number - preceding_number;
                                let value = (increase / preceding_number) * 100;
                                return value.toFixed(2);
                            }
                        } else {
                            return "";
                        }
                    } catch (error) {
                        console.log(error)
                        return "";
                    }
                }
            } else {
                return "";
            }
        }

    }

    metricGoal(key) {
        key = key.replace("##preciding##", '').replace("##preciding##", '');
        let metric = this.state.cell.metrics[key];
        if (metric && metric.goal && metric.goal.value === "highest") {
            return "highest"
        } else if (metric && metric.goal && metric.goal.value === "lowest") {
            return "lowest"
        } else {
            return null;
        }
    }

    showOverlayText() {
        let show = false;
        try {
            if (!this.state.cell.loading) {
                if (!Array.isArray(this.state.cell.data) || (Array.isArray(this.state.cell.data) && this.state.cell.data.length < 1)) {
                    if (this.state.cell.settings && this.state.cell.settings.zeroValueOverlay) {
                        if (typeof this.state.cell.settings.zeroValueOverlayText == "string" && this.state.cell.settings.zeroValueOverlayText !== "") {
                            show = true;
                        }
                    }
                }
            }
        } catch (error) { }
        return show;
    }

    showRedOrGreenValue(column, item) {
        let origin_number = null;
        try {
            let origin_number_metric = column.replace("##preciding##", "").replace("##preciding##", "");
            if (origin_number_metric in item) {
                origin_number = item[origin_number_metric];
            }
        } catch (error) { }
        if (this.metricComparisonType(column) !== "number") {
            if ((this.metricGoal(column) === "highest" && item[column] >= 0) || (this.metricGoal(column) === "lowest" && item[column] <= 0)) {
                return "green";
            } else if (((this.metricGoal(column) === "lowest" && item[column] > 0) || (this.metricGoal(column) === "highest" && item[column] < 0))) {
                return "red";
            }
        } else if (this.metricComparisonType(column) == "number") {
            if (this.metricGoal(column) === "highest") {
                if (origin_number >= item[column]) {
                    return "green";
                } else {
                    return "red";
                }
            } else if (this.metricGoal(column) === "lowest") {
                if (origin_number <= item[column]) {
                    return "green";
                } else {
                    return "red";
                }
            }
        }
    }

    metricSuffix(key) {
        try {
            if (this.state.cell.all_metrics[key]) {
                if (this.state.cell.all_metrics[key].suffix && this.state.cell.all_metrics[key].suffix.value == "%") {
                    return "%";
                } else if (this.state.cell.all_metrics[key].suffix && this.state.cell.all_metrics[key].suffix.value == "currency") {
                    if (this.state.cell.all_metrics[key].currencyOption && this.state.cell.all_metrics[key].currencyOption.value == "static") {
                        return this.state.cell.all_metrics[key].currency;
                    } else {
                        //ADACCOUNT
                        return null;
                    }
                }
            }
        } catch (error) {
            return null;
        }
        return null;
    }

    metricExternalName(key) {
        try {
            if (window.location.href.indexOf("/advanced/report/") !== -1) {
                if (this.state.cell.all_metrics[key]) {
                    if (this.state.cell.all_metrics[key].showExternalName) {
                        return this.state.cell.all_metrics[key].externalName;
                    }
                }
            }
        } catch (error) {
            return key;
        }
        return key;
    }

    metricComparisonType(key) {
        key = key.replace("##preciding##", '').replace("##preciding##", '');
        let metric = this.state.cell.metrics[key];
        if (metric && metric.comparison && metric.comparison.value) {
            return metric.comparison.value;
        } else {
            return null;
        }
    }

    columnName(column) {
        if (column.indexOf("##preciding##") !== -1) {
            return "Change";
        } else {
            return this.metricExternalName(column)
        }
    }

    precidingValue(item, column) {
        try {
            let value_name = column.replace("##preciding##", "##value##");
            value_name = value_name.replace("##preciding##", "##value##");
            if (item[value_name]) {
                return item[value_name];
            } else {
                return "";
            }
        } catch (error) {
            return "";
        }
    }

    addCommas(nStr) {
        try {
            if (isNaN(nStr)) {
                return nStr;
            } else {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + ' ' + '$2');
                }
                return x1 + x2;
            }
        } catch (error) {
            return nStr;
        }
    }

    shadeColor(color, percent) {

        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        R = Math.round(R)
        G = Math.round(G)
        B = Math.round(B)

        var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }

    convertNumber = (x) => {
        try {
            if (this.checkNumber(x) == "integer") {
                return parseInt(x);
            } else if (this.checkNumber(x) == "float") {
                return parseInt(parseFloat(x));
            } else {
                return x;
            }
        } catch (error) {
            return x;
        }
    }

    checkNumber = (x) => {
        try {
            if (!isNaN(x) && x.toString().indexOf('.') != -1) {
                return "float";
            } else if (!isNaN(x)) {
                return "integer";
            } else {
                return "string";
            }
        } catch (error) {
            return "string";
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        let search_ad = (item) => {
            return (
                <td className={"flex justify-start"}>
                    <div style={{ maxWidth: "250px" }} className="">
                        <div className="flex flex-row text-xxxs text-purple-500 flex-wrap">
                            {Array.isArray(item.headlines) && item.headlines.filter((item, index) => { return index < 3 }).join(" | ") + (item.headlines.length > 3 ? (" +" + (item.headlines.length - 3)) : "")}
                        </div>
                        {
                            Array.isArray(item.final_urls) && item.final_urls.length > 0 &&
                            <div className="flex truncate text-xxxs text-green-600">
                                {item.final_urls[0]}
                            </div>
                        }
                        <div className="flex flex-row truncate">
                            <div className="text-xxxs truncate">
                                {Array.isArray(item.descriptions) ? item.descriptions.join(". ") : ""}
                            </div>
                        </div>
                    </div>
                </td>
            )
        }

        if (this.state.show && !this.state.cell.loading && !this.functions.template() && !this.showOverlayText()) {
            return (
                <div
                    className="px-4 md:px-8 py-4 md:py-8 absolute w-full flex justify-center align-middle items-center flex-col h-full"
                    //style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                    style={{
                        ...(this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color && !this.state.cell.settings.transparent && { backgroundColor: "#" + this.state.cell.settings.background_color }),
                        ...(this.state.cell && this.state.cell.settings && !this.state.cell.settings.background_color && !this.state.cell.settings.transparent && { backgroundColor: "#FFFFFF" }),
                    }}
                >
                    {
                        this.state.cell && this.state.cell.settings && this.state.cell.settings.title && this.state.cell.settings.title !== "" &&
                        <div
                            style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color && { color: "#" + this.state.cell.settings.font_color }}
                            className="mb-4 md:mb-8">{this.state.cell.settings.title}</div>
                    }
                    {
                        Array.isArray(this.state.cell.data) && Array.isArray(this.state.cell.columns) &&
                        <div
                            style={this.state.cell && this.state.cell.settings && this.state.cell.settings.table_row_background_color ? { backgroundColor: "#" + this.state.cell.settings.table_row_background_color } : { backgroundColor: "#FFFFFF" }}
                            className="w-full border overflow-hidden h-full flex flex-col rounded-md relative">
                            <div className="h-full table-overflow">
                                <table ref={"table_" + this.state.cell.i} className="min-w-full relative divide-y divide-gray-200" id="reportstable">
                                    <thead
                                        style={this.state.cell && this.state.cell.settings && this.state.cell.settings.table_header_background_color ? { backgroundColor: "#" + this.state.cell.settings.table_header_background_color } : { backgroundColor: "#FCFCFC" }}
                                        className={"sticky top-0 z-10"}>
                                        <tr>
                                            {
                                                Array.isArray(this.state.cell.default_columns) && this.state.cell.default_columns.length > 0 &&
                                                this.state.cell.default_columns.map((column, index) => {
                                                    return (
                                                        <th key={index} onClick={async () => {
                                                            await this.promisedSetState({
                                                                sort: column.value,
                                                                order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                            });
                                                            if (this.props.updateSort) {
                                                                this.props.updateSort({ sort: this.state.sort, order: this.state.order, page: this.state.page });
                                                            }
                                                        }}
                                                            style={this.state.cell && this.state.cell.settings && this.state.cell.settings.table_header_font_color ? { color: "#" + this.state.cell.settings.table_header_font_color } : { color: "#333333" }}
                                                            className={(this.state.cell && this.state.cell.settings && this.state.cell.settings.table_font_size && this.state.cell.settings.table_font_size.value === 'medium' ? " text-sm " : "text-xs ") + " cursor-pointer border-r transition-all duration-200 text-left leading-4 font-medium  uppercase "}>
                                                            <div className="px-6 py-3 flex flex-row items-center whitespace-no-wrap">
                                                                <div>
                                                                    {column.title ? column.title : column.value}
                                                                </div>
                                                                {
                                                                    this.state.order === 'asc' && this.state.sort === column.value &&
                                                                    <ArrowDownIcon className="w-4 ml-2 font-medium" />
                                                                }
                                                                {
                                                                    this.state.order === 'desc' && this.state.sort === column.value &&
                                                                    <ArrowUpIcon className="w-4 ml-2 font-medium" />
                                                                }
                                                            </div>
                                                        </th>
                                                    )
                                                })
                                            }
                                            {
                                                this.state.cell.columns.map((column, index) => {
                                                    let last = this.state.cell.columns.length - 1 === index;
                                                    return (
                                                        <th key={index} onClick={async () => {
                                                            if (this.columnName(column) !== "Change") {
                                                                await this.promisedSetState({
                                                                    sort: column,
                                                                    order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                                });
                                                                if (this.props.updateSort) {
                                                                    this.props.updateSort({ sort: this.state.sort, order: this.state.order, page: this.state.page });
                                                                }
                                                            }
                                                        }}
                                                            style={this.state.cell && this.state.cell.settings && this.state.cell.settings.table_header_font_color ? { color: "#" + this.state.cell.settings.table_header_font_color } : { color: "#333333" }}
                                                            className={(this.state.cell && this.state.cell.settings && this.state.cell.settings.table_font_size && this.state.cell.settings.table_font_size.value === 'medium' ? " text-sm " : "text-xs ") + (!last ? " border-r " : "") + " cursor-pointer transition-all duration-200 text-left leading-4 font-medium  uppercase "}>
                                                            <div className="px-6 py-3 flex flex-row items-center whitespace-no-wrap">
                                                                <div>
                                                                    {this.columnName(column)}
                                                                </div>
                                                                {
                                                                    this.columnName(column) !== "Change" &&
                                                                    this.state.order === 'asc' && this.state.sort === column &&
                                                                    <ArrowDownIcon className="w-4 ml-2 font-medium" />
                                                                }
                                                                {
                                                                    this.columnName(column) !== "Change" &&
                                                                    this.state.order === 'desc' && this.state.sort === column &&
                                                                    <ArrowUpIcon className="w-4 ml-2 font-medium" />
                                                                }
                                                            </div>
                                                        </th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody
                                        style={this.state.cell && this.state.cell.settings && this.state.cell.settings.table_row_background_color ? { backgroundColor: "#" + this.state.cell.settings.table_row_background_color } : { backgroundColor: "#FFFFFF" }}
                                        className="divide-y divide-gray-200 relative z-0">
                                        {
                                            this.state.cell.data.map((item) => {
                                                if (typeof item[this.state.sort] == "number") {
                                                    item[this.state.sort] = +item[this.state.sort];
                                                }
                                                return item;
                                            }).sort((a, b) => {
                                                if (this.state.order === 'desc') {
                                                    return (this.convertNumber(a[this.state.sort]) > this.convertNumber(b[this.state.sort])) ? -1 : 1
                                                } else {
                                                    return (this.convertNumber(a[this.state.sort]) > this.convertNumber(b[this.state.sort])) ? 1 : -1
                                                }
                                            }).filter((item, index) => {
                                                if (this.state.cell.data.length > this.state.page_limit) {
                                                    return (this.state.page * this.state.page_limit) > index
                                                } else {
                                                    return true;
                                                }
                                            }).map((item, i) => {
                                                return (
                                                    <Fragment key={i}>
                                                        {
                                                            <tr
                                                                key={i}
                                                                className='border-b'
                                                            >
                                                                {
                                                                    Array.isArray(this.state.cell.default_columns) && this.state.cell.default_columns.length > 0 &&
                                                                    this.state.cell.default_columns.map((column, i) => {
                                                                        if (column.value == "channel") {
                                                                            return (
                                                                                <td key={i} className={(this.state.sort === "channel" ? " " : "") + " border-r"}>
                                                                                    <div
                                                                                        //style={this.state.cell && this.state.cell.settings && this.state.cell.settings.table_header_background_color ? { backgroundColor: "#" + this.state.cell.settings.table_header_background_color } : { backgroundColor: "#FCFCFC" }}
                                                                                        style={this.state.cell && this.state.cell.settings && this.state.cell.settings.table_row_font_color ? { color: "#" + this.state.cell.settings.table_row_font_color } : { color: "#333333" }}
                                                                                        className={"text-sm px-4 flex flex-row justify-start ml-2 py-4 align-middle items-center leading-5 truncate"}>
                                                                                        {
                                                                                            item.channel === "google" &&
                                                                                            <div
                                                                                                className="bg-google-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img className="w-3" src={require('../assets/images/google_icon.svg')} />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            item.channel === "google_shopping" &&
                                                                                            <div
                                                                                                className="bg-googleshopping-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img style={{ width: "0.65rem" }} src={require('../assets/images/google-shopping.png')} />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            item.channel === "linkedin" &&
                                                                                            <div
                                                                                                className="bg-linkedin-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img className="w-3"
                                                                                                    src={require('../assets/images/linkedin_icon.svg')} />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            item.channel === "facebook" &&
                                                                                            <div
                                                                                                className="bg-facebook-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img style={{ width: '0.4rem' }}
                                                                                                    src={require('../assets/images/facebook_icon.svg')} />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            item.channel === "google_analytics" &&
                                                                                            <div
                                                                                                className="bg-googleanalytics-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img className="w-3"
                                                                                                    src={require('../assets/images/ga_icon.png')} />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            item.channel === "adform" &&
                                                                                            <div
                                                                                                className="bg-adform-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img className="w-3"
                                                                                                    src={require('../assets/images/adform_icon.png')} />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            item.channel === "twitter" &&
                                                                                            <div
                                                                                                className="bg-twitter-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img className="w-3"
                                                                                                    src={require('../assets/images/twitter_icon.svg')} />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            item.channel === "tiktok" &&
                                                                                            <div
                                                                                                className="bg-tiktok-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img className="w-3"
                                                                                                    src={require('../assets/images/tiktok_icon.png')} />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            item.channel === "bing" &&
                                                                                            <div
                                                                                                className="bg-bing-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img className="w-2.5"
                                                                                                    src={require('../assets/images/bing-logo.png')} />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            item.channel === "snapchat" &&
                                                                                            <div
                                                                                                className="bg-snapchat-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img className="w-2.5"
                                                                                                    src={require('../assets/images/snapchat.svg')} />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            item.channel === "bidtheatre" &&
                                                                                            <div
                                                                                                className="bg-bidtheatre-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img className="w-2.5"
                                                                                                    src={require('../assets/images/bidtheatre_icon.png')} />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            item.channel === "dv360" &&
                                                                                            <div
                                                                                                className="bg-dv360-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                                <img className="w-2.5"
                                                                                                    src={require('../assets/images/dv360-logo.svg')} />
                                                                                            </div>
                                                                                        }
                                                                                        <div className="capitalize ml-1">
                                                                                            {item.channel === 'google_shopping' ? "Google Shopping" : (item.channel === 'google_analytics' ? "Google Analytics" : item.channel)}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                        } else if (column.value == "preview" && item.level == "ad" && (item.channel === "google" || item.channel === "bing")) {
                                                                            return (
                                                                                <td
                                                                                    key={i}
                                                                                    className={(this.state.sort === "preview" ? "  " : "") + " border-r px-6 py-4 whitespace-no-wrap"
                                                                                    }
                                                                                >
                                                                                    {search_ad(item)}
                                                                                </td>
                                                                            )
                                                                        } else if (column.value == "preview" && (item.level == "ad" || item.level == "account") && item.channel !== "bing") {
                                                                            return (
                                                                                <td
                                                                                    key={i + item.id}
                                                                                    className={(this.state.sort === "preview" ? "  " : "") + " border-r px-6 py-4 whitespace-no-wrap"}
                                                                                >
                                                                                    <div className="flex flex-row items-center">
                                                                                        <div className={"text-sm leading-5 truncate"}>
                                                                                            <div className="h-32 w-32 rounded-md flex justify-center items-center overflow-hidden">
                                                                                                {
                                                                                                    item.video_url &&
                                                                                                    <video controls={true} loop={false} autoPlay={false} muted={true}
                                                                                                        style={{ width: '100%', height: '100%' }}>
                                                                                                        <source src={item.video_url} type="video/mp4" />
                                                                                                    </video>
                                                                                                }
                                                                                                {
                                                                                                    item.image_url && !item.video_url &&
                                                                                                    <img src={item.image_url} style={{ height: '100%' }} />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <td
                                                                                    key={i}
                                                                                    style={this.state.cell && this.state.cell.settings && this.state.cell.settings.table_row_font_color ? { color: "#" + this.state.cell.settings.table_row_font_color } : { color: "#333333" }}
                                                                                    className={(this.state.sort === column.value ? " bg-opacity-75 " : "") + " border-r  px-6 py-4 whitespace-no-wrap"}
                                                                                >
                                                                                    <div className="flex flex-row items-center justify-end">
                                                                                        <div className={(this.state.cell && this.state.cell.settings && this.state.cell.settings.table_font_size && this.state.cell.settings.table_font_size.value === 'medium' ? "  " : "text-sm ") + " leading-5  truncate"}>
                                                                                            {item[column.value]}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                                {
                                                                    this.state.cell.columns.map((column, index) => {
                                                                        if (column == "preview" && item.level == "ad" && (item.channel === "google" || item.channel === "bing")) {
                                                                            return (
                                                                                <td
                                                                                    key={index + column}
                                                                                    className={(this.state.sort === "preview" ? " " : "") + (index !== 0 ? "border-l " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                                >
                                                                                    {search_ad(item)}
                                                                                </td>
                                                                            )
                                                                        } else if (column == "preview" && item.level == "ad" && item.channel !== "google" && item.channel !== "bing") {
                                                                            return (
                                                                                <td
                                                                                    key={index + column}
                                                                                    className={(this.state.sort === "preview" ? " " : "") + (index !== 0 ? "border-l " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                                >
                                                                                    <div className="flex flex-row items-center">
                                                                                        <div className={"text-sm leading-5 text-gray-900 truncate"}>
                                                                                            <div className="h-32 w-32 rounded-md justify-center flex overflow-hidden ">
                                                                                                {
                                                                                                    typeof item.video_url == "string" && item.video_url !== "" &&
                                                                                                    <video controls={true} loop={false} autoPlay={false} muted={true}
                                                                                                        style={{ width: '100%', height: '100%' }}>
                                                                                                        <source src={item.video_url} type="video/mp4" />
                                                                                                    </video>
                                                                                                }
                                                                                                {
                                                                                                    typeof item.image_url == "string" && item.image_url !== "" && !(typeof item.video_url == "string" && item.video_url !== "") &&
                                                                                                    <img src={item.image_url} style={{ height: '100%' }} />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <td
                                                                                    key={index + column}
                                                                                    className={(this.state.sort === column ? " bg-opacity-75 " : "") + (index !== 0 ? "border-l " : "") + " px-6 py-4 relative whitespace-no-wrap"}
                                                                                >
                                                                                    {
                                                                                        column.indexOf("##preciding##") !== -1 &&
                                                                                        (!item[column] || (
                                                                                            !((this.metricGoal(column) === "highest" && item[column] >= 0) || (this.metricGoal(column) === "lowest" && item[column] <= 0)) &&
                                                                                            !((this.metricGoal(column) === "lowest" && item[column] > 0) || (this.metricGoal(column) === "highest" && item[column] < 0))
                                                                                        )) &&
                                                                                        <div className="flex flex-row items-center">
                                                                                            -
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        column.indexOf("##preciding##") !== -1 &&
                                                                                        item[column] &&
                                                                                        this.showRedOrGreenValue(column, item) == "green" &&
                                                                                        <div className="flex flex-row items-center">
                                                                                            {
                                                                                                this.metricComparisonType(column) !== "number" &&
                                                                                                <div className="bg-green-100 text-green-500 border-green-500 h-5 w-5 mr-1 rounded-full flex justify-center items-center">
                                                                                                    {
                                                                                                        item[column] > 0 &&
                                                                                                        <ArrowUpIcon className={"w-3"} />
                                                                                                    }
                                                                                                    {
                                                                                                        item[column] < 0 &&
                                                                                                        <ArrowDownIcon className={"w-3"} />
                                                                                                    }
                                                                                                    {
                                                                                                        item[column] == 0 &&
                                                                                                        <div>-</div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                            <div className={(this.state.cell && this.state.cell.settings && this.state.cell.settings.table_font_size && this.state.cell.settings.table_font_size.value === 'medium' ? "  " : "text-sm ") + " leading-5 text-green-500 truncate"}>
                                                                                                {item[column]}{this.metricComparisonType(column) !== "number" && <span>%</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        column.indexOf("##preciding##") !== -1 &&
                                                                                        item[column] &&
                                                                                        this.showRedOrGreenValue(column, item) == "red" &&
                                                                                        <div className="flex flex-row items-center">
                                                                                            {
                                                                                                this.metricComparisonType(column) !== "number" &&
                                                                                                <div className="bg-red-100 text-red-500 border-red-500 h-5 w-5 mr-1 rounded-full flex justify-center items-center">
                                                                                                    {
                                                                                                        item[column] > 0 &&
                                                                                                        <ArrowUpIcon className={"w-3"} />
                                                                                                    }
                                                                                                    {
                                                                                                        item[column] < 0 &&
                                                                                                        <ArrowDownIcon className={"w-3"} />
                                                                                                    }
                                                                                                    {
                                                                                                        item[column] == 0 &&
                                                                                                        <div>-</div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                            <div className={(this.state.cell && this.state.cell.settings && this.state.cell.settings.table_font_size && this.state.cell.settings.table_font_size.value === 'medium' ? "  " : "text-sm ") + " leading-5 text-red-500 truncate"}>
                                                                                                {item[column]}{this.metricComparisonType(column) !== "number" && <span>%</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        column.indexOf("##preciding##") === -1 &&
                                                                                        <div className="flex flex-row items-center justify-end">
                                                                                            <div
                                                                                                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.table_row_font_color ? { color: "#" + this.state.cell.settings.table_row_font_color } : { color: "#333333" }}
                                                                                                className={(this.state.cell && this.state.cell.settings && this.state.cell.settings.table_font_size && this.state.cell.settings.table_font_size.value === 'medium' ? "  " : "text-sm ") + " leading-5 truncate"}>
                                                                                                {(column in item) ? this.addCommas(item[column]) : "-"}{" "}{this.metricSuffix(column)}
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </td>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </tr>
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </tbody >
                                </table >
                            </div >
                            <div className="flex flex-1 border-t"></div>
                            <div
                                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.table_header_background_color ? { backgroundColor: "#" + this.state.cell.settings.table_header_background_color } : { backgroundColor: "#FCFCFC" }}
                                className="w-full py-2  border-t flex justify-center items-center">
                                <button
                                    onClick={async () => {
                                        if (Array.isArray(this.state.cell.data) && Array.isArray(this.state.cell.columns) && (this.state.cell.data.length > (this.state.page_limit * this.state.page))) {
                                            await this.promisedSetState({
                                                page: this.state.page + 1
                                            });
                                            if (this.props.updateSort) {
                                                this.props.updateSort({ sort: this.state.sort, order: this.state.order, page: this.state.page });
                                            }
                                            try {
                                                this.refs["table_" + this.state.cell.i].scrollIntoView({ behavior: "smooth", block: "end" });
                                            } catch (error) { }
                                        }
                                    }}
                                    className={(!(Array.isArray(this.state.cell.data) && Array.isArray(this.state.cell.columns) && (this.state.cell.data.length > (this.state.page_limit * this.state.page))) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                >
                                    {
                                        Array.isArray(this.state.cell.data) && Array.isArray(this.state.cell.columns) && (this.state.cell.data.length > (this.state.page_limit * this.state.page)) &&
                                        <span>Load more ({this.state.page_limit * this.state.page}/{this.state.cell.data.length})</span>
                                        ||
                                        <span>All loaded ({this.state.cell.data.length})</span>
                                    }
                                </button>
                            </div>
                        </div>
                    }
                </div >
            );
        } else if (this.state.show && this.state.cell.loading && !this.showOverlayText()) {
            return (
                <div className="w-full h-full relative flex flex-col justify-center items-center">
                    <div
                        style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                    >
                        <div style={{ borderTopColor: "transparent" }}
                            class="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                    </div>
                    <div className="text-xs mt-3">{this.state.load_status}</div>
                </div>
            );
        } else if (this.showOverlayText()) {
            return (
                <div
                    style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                    className="w-full h-full relative flex justify-center items-center">
                    {
                        this.state.cell && this.state.cell.settings &&
                        <div
                            style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color && { color: "#" + this.state.cell.settings.font_color }}
                            className="text-sm font-medium">{this.state.cell.settings.zeroValueOverlayText}</div>
                    }
                </div>
            )
        } else {
            return (<div
                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                className="w-full h-full relative flex flex-col justify-center items-center">
                <div
                    style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                >
                    <div style={{ borderTopColor: "transparent" }}
                        class="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                </div>
            </div>)
        }
    }

}
