import React, { Component, Fragment } from 'react';
import FeatherIcon from "feather-icons-react";
import { Dialog, Popover, Transition } from '@headlessui/react'
import { ArrowDownIcon, ViewBoardsIcon, ArrowLeftIcon, PhotographIcon, ArrowRightIcon, ArrowUpIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, XIcon, PlusIcon, TrashIcon, PencilAltIcon, CheckIcon } from '@heroicons/react/outline'
import moment from 'moment';
import fuzzysort from 'fuzzysort';
import SlideDown from 'react-slidedown';
import { BeatLoader } from 'react-spinners';
import 'react-slidedown/lib/slidedown.css';
import 'sweetalert/dist/sweetalert.css';
import { CirclePicker } from 'react-color';
import SingleDatepicker from "../modules/singleDatepicker";
import CreateCustomMetric from "../modules/createCustomMetric";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import GoogleAnalyticsDimensions from '../assets/json/dimensions/google_analytics.json';
import GoogleAnalytics4Dimensions from '../assets/json/dimensions/google_analytics_4.json';
import AdformDimensions from '../assets/json/dimensions/adform.json';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import SwitchTailwind from './switchTailwind';
import InputTailwind from './inputTailwind';
import DropdownTailwind from './dropdownTailwind';
import ReactTooltip from 'react-tooltip';
import IconPicker from './iconPicker';
import { resolve } from 'path';
import TextArea from '../components/text-area';

class AdvancedReportCellSlideIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datasource: true,
            data: {},
            tab: {},
            cell: {},
            accounts: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_channels: {},
            selected_accounts: {},
            selected_campaigns: {},
            selected_adgroups: {},
            selected_ads: {},
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            premium: false,
            celltype: true,
            metrics: {},
            channel_breakdowns: [],
            analytics_breakdowns: [],
            closed: true
        };
    };

    componentDidMount() {
        this.setState({
            closed: this.props.closed,
            all_metrics: this.props.metrics,
            premium: this.props.premium,
            accounts: this.props.accounts,
            campaigns: this.props.campaigns,
            adgroups: this.props.adgroups,
            ads: this.props.ads,
            template: this.props.template,
            masterTemplate: this.props.masterTemplate,
            metrics: this.props.metrics
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            closed: nextProps.closed,
            metrics: nextProps.metrics,
            premium: nextProps.premium,
            template: nextProps.template,
            masterTemplate: nextProps.masterTemplate,
            accounts: nextProps.accounts,
            campaigns: nextProps.campaigns,
            adgroups: nextProps.adgroups,
            ads: nextProps.ads,
        });
    }

    functions = {
        initiate: (channels, accounts, campaigns, adgroups, ads, group) => {

            if (!channels) {
                channels = {};
            }
            if (!accounts) {
                accounts = {};
            }
            if (!campaigns) {
                campaigns = {};
            }
            if (!adgroups) {
                adgroups = {};
            }
            if (!ads) {
                ads = {};
            }

            let cell = null;
            if (this.props.cell) {
                if (this.props.cell.typeOfBlock == "input" || this.props.cell.typeOfBlock == "img") {
                    cell = JSON.parse(JSON.stringify(this.props.cell));
                } else {
                    delete this.props.cell.data;
                    delete this.props.cell.parsed_data;
                    delete this.props.cell.parsed_items;
                    delete this.props.cell.parsed_metrics;
                    cell = JSON.parse(JSON.stringify(this.props.cell));
                }
            }

            this.setState({
                celltype: true,
                datasource: !this.state.premium,
                breakdown: false,
                daterange: false,
                piechart: false,
                settings: false,
                update_cell: false,
                init_tab: { ...this.props.tab },
                tab: { ...this.props.tab },
                cell: cell,
                selected_channels_internal: JSON.parse(JSON.stringify(channels)),
                selected_accounts_internal: JSON.parse(JSON.stringify(accounts)),
                selected_campaigns_internal: JSON.parse(JSON.stringify(campaigns)),
                selected_adgroups_internal: JSON.parse(JSON.stringify(adgroups)),
                selected_ads_internal: JSON.parse(JSON.stringify(ads)),
            }, () => {

                if (this.state.cell && this.state.cell.typeOfBlock) {
                    this.state.update_cell = true;
                }

                delete this.state.tab.celltype;

                if (this.state.cell) {
                    this.state.tab.settings = this.state.cell.settings;
                    if (!this.state.tab.settings) {
                        this.state.tab.settings = {};
                    }

                    if (this.state.cell.typeOfBlock === 'input') {
                        this.state.tab.celltype = {
                            title: "Text",
                            subtitle: "Write text in the cell",
                            icon: "file-text",
                            value: "text"
                        };
                    } else if (this.state.cell.typeOfBlock === 'img') {
                        this.state.tab.celltype = {
                            title: "Image",
                            subtitle: "Upload an image to the cell",
                            icon: "image",
                            value: "image"
                        };
                    } else if (this.state.cell.typeOfBlock === 'preview') {
                        this.state.tab.celltype = {
                            title: "Preview",
                            subtitle: "Preview an ad creative",
                            icon: "image",
                            value: "preview",
                        };
                        if (cell.preview_placement && cell.preview_placement.value === "story") {
                            this.state.tab.settings.selected_placement = { id: 1, name: "Story", description: "Ads that appear in a user's swipable content", value: "story", icon: <ViewBoardsIcon style={{ width: 14, height: 14 }} />, allowed: { facebook: true, snapchat: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "no_frame") {
                            this.state.tab.settings.selected_placement = { id: 2, name: "No frame", description: "Remove ad frame and only show media", value: "no_frame", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, allowed: { facebook: true, linkedin: true, tiktok: true, twitter: true, snapchat: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "feed_instagram") {
                            this.state.tab.settings.selected_placement = { id: 3, name: "Feed (Instagram)", description: "Ads that appear in a users scrollable content on Instagram", icon: <ViewBoardsIcon style={{ width: 14, height: 14, transform: 'rotate(-90deg)' }} />, value: "feed_instagram", allowed: { facebook: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "story_instagram") {
                            this.state.tab.settings.selected_placement = { id: 4, name: "Story (Instagram)", description: "Ads that appear in a users swipable content on Instagram", icon: <ViewBoardsIcon style={{ width: 14, height: 14 }} />, value: "story_instagram", allowed: { facebook: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "square") {
                            this.state.tab.settings.selected_placement = { id: 5, name: "Square", description: "Display ad in a square format", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, value: "square", allowed: { google: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "billboard") {
                            this.state.tab.settings.selected_placement = { id: 6, name: "Billboard", description: "Display ad in a rectangle format", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, value: "billboard", allowed: { google: true } }
                        } else if (cell.preview_placement && cell.preview_placement.value === "skyscraper") {
                            this.state.tab.settings.selected_placement = { id: 7, name: "Skyscraper", description: "Display ad in a vertical format", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, value: "skyscraper", allowed: { google: true } }
                        } else {
                            this.state.tab.settings.selected_placement = { id: 0, name: "Feed", description: "Ads that appear in a user's scrollable content", value: "feed", icon: <ViewBoardsIcon style={{ width: 14, height: 14, transform: 'rotate(-90deg)' }} />, allowed: { facebook: true, linkedin: true, tiktok: true, twitter: true } }
                        }
                    } else if (this.state.cell.typeOfBlock === 'number') {
                        this.state.tab.celltype = {
                            id: this.state.cell.celltype && this.state.cell.celltype.id ? this.state.cell.celltype.id : null,
                            title: "Aggregated number",
                            subtitle: "Summarize data from datasources",
                            icon: "tag",
                            value: "number"
                        };
                        this.state.tab.metrics = this.state.cell.metrics;
                        this.state.tab.daterange = this.state.cell.daterange;
                        if (!this.state.cell.channel_breakdowns) {
                            this.state.tab.channel_breakdowns = {
                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "facebook": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "google": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "bidtheatre": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "twitter": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "bing": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                                "dv360": { "overview": { name: "Overview", value: "overview", parent: "Popular" } }
                            }
                        } else {
                            this.state.tab.channel_breakdowns = this.state.cell.channel_breakdowns;
                        }
                    } else if (this.state.cell.typeOfBlock === 'chart') {
                        this.state.tab.celltype = {
                            title: "Chart",
                            subtitle: "Show data in a chart",
                            icon: "pie-chart",
                            value: "chart"
                        };
                        this.state.tab.breakdown = this.state.cell.breakdown;
                        this.state.tab.chart = this.state.cell.chart;
                        this.state.tab.metrics = this.state.cell.metrics;
                        this.state.tab.daterange = this.state.cell.daterange;
                        this.state.tab.channel_breakdowns = this.state.cell.channel_breakdowns;
                    } else if (this.state.cell.typeOfBlock === 'table') {
                        this.state.tab.celltype = {
                            title: "Table",
                            subtitle: "Show data in a table",
                            icon: "align-justify",
                            value: "table"
                        };
                        this.state.tab.breakdown = this.state.cell.breakdown;
                        this.state.tab.chart = this.state.cell.chart;
                        this.state.tab.metrics = this.state.cell.metrics;
                        this.state.tab.table_metrics_index = this.state.cell.table_metrics_index;
                        this.state.tab.daterange = this.state.cell.daterange;
                        this.state.tab.channel_breakdowns = this.state.cell.channel_breakdowns;
                    }

                    //SET TABLE REMOVES
                    this.state.tab.remove_preview = this.state.cell.remove_preview;
                    this.state.tab.remove_breakdown = this.state.cell.remove_breakdown;
                    this.state.tab.remove_breakdown_value = this.state.cell.remove_breakdown_value;
                    this.state.tab.remove_channel = this.state.cell.remove_channel;
                    this.state.tab.remove_id = this.state.cell.remove_id;
                    this.state.tab.remove_level = this.state.cell.remove_level;
                    this.state.tab.remove_name = this.state.cell.remove_name;

                    this.state.tab.group = this.state.cell.group;

                    //SET COLOR AND NAME
                    if (this.state.selected_campaigns_internal) {
                        for (let id in this.state.selected_campaigns_internal) {
                            if (this.state.tab.settings && this.state.tab.settings.color && this.state.tab.settings.color[id]) {
                                this.state.selected_campaigns_internal[id].hex = this.state.tab.settings.color[id];
                            }
                            this.state.campaigns.map((item) => {
                                if (item.id == id) {
                                    this.state.selected_campaigns_internal[id].name = item.name;
                                }
                            })
                        }
                    }
                    if (this.state.selected_adgroups_internal) {
                        for (let id in this.state.selected_adgroups_internal) {
                            if (this.state.tab.settings && this.state.tab.settings.color && this.state.tab.settings.color[id]) {
                                this.state.selected_adgroups_internal[id].hex = this.state.tab.settings.color[id];
                            }
                            this.state.adgroups.map((item) => {
                                if (item.id == id) {
                                    this.state.selected_adgroups_internal[id].name = item.name;
                                }
                            })
                        }
                    }
                    if (this.state.selected_ads_internal) {
                        for (let id in this.state.selected_ads_internal) {
                            if (this.state.tab.settings && this.state.tab.settings.color && this.state.tab.settings.color[id]) {
                                this.state.selected_ads_internal[id].hex = this.state.tab.settings.color[id];
                            }
                            this.state.ads.map((item) => {
                                if (item.id == id) {
                                    this.state.selected_ads_internal[id].name = item.name;
                                }
                            })
                        }
                    }

                    //CHECK IF TEMPLATE AND IF NEW OR OLD CELL
                    if (this.state.cell.typeOfBlock !== 'input' && this.state.cell.typeOfBlock !== 'img') {

                        if (!this.state.cell.data_sources) {
                            this.state.cell.data_sources = {};
                        }
                        if (!this.state.cell.data_sources.campaigns) {
                            this.state.cell.data_sources.campaigns = {};
                        }
                        if (!this.state.cell.data_sources.adgroups) {
                            this.state.cell.data_sources.adgroups = {};
                        }
                        if (!this.state.cell.data_sources.ads) {
                            this.state.cell.data_sources.ads = {};
                        }

                        if (
                            this.state.template &&
                            this.state.cell.typeOfBlock &&
                            Object.keys(this.state.cell.data_sources.campaigns).length < 1 &&
                            Object.keys(this.state.cell.data_sources.adgroups).length < 1 &&
                            Object.keys(this.state.cell.data_sources.ads).length < 1
                        ) {
                            this.state.celltype = false;
                            this.state.datasource = true;
                        }
                    }

                } else {

                    if (group) {
                        this.state.tab.group = group;
                    }

                    this.state.tab.cellType = null;
                    this.state.tab.metrics = {};
                    this.state.tab.channel_breakdowns = {
                        "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "adform": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "facebook": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "google": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "bidtheatre": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "twitter": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "bing": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "dv360": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "google_search_console": { "overview": { name: "Overview", value: "overview", parent: "Popular" } },
                        "basic": {
                            "overview": {
                                title: "Overview",
                                subtitle: "Aggregated data",
                                icon: "globe",
                                value: "overview"
                            }
                        }
                    };

                }

                this.setState({
                    celltype: this.state.celltype,
                    datasource: this.state.datasource,
                    update_cell: this.state.update_cell,
                    cell: this.state.cell,
                    tab: this.state.tab
                })

            })
        },
        valid: () => {

            let valid = false;

            //TEXT AND IMAGE
            if (this.state.tab.celltype && (this.state.tab.celltype.value === "image" || this.state.tab.celltype.value === "text")) {
                valid = true;
            }

            //PREVIEW
            if (this.state.tab.celltype && this.state.tab.celltype.value === "preview") {
                let datasource = this.functions.adsOnlySelected() || this.functions.accountsOnlySelected() || this.functions.channelsOnlySelected();
                if (datasource) {
                    valid = true;
                }
            }

            //NUMBER
            if (this.state.tab.celltype && this.state.tab.celltype.value === "number") {
                let datasource = this.functions.anythingSelected();
                let daterange = ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") || (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date));
                let metrics = this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0;
                if (datasource && daterange && metrics) {
                    valid = true;
                }
            }

            //TABLE
            if (this.state.tab.celltype && this.state.tab.celltype.value === "table") {
                let datasource = this.functions.anythingSelected();
                let daterange = ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") || (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date));
                let breakdowns = this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0;
                let metrics = this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0;
                if (datasource && daterange && breakdowns && metrics) {
                    valid = true;
                }
            }

            //LINE OR BAR CHART
            if (this.state.tab.celltype && this.state.tab.celltype.value === "chart") {
                let datasource = this.functions.anythingSelected();
                let daterange = ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") || (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date));
                let breakdowns = this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0;
                let metrics = this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0;
                let chart = this.state.tab.chart && (this.state.tab.chart.value == "line" || this.state.tab.chart.value == "bar");
                if (datasource && daterange && breakdowns && metrics && chart) {
                    valid = true;
                }
            }

            //DONUT CHART
            if (this.state.tab.celltype && this.state.tab.celltype.value === "chart") {
                let allow = false;
                let amount_of_datasources = 0;
                let breakdowns = {};
                let channels = {};
                amount_of_datasources += Object.keys(this.state.selected_channels_internal).length;
                amount_of_datasources += Object.keys(this.state.selected_accounts_internal).length;
                amount_of_datasources += Object.keys(this.state.selected_campaigns_internal).length;
                amount_of_datasources += Object.keys(this.state.selected_adgroups_internal).length;
                amount_of_datasources += Object.keys(this.state.selected_ads_internal).length;
                Object.keys(this.state.selected_channels_internal).map((item) => {
                    channels[this.state.selected_channels_internal[item].channel] = true;
                });
                Object.keys(this.state.selected_accounts_internal).map((item) => {
                    channels[this.state.selected_accounts_internal[item].channel] = true;
                });
                Object.keys(this.state.selected_campaigns_internal).map((item) => {
                    channels[this.state.selected_campaigns_internal[item].channel] = true;
                });
                Object.keys(this.state.selected_adgroups_internal).map((item) => {
                    channels[this.state.selected_adgroups_internal[item].channel] = true;
                });
                Object.keys(this.state.selected_ads_internal).map((item) => {
                    channels[this.state.selected_ads_internal[item].channel] = true;
                });
                for (let channel in this.state.tab.channel_breakdowns) {
                    for (let breakdown in this.state.tab.channel_breakdowns[channel]) {
                        if (breakdown !== "basic") {
                            breakdowns[breakdown] = channel;
                        }
                    }
                }
                if (amount_of_datasources > 0 && Object.keys(channels).length > 1 && ("overview" in breakdowns) && Object.keys(breakdowns).length === 1) {
                    allow = true;
                } else if (amount_of_datasources > 0 && Object.keys(channels).length === 1) {
                    allow = true;
                } else if (amount_of_datasources === 1) {
                    allow = true;
                }
                let daterange = ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") || (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date));
                let metrics = this.state.tab.metrics && Object.keys(this.state.tab.metrics).length === 1;
                let chart = this.state.tab.chart && this.state.tab.chart.value == "donut";
                if (allow && daterange && breakdowns && metrics && chart) {
                    valid = true;
                }
            }

            return valid;

        },
        create: () => {
            if (this.functions.valid()) {

                if (this.state.tab.daterange && this.state.tab.daterange.value === "7_days") {
                    this.state.tab.daterange.start_date = moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "10_days") {
                    this.state.tab.daterange.start_date = moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "14_days") {
                    this.state.tab.daterange.start_date = moment(new Date()).subtract(14, 'days').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "20_days") {
                    this.state.tab.daterange.start_date = moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "30_days") {
                    this.state.tab.daterange.start_date = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "last_week") {
                    this.state.tab.daterange.start_date = moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "last_month") {
                    this.state.tab.daterange.start_date = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
                    this.state.tab.daterange.end_date = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "this_month") {
                    this.state.tab.daterange.start_date = moment().startOf('month').format("YYYY-MM-DD");
                    if (moment().startOf('month').format("DD") == "01") {
                        this.state.tab.daterange.end_date = moment().format("YYYY-MM-DD");
                    } else {
                        this.state.tab.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                    }
                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates") {

                }

                if (!this.state.tab.channel_breakdowns) {
                    this.state.tab.channel_breakdowns = {};
                }

                if (!this.state.cell) {
                    this.state.cell = {};
                }


                this.state.cell.remove_preview = this.state.tab.remove_preview;
                this.state.cell.remove_name = this.state.tab.remove_name;
                this.state.cell.remove_id = this.state.tab.remove_id;
                this.state.cell.remove_level = this.state.tab.remove_level;
                this.state.cell.remove_breakdown_value = this.state.tab.remove_breakdown_value;
                this.state.cell.remove_breakdown = this.state.tab.remove_breakdown;
                this.state.cell.remove_channel = this.state.tab.remove_channel;

                this.state.cell.group = this.state.tab.group ? this.state.tab.group : 1;

                this.state.cell.channel_breakdowns = this.state.tab.channel_breakdowns;
                this.state.cell.daterange = this.state.tab.daterange;
                this.state.cell.celltype = this.state.tab.celltype;
                this.state.cell.metrics = this.state.tab.metrics;

                this.state.cell.table_metrics_index = this.state.tab.table_metrics_index;
                this.state.cell.chart = this.state.tab.chart;
                this.state.cell.settings = this.state.tab.settings;

                this.state.cell.data_sources = {
                    channels: this.state.selected_channels_internal,
                    accounts: this.state.selected_accounts_internal,
                    campaigns: this.state.selected_campaigns_internal,
                    adgroups: this.state.selected_adgroups_internal,
                    ads: this.state.selected_ads_internal
                };

                //SET COLOR TO SETTINGS
                if (this.state.cell.settings && this.state.cell.data_sources) {
                    this.state.cell.settings.color = {};
                    for (let level in this.state.cell.data_sources) {
                        for (let id in this.state.cell.data_sources[level]) {
                            this.state.cell.settings.color[id] = this.state.cell.data_sources[level][id].hex;
                        }
                    }
                }

                //REMOVE COMPARE IF NOT VALID
                if (!this.functions.compareFormValidation()) {
                    delete this.state.cell.daterange.compare_dates;
                    delete this.state.tab.daterange.compare_dates;
                }

                //UPDATE CAMPAIGNS
                this.props.updateData(
                    this.state.cell
                );

                this.props.toggle();

            } else {

                //NOT VALID

            }


        },
        anythingSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 0
        },
        channelsOnlySelected: () => {
            let channels = 0;
            let accounts = 0;
            let campaigns = 0;
            let adgroups = 0;
            let ads = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                channels = channels + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                accounts = accounts + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                campaigns = campaigns + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                adgroups = adgroups + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                ads = ads + 1;
            }
            return ads < 1 && campaigns < 1 && adgroups < 1 && accounts < 1 && channels > 0
        },
        accountsOnlySelected: () => {
            let accounts = 0;
            let campaigns = 0;
            let adgroups = 0;
            let ads = 0;
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                accounts = accounts + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                campaigns = campaigns + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                adgroups = adgroups + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                ads = ads + 1;
            }
            return ads < 1 && campaigns < 1 && adgroups < 1 && accounts > 0
        },
        adsOnlySelected: () => {
            let accounts = 0;
            let campaigns = 0;
            let adgroups = 0;
            let ads = 0;
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                accounts = accounts + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                campaigns = campaigns + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                adgroups = adgroups + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                ads = ads + 1;
            }
            return accounts < 1 && campaigns < 1 && adgroups < 1 && ads > 0
        },
        amountSelected: () => {
            let total = 0;
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + Object.keys(this.state.selected_accounts_internal).length;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + Object.keys(this.state.selected_campaigns_internal).length;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + Object.keys(this.state.selected_adgroups_internal).length;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + Object.keys(this.state.selected_ads_internal).length;
            }
            return total
        },
        compareFormValidation: () => {
            let amount_of_datasources = 0;
            amount_of_datasources += Object.keys(this.state.selected_accounts_internal).length;
            amount_of_datasources += Object.keys(this.state.selected_campaigns_internal).length;
            amount_of_datasources += Object.keys(this.state.selected_adgroups_internal).length;
            amount_of_datasources += Object.keys(this.state.selected_ads_internal).length;
            if ((amount_of_datasources > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart") || (this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart")) {
                return false;
            } else if (this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.chart && this.state.tab.chart.value === "donut") {
                return false;
            } else {
                return true;
            }
        }
    };

    render() {
        return (

            <Transition.Root show={!this.state.closed} as={Fragment}>
                <Dialog style={{ zIndex: 151 }} as="div" className="fixed inset-0 overflow-hidden" onClose={() => { }}>
                    <div className="absolute inset-0 overflow-hidde">
                        <Dialog.Overlay className="absolute inset-0 bg-purple-900 bg-opacity-50" />
                        <div className="pointer-events-none fixed inset-y-0 p-4 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className={"max-w-xl pointer-events-auto rounded-md overflow-hidden w-screen flex"}>
                                    <div className="h-full w-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="py-4 px-4 sm:px-6 bg-purple-900 ">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="flex flex-1 flex-row pr-14">
                                                    <div className="text-xl flex-1 flex leading-6 font-semibold text-white">
                                                        Create/Update cell
                                                    </div>
                                                </Dialog.Title>
                                                <div onClick={() => {
                                                    this.props.toggle();
                                                }} className="ml-3 cursor-pointer h-12 w-12 flex justify-center transition-colors ease-in duration-75 hover:bg-red-700 text-white items-center rounded-full bg-red-500 ">
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="flex-1 flex flex-col justify-between">

                                                {/*SELECT CELL TYPE*/}
                                                <SelectCellType
                                                    tab={this.state.tab}
                                                    selected={this.state.tab.celltype ? this.state.tab.celltype : null}
                                                    open={this.state.celltype}
                                                    onTab={(tab) => {
                                                        this.setState({
                                                            tab: tab
                                                        })
                                                    }}
                                                    onToggle={(value) => {
                                                        this.setState({
                                                            celltype: !value
                                                        })
                                                    }}
                                                    onSelect={(celltype) => {

                                                        /*
                                                        if (celltype.value == "number") {
                                                            this.state.tab.channel_breakdowns = {
                                                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "bing": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                "dv360": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            };
                                                        }
                                                        */

                                                        this.state.tab.celltype = celltype;
                                                        if (!this.state.tab.group) {
                                                            this.state.tab.group = 0;
                                                        }
                                                        this.setState({
                                                            tab: this.state.tab,
                                                            celltype: false,
                                                            datasource: (celltype.value !== "image" && celltype.value !== "text"),
                                                            settings: (celltype.value === "image" || celltype.value === "text")
                                                        })
                                                    }}
                                                    onNext={() => {
                                                        this.setState({
                                                            celltype: false,
                                                            datasource: (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "text"),
                                                            settings: (this.state.tab.celltype.value === "image" || this.state.tab.celltype.value === "text")
                                                        })
                                                    }}
                                                />

                                                {/*SELECT CELL DATASOURCE*/}
                                                {
                                                    this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "text") &&
                                                    <SelectCellDatasource
                                                        masterTemplate={this.state.masterTemplate}
                                                        template={this.state.template}
                                                        tab={this.state.tab}
                                                        accounts={this.state.accounts}
                                                        campaigns={this.state.campaigns}
                                                        adgroups={this.state.adgroups}
                                                        ads={this.state.ads}
                                                        selectedChannelsInternal={this.state.selected_channels_internal}
                                                        selectedAccountsInternal={this.state.selected_accounts_internal}
                                                        selectedCampaignsInternal={this.state.selected_campaigns_internal}
                                                        selectedAdgroupsInternal={this.state.selected_adgroups_internal}
                                                        selectedAdsInternal={this.state.selected_ads_internal}
                                                        open={this.state.datasource}
                                                        onTab={(tab) => {
                                                            console.log(tab);
                                                            this.setState({
                                                                tab: tab
                                                            });
                                                        }}
                                                        getAdgroups={(campaign) => {
                                                            if (this.props.getAdgroups) {
                                                                this.props.getAdgroups(campaign);
                                                            }
                                                        }}
                                                        getAds={(adgroup) => {
                                                            if (this.props.getAds) {
                                                                this.props.getAds(adgroup);
                                                            }
                                                        }}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                datasource: !value
                                                            })
                                                        }}
                                                        onCampaigns={(campaigns) => {
                                                            this.setState({
                                                                campaigns: campaigns
                                                            })
                                                        }}
                                                        onSelectedChannels={(channels) => {
                                                            this.setState({
                                                                selected_channels_internal: channels
                                                            })
                                                        }}
                                                        onSelectedAccounts={(accounts) => {
                                                            this.setState({
                                                                selected_accounts_internal: accounts
                                                            })
                                                        }}
                                                        onSelectedCampaigns={(campaigns) => {
                                                            this.setState({
                                                                selected_campaigns_internal: campaigns
                                                            })
                                                        }}
                                                        onAdgroups={(adgroups) => {
                                                            this.setState({
                                                                adgroups: adgroups
                                                            })
                                                        }}
                                                        onSelectedAdgroups={(adgroups) => {
                                                            this.setState({
                                                                selected_adgroups_internal: adgroups
                                                            })
                                                        }}
                                                        onAds={(ads) => {
                                                            this.setState({
                                                                ads: ads
                                                            })
                                                        }}
                                                        onSelectedAds={(ads) => {
                                                            this.setState({
                                                                selected_ads_internal: ads
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                datasource: false,
                                                                //daterange: this.state.premium ? (this.state.tab.celltype.value !== "chart" && this.state.tab.celltype.value !== "table") : false,
                                                                breakdown: true
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SELECT CELL BREAKDOWN*/}
                                                {
                                                    this.functions.anythingSelected() &&
                                                    this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "preview" && this.state.tab.celltype.value !== "text") &&
                                                    <SelectCellBreakdown
                                                        masterTemplate={this.state.masterTemplate}
                                                        tab={this.state.tab}
                                                        open={this.state.breakdown}
                                                        selectedChannelsInternal={this.state.selected_channels_internal}
                                                        selectedAccountsInternal={this.state.selected_accounts_internal}
                                                        selectedCampaignsInternal={this.state.selected_campaigns_internal}
                                                        selectedAdgroupsInternal={this.state.selected_adgroups_internal}
                                                        selectedAdsInternal={this.state.selected_ads_internal}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                breakdown: !value
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                breakdown: false,
                                                                daterange: !(this.state.premium && this.state.tab.celltype.value === "chart"),
                                                                chart: this.state.premium && this.state.tab.celltype.value === "chart"
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SELECT CELL CHART*/}
                                                {
                                                    this.functions.anythingSelected() &&
                                                    this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "preview" && this.state.tab.celltype.value !== "text" && this.state.tab.celltype.value !== "number" && this.state.tab.celltype.value !== "table") &&
                                                    <SelectCellChart
                                                        tab={this.state.tab}
                                                        open={this.state.chart}
                                                        selectedChannels={this.state.selected_channels_internal}
                                                        selectedAccounts={this.state.selected_accounts_internal}
                                                        selectedCampaigns={this.state.selected_campaigns_internal}
                                                        selectedAdgroups={this.state.selected_adgroups_internal}
                                                        selectedAds={this.state.selected_ads_internal}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                chart: !value
                                                            })
                                                        }}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                chart: false,
                                                                daterange: true
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SELECT CELL CHART*/}
                                                {
                                                    this.functions.anythingSelected() &&
                                                    this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "preview" && this.state.tab.celltype.value !== "text") &&
                                                    <SelectCellDaterange
                                                        tab={this.state.tab}
                                                        selectedChannels={this.state.selected_channels_internal}
                                                        selectedAccounts={this.state.selected_accounts_internal}
                                                        selectedCampaigns={this.state.selected_campaigns_internal}
                                                        selectedAdgroups={this.state.selected_adgroups_internal}
                                                        selectedAds={this.state.selected_ads_internal}
                                                        open={this.state.daterange}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                daterange: !value
                                                            })
                                                        }}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                daterange: false,
                                                                metric: true
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SELECT CELL METRIC*/}
                                                {
                                                    (
                                                        (this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates")
                                                        ||
                                                        (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)
                                                    ) &&
                                                    this.functions.anythingSelected() &&
                                                    this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "preview" && this.state.tab.celltype.value !== "text") &&
                                                    <SelectCellMetric
                                                        tab={this.state.tab}
                                                        open={this.state.metric}
                                                        metrics={this.state.metrics}
                                                        selectedChannels={this.state.selected_channels_internal}
                                                        selectedAccounts={this.state.selected_accounts_internal}
                                                        selectedCampaigns={this.state.selected_campaigns_internal}
                                                        selectedAdgroups={this.state.selected_adgroups_internal}
                                                        selectedAds={this.state.selected_ads_internal}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                metric: !value
                                                            })
                                                        }}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                metric: false,
                                                                settings: true
                                                            })
                                                        }}
                                                    />
                                                }

                                                {/*SELECT CELL SETTINGS*/}
                                                {
                                                    (
                                                        (this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0 && ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") || (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)) && this.functions.anythingSelected() && this.state.tab.celltype && (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "preview" && this.state.tab.celltype.value !== "text"))
                                                        ||
                                                        (this.state.tab.celltype && (this.state.tab.celltype.value == "image" || this.state.tab.celltype.value == "text"))
                                                        ||
                                                        (this.functions.anythingSelected() && this.state.tab.celltype && (this.state.tab.celltype.value == "preview"))
                                                    ) &&
                                                    <SelectCellSettings
                                                        masterTemplate={this.state.masterTemplate}
                                                        tab={this.state.tab}
                                                        open={this.state.settings}
                                                        selectedChannelsInternal={this.state.selected_channels_internal}
                                                        selectedAccountsInternal={this.state.selected_accounts_internal}
                                                        selectedCampaignsInternal={this.state.selected_campaigns_internal}
                                                        selectedAdgroupsInternal={this.state.selected_adgroups_internal}
                                                        selectedAdsInternal={this.state.selected_ads_internal}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                settings: !value
                                                            })
                                                        }}
                                                        onSelected={(object) => {
                                                            this.setState({
                                                                selected_campaigns_internal: object.selected_campaigns_internal,
                                                                selected_adgroups_internal: object.selected_adgroups_internal,
                                                                selected_ads_internal: object.selected_ads_internal
                                                            });
                                                        }}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        onNext={() => {
                                                            this.setState({
                                                                settings: false
                                                            })
                                                        }}
                                                    />
                                                }
                                            </div>

                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            <button
                                                onClick={() => {
                                                    if (this.functions.valid()) {
                                                        this.functions.create();
                                                    }
                                                }}
                                                className={(this.state.success ? "cursor-pointer bg-green-700 text-white" : (this.functions.valid() ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600")) + " shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                }
                                            >
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }

}

class SelectCellType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            selected: {}
        };
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            tab: this.props.tab,
            selected: this.props.selected
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open,
            tab: nextProps.tab,
            selected: nextProps.selected
        })
    }

    functions = {
        getCellTypes: () => {
            let celltypes = [
                {
                    id: "preview",
                    title: "Preview",
                    subtitle: "Select ad to preview its creative",
                    icon: "image",
                    value: "preview"
                },
                {
                    id: "image",
                    title: "Image/Video",
                    subtitle: "Upload an image/video to the cell",
                    icon: "image",
                    value: "image"
                },
                {
                    id: "text",
                    title: "Text",
                    subtitle: "Write text in the cell",
                    icon: "file-text",
                    value: "text"
                },
                {
                    id: "number",
                    title: "Aggregated number",
                    subtitle: "Summarize data from datasources",
                    icon: "tag",
                    value: "number"
                },
                {
                    id: "performance",
                    title: "Performance tracker",
                    subtitle: "Set future goals",
                    icon: "flag",
                    value: "number"
                },
                {
                    id: "chart",
                    title: "Chart",
                    subtitle: "Show data in a chart",
                    icon: "pie-chart",
                    value: "chart"
                },
                {
                    id: "table",
                    title: "Table",
                    subtitle: "Show data in a table",
                    icon: "align-justify",
                    value: "table"
                }
            ];
            return celltypes;
        },

    };

    render() {
        return (
            <>
                <div
                    className={"px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            if (this.props.onToggle) {
                                this.props.onToggle(this.state.open);
                            }
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-semibold text-sm">
                                Select cell type
                            </h3>
                            {
                                !this.state.tab.celltype &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    There are <span
                                        className="font-bold ml-1 mr-1">{this.functions.getCellTypes().length}</span> cell
                                    types
                                    available
                                </p>
                            }
                            {
                                this.state.selected && this.state.selected.value &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    You have selected <span
                                        className="font-bold ml-1 mr-1">{this.state.selected ? this.state.selected.title : "..."}</span>
                                </p>
                            }
                        </div>
                        <div className="flex flex-row">
                            {
                                this.state.selected && this.state.selected.value &&
                                <div
                                    className=" text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                    <FeatherIcon className={'stroke-current'} size={16}
                                        icon={"check"} />
                                </div>
                            }
                            <div onClick={() => {
                                if (this.props.onToggle) {
                                    this.props.onToggle(this.state.open);
                                }
                            }}
                                className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                {
                                    !this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={17}
                                        icon={"chevron-down"} />
                                }
                                {
                                    this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={17}
                                        icon={"chevron-up"} />
                                }
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="">
                            {
                                this.functions.getCellTypes().map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div
                                                className={("cursor-pointer") + (i !== (this.functions.getCellTypes().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                <div
                                                    className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'}
                                                        size={16}
                                                        icon={item.icon} />
                                                </div>
                                                <div onClick={() => {
                                                    if (this.props.onSelect) {
                                                        this.props.onSelect(item);
                                                    }
                                                }}
                                                    className={((this.state.selected && (this.state.selected.id ? this.state.selected.id == item.id : this.state.selected.value === item.value)) ? "border-purple-500" : "") + " h-10 w-10 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                    {
                                                        this.state.selected && (this.state.selected.id ? this.state.selected.id == item.id : this.state.selected.value === item.value) &&
                                                        <div className="w-full h-full rounded-full bg-purple-500" />
                                                    }
                                                </div>
                                                <div className="flex flex-1 flex-col">
                                                    <div className="font-medium text-sm">
                                                        {item.title}
                                                    </div>
                                                    <div className="text-xxs">
                                                        {item.subtitle}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.selected && this.state.selected.value &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} class="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabindex="0">Next</button>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }
}

class SelectCellDatasource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            accounts: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            search_campaigns: ""
        };
    };

    componentDidMount() {
        this.setState({
            masterTemplate: this.props.masterTemplate,
            template: this.props.template,
            tab: this.props.tab,
            open: this.props.open,
            accounts: this.props.accounts ? this.props.accounts : [],
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            selected_channels_internal: this.props.selectedChannelsInternal ? this.props.selectedChannelsInternal : {},
            selected_account_internal: this.props.selectedAccountsInternal ? this.props.selectedAccountsInternal : {},
            selected_campaigns_internal: this.props.selectedCampaignsInternal ? this.props.selectedCampaignsInternal : {},
            selected_adgroups_internal: this.props.selectedAdgroupsInternal ? this.props.selectedAdgroupsInternal : {},
            selected_ads_internal: this.props.selectedAdsInternal ? this.props.selectedAdsInternal : {}
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            masterTemplate: nextProps.masterTemplate,
            template: nextProps.template,
            tab: nextProps.tab,
            open: nextProps.open,
            accounts: nextProps.accounts ? nextProps.accounts : [],
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            ads: nextProps.ads ? nextProps.ads : [],
            selected_channels_internal: nextProps.selectedChannelsInternal ? nextProps.selectedChannelsInternal : {},
            selected_accounts_internal: nextProps.selectedAccountsInternal ? nextProps.selectedAccountsInternal : {},
            selected_campaigns_internal: nextProps.selectedCampaignsInternal ? nextProps.selectedCampaignsInternal : {},
            selected_adgroups_internal: nextProps.selectedAdgroupsInternal ? nextProps.selectedAdgroupsInternal : {},
            selected_ads_internal: nextProps.selectedAdsInternal ? nextProps.selectedAdsInternal : {}
        });
    }

    functions = {
        color: (item) => {
            let colors = {
                hex: "",
                rgb: ""
            }
            colors.hex = "#3A5998";
            if (item.channel === "facebook") {
                colors.hex = "#3A5998";
            } else if (item.channel === "google") {
                colors.hex = "#4384F4";
            } else if (item.channel === "linkedin") {
                colors.hex = "#0D65A1";
            } else if (item.channel === "google_analytics") {
                colors.hex = "#e8710a";
            } else if (item.channel === "google_analytics_4") {
                colors.hex = "#e8710a";
            } else if (item.channel === "google_shopping") {
                colors.hex = "#34A853";
            } else if (item.channel === "adform") {
                colors.hex = "#60C1A4";
            } else if (item.channel === "bidtheatre") {
                colors.hex = "#f48e22";
            } else if (item.channel === "tiktok") {
                colors.hex = "#000000";
            } else if (item.channel === "twitter") {
                colors.hex = "#50ABF1";
            } else if (item.channel === "snapchat") {
                colors.hex = "#FFFC00";
            } else if (item.channel === "bing") {
                colors.hex = "#0C8484";
            } else if (item.channel === "dv360") {
                colors.hex = "#34A853";
            } else if (item.channel === "google_search_console") {
                colors.hex = "#34A145";
            }
            colors.rgb = colors.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                , (m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1).match(/.{2}/g)
                .map(x => parseInt(x, 16));
            return colors;
        },
        valid: () => {
            return true;
        },
        multipleLevelsSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 1
        },
        anythingSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 0
        }
    };

    renders = {
        preview: () => {
            try {
                return this.state.tab.celltype.value === "preview"
            } catch (error) {
                return false
            }
        },
        search: (search, options) => {
            if (!search || search == "") {
                return options;
            }
            let result = fuzzysort.go(search, options.map((item) => { return item.name }));
            let keywords = [];
            let old_keywords = JSON.parse(JSON.stringify(options));
            result.map((item) => {
                for (let i = 0; i < old_keywords.length; i++) {
                    if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                        let html_string = "";
                        if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                            for (let m = 0; m < item.target.length; m++) {
                                if (!item.indexes.includes(m)) {
                                    html_string = html_string + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                } else {
                                    html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                }
                            }
                        }
                        old_keywords[i].html = html_string;
                        keywords.push(old_keywords[i]);
                        old_keywords.splice(i, 1);
                        break;
                    }
                }
            });
            return keywords;
        }
    };

    render() {
        return (
            <>
                <div
                    className={(this.functions.valid() ? " " : " opacity-50 ") + " pt-6 px-4 sm:px-6 pb-6 border-b"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            if (this.props.onToggle) {
                                this.props.onToggle(this.state.open);
                            }
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 text-sm font-semibold">
                                Select data source
                            </h3>
                            {
                                !this.functions.anythingSelected() &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Display data from selected data sources
                                </p>
                            }
                            {
                                this.functions.anythingSelected() &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    You have selected
                                    {
                                        this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_channels_internal).length} channels</span>
                                    }
                                    {
                                        this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_accounts_internal).length} accounts</span>
                                    }
                                    {
                                        this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_campaigns_internal).length} campaigns</span>
                                    }
                                    {
                                        this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_adgroups_internal).length} adgroups</span>
                                    }
                                    {
                                        this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_ads_internal).length} ads</span>
                                    }
                                </p>
                            }
                        </div>
                        <div className="flex flex-row">
                            {
                                this.functions.anythingSelected()
                                &&
                                <div
                                    className=" text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                    <FeatherIcon className={'stroke-current'} size={16}
                                        icon={"check"} />
                                </div>
                            }
                            <div onClick={() => {
                                if (this.props.onToggle) {
                                    this.props.onToggle(this.state.open);
                                }
                            }}
                                className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                {
                                    !this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={17}
                                        icon={"chevron-down"} />
                                }
                                {
                                    this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={17}
                                        icon={"chevron-up"} />
                                }
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        {
                            (this.state.masterTemplate || this.state.template) &&
                            <Fragment>
                                <div className="w-full h-4" />
                            </Fragment>
                        }
                        {
                            (this.state.masterTemplate || this.state.template) &&
                            [
                                { id: "adform", name: "adform", value: "adform", channel: "adform" },
                                { id: "bidtheatre", name: "bidtheatre", value: "bidtheatre", channel: "bidtheatre" },
                                { id: "bing", name: "bing", value: "bing", channel: "bing" },
                                { id: "dv360", name: "dv360", value: "dv360", channel: "dv360" },
                                { id: "facebook", name: "facebook", value: "facebook", channel: "facebook" },
                                { id: "google", name: "google", value: "google", channel: "google" },
                                { id: "google_analytics_4", name: "Google Analytics 4", value: "google_analytics_4", channel: "google_analytics_4" },
                                { id: "linkedin", name: "linkedin", value: "linkedin", channel: "linkedin" },
                                { id: "snapchat", name: "snapchat", value: "snapchat", channel: "snapchat" },
                                { id: "tiktok", name: "tiktok", value: "tiktok", channel: "tiktok" },
                                { id: "twitter", name: "twitter", value: "twitter", channel: "twitter" }
                            ].map((account, index) => {
                                return (
                                    <div className="flex flex-1 flex-row justify-center mb-2">
                                        <div className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">

                                        </div>
                                        {
                                            this.renders.preview() &&
                                            <div onClick={() => {
                                                if (!account.is_loading) {
                                                    if (this.state.selected_channels_internal[account.id]) {
                                                        delete this.state.selected_channels_internal[account.id];
                                                    } else {
                                                        this.state.selected_channels_internal = {};
                                                        let colors = this.functions.color(account);
                                                        account.hex = colors.hex;
                                                        account.rgb = colors.rgb;
                                                        this.state.selected_channels_internal[account.id] = account;
                                                        if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                            this.state.tab.breakdown = {
                                                                title: "Ads",
                                                                subtitle: "Ads",
                                                                icon: "globe",
                                                                value: "ads"
                                                            };
                                                        }
                                                    }
                                                    this.props.onTab(this.state.tab);
                                                    this.props.onSelectedChannels(this.state.selected_channels_internal);
                                                }
                                            }}
                                                className={((this.state.selected_channels_internal[account.id]) ? "border-purple-500" : "") + " h-10 w-10 cursor-pointer p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                {
                                                    this.state.selected_channels_internal[account.id] &&
                                                    <div
                                                        className="w-full h-full rounded-full bg-purple-500" />
                                                }
                                            </div>
                                        }
                                        {
                                            !this.renders.preview() &&
                                            <div onClick={() => {
                                                if (!account.is_loading) {
                                                    if (this.state.selected_channels_internal[account.id]) {
                                                        delete this.state.selected_channels_internal[account.id]
                                                    } else {
                                                        let colors = this.functions.color(account);
                                                        account.hex = colors.hex;
                                                        account.rgb = colors.rgb;
                                                        this.state.selected_channels_internal[account.id] = account;
                                                    }
                                                    this.props.onSelectedChannels(this.state.selected_channels_internal);
                                                }
                                            }}
                                                className={(this.state.selected_channels_internal[account.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                {
                                                    this.state.selected_channels_internal[account.id] &&
                                                    <FeatherIcon className={'stroke-current'}
                                                        size={14}
                                                        icon="check" />
                                                }
                                                {
                                                    account.is_loading &&
                                                    <div
                                                        className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                        <BeatLoader
                                                            sizeUnit={"px"}
                                                            size={4}
                                                            color={'#060534'}
                                                            loading={true}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            account.channel === "google" &&
                                            <div
                                                className="bg-google-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/google_icon.svg')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "google_shopping" &&
                                            <div
                                                className="bg-googleshopping-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/google-shopping.png')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "linkedin" &&
                                            <div
                                                className="bg-linkedin-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/linkedin_icon.svg')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "facebook" &&
                                            <div
                                                className="bg-facebook-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img style={{ width: '0.6rem' }}
                                                    src={require('../assets/images/facebook_icon.svg')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "google_analytics" &&
                                            <div
                                                className="bg-googleanalytics-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/google_analytics_icon.png')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "google_analytics_4" &&
                                            <div
                                                className="bg-googleanalytics-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/ga_icon.png')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "adform" &&
                                            <div
                                                className="bg-adform-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/adform_icon.png')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "twitter" &&
                                            <div
                                                className="bg-twitter-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/twitter_icon.svg')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "tiktok" &&
                                            <div
                                                className="bg-tiktok-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/tiktok_icon.png')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "bidtheatre" &&
                                            <div
                                                className="bg-bidtheatre-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/bidtheatre_icon.png')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "snapchat" &&
                                            <div
                                                className="bg-snapchat-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/snapchat.svg')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "bing" &&
                                            <div
                                                className="bg-bing-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/bing-logo.png')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "dv360" &&
                                            <div
                                                className="bg-dv360-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                <img className="w-4"
                                                    src={require('../assets/images/dv360-logo.svg')} />
                                            </div>
                                        }
                                        {
                                            account.channel === "google_search_console" &&
                                            <div
                                                className="bg-dv360-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                <img className="w-3"
                                                    src={require('../assets/images/google_search_console.png')} />
                                            </div>
                                        }
                                        <div className="flex flex-1 flex-col truncate">
                                            <div className="font-semibold text-sm truncate">
                                                {account.name}
                                            </div>
                                            <div className="text-xxs flex flex-row">
                                                Account level
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            this.state.masterTemplate &&
                            <div>
                                <div className="text-xs font-bold mt-4 mb-1">
                                    Account level data
                                </div>
                                <div className='flex flex-col w-full'>
                                    <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className={"flex flex-1 text-sm ml-5"}>
                                            {this.state.tab.settings && this.state.tab.settings.overwrite_tags ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.tab.settings && this.state.tab.settings.overwrite_tags ? true : false}
                                                onSwitch={async () => {
                                                    if (!this.state.tab.settings) {
                                                        this.state.tab.settings = { title: "" };
                                                    }
                                                    this.state.tab.settings.overwrite_tags = !this.state.tab.settings.overwrite_tags;
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.masterTemplate &&
                            <div className="mt-4 bg-blue-100 text-blue-500 font-medium text-sm p-4 rounded-md">
                                OBS! If enabled this cell will always fetch data from account level
                            </div>
                        }
                        {
                            !this.state.masterTemplate &&
                            !this.state.template &&
                            this.state.accounts.length > 0 &&
                            <Fragment>
                                <div className="w-full h-6" />
                            </Fragment>
                        }
                        {
                            !this.state.masterTemplate &&
                            !this.state.template &&
                            <div className="flex flex-1 mb-4 flex-col justify-center">
                                <InputTailwind
                                    label={null}
                                    placeholder={"Search campaigns ..."}
                                    value={this.state.search_campaigns}
                                    onChange={(value) => {
                                        this.setState({
                                            search_campaigns: value
                                        });
                                    }}
                                />
                            </div>
                        }
                        {
                            !this.state.masterTemplate &&
                            !this.state.template &&
                            <div className="text-xs mt-2 font-medium mb-1">Accounts</div>
                        }
                        {
                            !this.state.masterTemplate &&
                            !this.state.template &&
                            this.state.accounts.map((account, index) => {
                                return (
                                    <Fragment>
                                        <div className="flex flex-1 flex-row justify-center mb-2">
                                            <div>
                                                <div onClick={() => {
                                                    account.show_campaigns = !account.show_campaigns;
                                                    this.setState({
                                                        accounts: this.state.accounts
                                                    });
                                                }} className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    {
                                                        !account.show_campaigns &&
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={14}
                                                            icon="plus" />
                                                    }
                                                    {
                                                        account.show_campaigns &&
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={14}
                                                            icon="minus" />
                                                    }
                                                </div>
                                            </div>
                                            {
                                                this.renders.preview() &&
                                                <div>
                                                    <div onClick={() => {
                                                        if (account.channel === "google" || account.channel === "bing" || account.channel === "facebook" || account.channel === "tiktok" || account.channel === "linkedin" || account.channel === "snapchat") {
                                                            if (!account.is_loading) {
                                                                if (this.state.selected_accounts_internal[account.id]) {
                                                                    delete this.state.selected_accounts_internal[account.id]
                                                                } else {

                                                                    this.state.selected_accounts_internal = {};
                                                                    this.state.selected_ads_internal = {};

                                                                    let colors = this.functions.color(account);
                                                                    account.hex = colors.hex;
                                                                    account.rgb = colors.rgb;
                                                                    this.state.selected_accounts_internal[account.id] = account;

                                                                    if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                        this.state.tab.breakdown = {
                                                                            title: "Ads",
                                                                            subtitle: "Ads",
                                                                            icon: "globe",
                                                                            value: "ads"
                                                                        };
                                                                    }

                                                                }
                                                                this.props.onTab(this.state.tab);
                                                                this.props.onSelectedAccounts(this.state.selected_accounts_internal);
                                                                this.props.onSelectedAds(this.state.selected_ads_internal);
                                                            }
                                                        }
                                                    }}
                                                        className={((this.state.selected_accounts_internal[account.id]) ? "border-purple-500" : "") + " h-10 w-10 cursor-pointer p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                        {
                                                            this.state.selected_accounts_internal[account.id] &&
                                                            <div
                                                                className="w-full h-full rounded-full bg-purple-500" />
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !this.renders.preview() &&
                                                <div>
                                                    <div onClick={() => {
                                                        if (!account.is_loading) {
                                                            if (this.state.selected_accounts_internal[account.id]) {
                                                                delete this.state.selected_accounts_internal[account.id]
                                                            } else {
                                                                let colors = this.functions.color(account);
                                                                account.hex = colors.hex;
                                                                account.rgb = colors.rgb;
                                                                this.state.selected_accounts_internal[account.id] = account;
                                                            }
                                                            this.props.onSelectedAccounts(this.state.selected_accounts_internal);
                                                        }
                                                    }}
                                                        className={(this.state.selected_accounts_internal[account.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                        {
                                                            this.state.selected_accounts_internal[account.id] &&
                                                            <FeatherIcon className={'stroke-current'}
                                                                size={14}
                                                                icon="check" />
                                                        }
                                                        {
                                                            account.is_loading &&
                                                            <div
                                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                <BeatLoader
                                                                    sizeUnit={"px"}
                                                                    size={4}
                                                                    color={'#060534'}
                                                                    loading={true}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {
                                                [
                                                    { name: 'facebook', icon: 'facebook_icon.svg', style: { width: '0.6rem' } },
                                                    { name: 'google', icon: 'google_icon.svg', style: { width: '1.1rem' } },
                                                    { name: 'google_analytics', icon: 'google_analytics_icon.png', style: { width: '0.75rem' } },
                                                    { name: 'google_analytics_4', icon: 'ga_icon.png', style: { width: '0.75rem' } },
                                                    { name: 'google_shopping', icon: 'google_shopping_icon.png', style: { width: '0.75rem' } },
                                                    { name: 'adform', icon: 'adform_icon.png', style: { width: '0.75rem' } },
                                                    { name: 'linkedin', icon: 'linkedin_icon.svg', style: { width: '1rem' } },
                                                    { name: 'twitter', icon: 'twitter_icon.svg', style: { width: '0.75rem' } },
                                                    { name: 'bidtheatre', icon: 'bidtheatre_icon.png', style: { width: '0.75rem' } },
                                                    { name: 'snapchat', icon: 'snapchat.svg', style: { width: '0.75rem' } },
                                                    { name: 'bing', icon: 'bing-logo.png', style: { width: '0.75rem' } },
                                                    { name: 'dv360', icon: 'dv360-logo.svg', style: { width: '1rem' } },
                                                    { name: 'tiktok', icon: 'tiktok_icon.png', style: { width: '1rem' } }
                                                ].filter((channel) => { return (account.channel == channel.name) }).map((item) => {
                                                    return (
                                                        <div>
                                                            <div
                                                                className={"bg-" + item.name.replace('_', '').replace('_', '') + "-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center"}>
                                                                <img className={item.style ? "" : "w-3"}
                                                                    style={item.style ? item.style : {}}
                                                                    src={require('../assets/images/' + item.icon)} />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                account.custom &&
                                                <div
                                                    style={{ backgroundColor: "#" + account.backgroundColor }}
                                                    className={"mr-2 h-10 w-10 rounded flex justify-center align-middle items-center"}>
                                                    {
                                                        account.logo &&
                                                        <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + account.logo + ")" }}></div>
                                                    }
                                                    {
                                                        !account.logo &&
                                                        <div data-tip='' data-for={account.id} key={account.id + "_custom"} className="w-full h-full relative flex items-center justify-center text-semibold text-white text-sm uppercase">
                                                            {account.name[0]}{account.name[1]}
                                                            <ReactTooltip effect='solid' id={account.id}>
                                                                {account.name[0]}{account.name[1]}
                                                            </ReactTooltip>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div className="flex flex-1 flex-row">
                                                <div className="flex flex-col flex-1 justify-center truncate">
                                                    <div data-tip='' data-for={account.id + "_" + index} key={account.id} className="font-medium relative text-sm">
                                                        <div className="w-full absolute pr-2 truncate">{account.name}</div>
                                                        <ReactTooltip effect='solid' id={account.id + "_" + index}>
                                                            {account.name}
                                                        </ReactTooltip>
                                                    </div>
                                                    <div className="text-xxs flex mt-5.5 flex-row">
                                                        <span className="font-medium text-purple-500">{account.client_name ? account.client_name : ""}</span>
                                                        {
                                                            this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).filter((key) => {
                                                                return this.state.selected_campaigns_internal[key].client == account.client && this.state.selected_campaigns_internal[key].channel == account.channel;
                                                            }).length > 0 &&
                                                            <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>Campaigns <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                                        }
                                                        {
                                                            this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).filter((key) => {
                                                                return this.state.selected_adgroups_internal[key].client == account.client && this.state.selected_adgroups_internal[key].channel == account.channel;
                                                            }).length > 0 &&
                                                            <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>adgroups <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                                        }
                                                        {
                                                            this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                                return this.state.selected_ads_internal[key].client == account.client && this.state.selected_ads_internal[key].channel == account.channel;
                                                            }).length > 0 &&
                                                            <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>Ads <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                                        }
                                                    </div>
                                                </div>
                                                {((this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).filter((key) => {
                                                    return this.state.selected_campaigns_internal[key].client == account.client && this.state.selected_campaigns_internal[key].channel == account.channel;
                                                }).length > 0) || (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).filter((key) => {
                                                    return this.state.selected_adgroups_internal[key].client == account.client && this.state.selected_adgroups_internal[key].channel == account.channel;
                                                }).length > 0) || (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                    return this.state.selected_ads_internal[key].client == account.client && this.state.selected_ads_internal[key].channel == account.channel;
                                                }).length > 0)) &&
                                                    <div className="pt-2"><div onClick={() => {
                                                        if (!account.is_loading) {

                                                            //REMOVE ADS
                                                            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                                return this.state.selected_ads_internal[key].client == account.client && this.state.selected_ads_internal[key].channel == account.channel;
                                                            }).length > 0) {
                                                                Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                                    return this.state.selected_ads_internal[key].client == account.client && this.state.selected_ads_internal[key].channel == account.channel;
                                                                }).map((key) => {
                                                                    delete this.state.selected_ads_internal[key]
                                                                });
                                                                this.props.onSelectedAds(this.state.selected_ads_internal);
                                                            }

                                                            //REMOVE ADGROUPS
                                                            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).filter((key) => {
                                                                return this.state.selected_adgroups_internal[key].client == account.client && this.state.selected_adgroups_internal[key].channel == account.channel;
                                                            }).length > 0) {
                                                                Object.keys(this.state.selected_adgroups_internal).filter((key) => {
                                                                    return this.state.selected_adgroups_internal[key].client == account.client && this.state.selected_adgroups_internal[key].channel == account.channel;
                                                                }).map((key) => {
                                                                    delete this.state.selected_adgroups_internal[key]
                                                                });
                                                                this.props.onSelectedAdgroups(this.state.selected_adgroups_internal);
                                                            }

                                                            //REMOVE CAMPAIGNS
                                                            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).filter((key) => {
                                                                return this.state.selected_campaigns_internal[key].client == account.client && this.state.selected_campaigns_internal[key].channel == account.channel;
                                                            }).length > 0) {
                                                                Object.keys(this.state.selected_campaigns_internal).filter((key) => {
                                                                    return this.state.selected_campaigns_internal[key].client == account.client && this.state.selected_campaigns_internal[key].channel == account.channel;
                                                                }).map((key) => {
                                                                    delete this.state.selected_campaigns_internal[key]
                                                                });
                                                                this.props.onSelectedCampaigns(this.state.selected_campaigns_internal);
                                                            }

                                                            //REMOVE ACCOUNT
                                                            if (this.state.selected_accounts_internal) {
                                                                delete this.state.selected_accounts_internal[account.id]
                                                                this.props.onSelectedAccounts(this.state.selected_accounts_internal);
                                                            }

                                                        }
                                                    }} className="px-2 py-1 hover:bg-gray-200 bg-gray-100 rounded-full font-medium cursor-pointer text-xxs">Clear selected</div></div>
                                                }
                                                {
                                                    this.state.selected_accounts_internal[account.id] &&
                                                    <div onClick={() => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                            this.state.tab.settings.source_name = {};
                                                        } else if (!this.state.tab.settings.source_name) {
                                                            this.state.tab.settings.source_name = {};
                                                        }
                                                        if (account.id in this.state.tab.settings.source_name) {
                                                            delete this.state.tab.settings.source_name[account.id];
                                                        } else {
                                                            this.state.tab.settings.source_name[account.id] = "";
                                                        }
                                                        if (this.props.onTab) {
                                                            this.props.onTab(this.state.tab);
                                                        }
                                                    }} className="pt-2">
                                                        <div>
                                                            <div className="px-2 py-1 ml-1 hover:bg-gray-200 bg-gray-100 items-center whitespace-no-wrap rounded-full flex font-medium cursor-pointer text-xxs">
                                                                {this.state.tab.settings && this.state.tab.settings.source_name && (account.id in this.state.tab.settings.source_name) && this.state.selected_accounts_internal[account.id] ? "Remove" : "Edit"} name
                                                                {this.state.tab.settings && this.state.tab.settings.source_name && (account.id in this.state.tab.settings.source_name) && this.state.selected_accounts_internal[account.id] ? <XIcon className="w-3 ml-1" /> : <PencilAltIcon className="w-3 ml-1" />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.state.tab.settings &&
                                            this.state.tab.settings.source_name &&
                                            (account.id in this.state.tab.settings.source_name) &&
                                            this.state.selected_accounts_internal[account.id] &&
                                            <div className="flex flex-1 w-full -mt-1 pl-12 mb-2">
                                                <div className="flex flex-1">
                                                    <div className="w-full">
                                                        <InputTailwind
                                                            label={"Custom name"}
                                                            value={this.state.tab.settings.source_name[account.id]}
                                                            onChange={(value) => {
                                                                this.state.tab.settings.source_name[account.id] = value;
                                                                if (this.props.onTab) {
                                                                    this.props.onTab(this.state.tab);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/*NO ACTIVE CAMPAIGNS*/}
                                        {
                                            !this.state.masterTemplate &&
                                            !this.state.template &&
                                            account.show_campaigns &&
                                            this.state.campaigns.filter((item) => {
                                                return item.client_id == account.client && item.channel == account.channel;
                                            }).filter((campaign) => {
                                                if (account.channel == "google_analytics_4" || account.channel == "google_analytics") {
                                                    return true;
                                                } else if (account.show_paused) {
                                                    return true;
                                                } else {
                                                    return typeof campaign.status == "string" && (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enabled");
                                                }
                                            }).length < 1 &&
                                            <div className="text-orange-500 pl-12 rounded-md mb-2 p-4 text-sm font-medium">
                                                No active campaigns available <span onClick={() => {
                                                    account.show_paused = !account.show_paused;
                                                    this.setState({
                                                        accounts: this.state.accounts
                                                    });
                                                }} className="underline cursor-pointer">Click here</span> to show all non active campaigns.
                                            </div>
                                        }
                                        {/*NO MATCH CAMPAIGNS*/}
                                        {
                                            !this.state.masterTemplate &&
                                            !this.state.template &&
                                            account.show_campaigns &&
                                            this.state.campaigns.filter((item) => {
                                                return item.client_id == account.client && item.channel == account.channel;
                                            }).filter((item) => {
                                                if (typeof this.state.search_campaigns == "string" && this.state.search_campaigns !== "") {
                                                    return item.name.toLowerCase().indexOf(this.state.search_campaigns.toLowerCase()) > -1;
                                                } else {
                                                    return true;
                                                }
                                            }).filter((campaign) => {
                                                if (account.channel == "google_analytics_4" || account.channel == "google_analytics") {
                                                    return true;
                                                } else if (account.show_paused) {
                                                    return true;
                                                } else {
                                                    return typeof campaign.status == "string" && (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enabled");
                                                }
                                            }).length < 1 &&
                                            <div className="text-red-500 pl-12 rounded-md mb-2 p-4 text-sm font-medium">
                                                No matching campaigns
                                            </div>
                                        }
                                        {
                                            !this.state.masterTemplate &&
                                            !this.state.template &&
                                            (account.show_campaigns) &&
                                            <div className="text-xs font-medium mb-1 pl-12">Campaigns</div>
                                        }
                                        {
                                            !this.state.masterTemplate &&
                                            !this.state.template &&
                                            (account.show_campaigns) &&
                                            this.state.campaigns.filter((item) => {
                                                return item.client_id == account.client && item.channel == account.channel;
                                            }).filter((item) => {
                                                if (typeof this.state.search_campaigns == "string" && this.state.search_campaigns !== "") {
                                                    return item.name.toLowerCase().indexOf(this.state.search_campaigns.toLowerCase()) > -1;
                                                } else {
                                                    return true;
                                                }
                                            }).sort((a, b) => {
                                                let a_active = typeof a.status == "string" && (a.status.toLowerCase() === "active" || a.status.toLowerCase() === "enabled");
                                                let b_active = typeof b.status == "string" && (b.status.toLowerCase() === "active" || b.status.toLowerCase() === "enabled");
                                                return (a_active === b_active) ? 0 : a_active ? -1 : 1;
                                            }).filter((campaign) => {
                                                if (account.channel == "google_analytics_4" || account.channel == "google_analytics") {
                                                    return true;
                                                } else if (account.show_paused || (typeof this.state.search_campaigns == "string" && this.state.search_campaigns !== "")) {
                                                    return true;
                                                } else {
                                                    return typeof campaign.status == "string" && (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enabled");
                                                }
                                            }).map((campaign, index) => {
                                                return (
                                                    <Fragment>
                                                        <div
                                                            className="flex flex-1 flex-row justify-center mb-2 pl-12">
                                                            <div onClick={() => {
                                                                //GA ONLY HAVE CAMPAIGN LEVEL
                                                                if (campaign.channel !== "google_analytics" && campaign.channel !== "google_analytics_4") {
                                                                    if (!campaign.open) {
                                                                        if (this.props.getAdgroups) {
                                                                            this.props.getAdgroups(campaign);
                                                                            campaign.open = true;
                                                                        }
                                                                    } else {
                                                                        campaign.open = false;
                                                                    }
                                                                    this.setState({
                                                                        campaigns: this.state.campaigns
                                                                    }, () => {
                                                                        if (this.props.onCampaigns) {
                                                                            this.props.onCampaigns(this.state.campaigns);
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                                className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                {
                                                                    campaign.channel !== "google_analytics" &&
                                                                    campaign.channel !== "google_analytics_4" &&
                                                                    !campaign.open && !campaign.loading &&
                                                                    <FeatherIcon className={'stroke-current'}
                                                                        size={16}
                                                                        icon="plus" />
                                                                }
                                                                {
                                                                    campaign.channel !== "google_analytics" &&
                                                                    campaign.channel !== "google_analytics_4" &&
                                                                    campaign.open && !campaign.loading &&
                                                                    <FeatherIcon className={'stroke-current'}
                                                                        size={16}
                                                                        icon="minus" />
                                                                }
                                                                {
                                                                    campaign.channel !== "google_analytics" &&
                                                                    campaign.channel !== "google_analytics_4" &&
                                                                    campaign.loading &&
                                                                    <BeatLoader
                                                                        sizeUnit={"px"}
                                                                        size={5}
                                                                        color={'#060534'}
                                                                        loading={true}
                                                                    />
                                                                }
                                                            </div>
                                                            {
                                                                !this.renders.preview() &&
                                                                <div onClick={() => {

                                                                    if (!campaign.is_loading) {
                                                                        if (this.state.selected_campaigns_internal[campaign.id]) {
                                                                            delete this.state.selected_campaigns_internal[campaign.id]
                                                                        } else {
                                                                            let colors = this.functions.color(campaign);
                                                                            campaign.hex = colors.hex;
                                                                            campaign.rgb = colors.rgb;
                                                                            this.state.selected_campaigns_internal[campaign.id] = campaign;
                                                                        }
                                                                        this.props.onSelectedCampaigns(this.state.selected_campaigns_internal);
                                                                    }

                                                                }}
                                                                    className={(this.state.selected_campaigns_internal[campaign.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                    {
                                                                        this.state.selected_campaigns_internal[campaign.id] &&
                                                                        <FeatherIcon className={'stroke-current'}
                                                                            size={14}
                                                                            icon="check" />
                                                                    }
                                                                    {
                                                                        campaign.is_loading &&
                                                                        <div
                                                                            className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                            <BeatLoader
                                                                                sizeUnit={"px"}
                                                                                size={4}
                                                                                color={'#060534'}
                                                                                loading={true}
                                                                            />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            <div className="flex flex-1 truncate">
                                                                <div className="flex flex-col truncate justify-center">
                                                                    <div data-tip='' data-for={campaign.id + "_" + index} key={campaign.id} className="font-medium truncate relative text-sm">
                                                                        {campaign.name}
                                                                        <ReactTooltip effect='solid' id={campaign.id + "_" + index}>
                                                                            {campaign.name}
                                                                        </ReactTooltip>
                                                                    </div>
                                                                    <div className="text-xxs flex flex-row">
                                                                        {
                                                                            typeof campaign.status == "string" && (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enabled") &&
                                                                            <div className="text-green-500 font-medium text-xxs capitalize">{campaign.status.toLowerCase()}</div>
                                                                        }
                                                                        {
                                                                            typeof campaign.status == "string" && (campaign.status.toLowerCase() === "paused") &&
                                                                            <div className="text-orange-500 font-medium text-xxs capitalize">{campaign.status.toLowerCase()}</div>
                                                                        }
                                                                        {
                                                                            typeof campaign.status == "string" && (campaign.status.toLowerCase() === "ended" || campaign.status.toLowerCase() === "removed") &&
                                                                            <div className="text-red-500 font-medium text-xxs capitalize">{campaign.status.toLowerCase()}</div>
                                                                        }
                                                                        {
                                                                            typeof campaign.status == "string" && (campaign.status.toLowerCase() !== "active" && campaign.status.toLowerCase() !== "enabled" && campaign.status.toLowerCase() !== "ended" && campaign.status.toLowerCase() !== "removed" && campaign.status.toLowerCase() !== "paused") &&
                                                                            <div className="text-gray-500 font-medium text-xxs capitalize">{campaign.status.toLowerCase()}</div>
                                                                        }
                                                                        {
                                                                            this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).filter((key) => {
                                                                                return this.state.selected_adgroups_internal[key].client == campaign.client && this.state.selected_adgroups_internal[key].campaign == campaign.id;
                                                                            }).length > 0 &&
                                                                            <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>adgroups <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                                                        }
                                                                        {
                                                                            this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                                                return this.state.selected_ads_internal[key].client == campaign.client && this.state.selected_ads_internal[key].campaign == campaign.id;
                                                                            }).length > 0 &&
                                                                            <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>Ads <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.state.selected_campaigns_internal[campaign.id] &&
                                                                    <div onClick={() => {
                                                                        if (!this.state.tab.settings) {
                                                                            this.state.tab.settings = { title: "" };
                                                                            this.state.tab.settings.source_name = {};
                                                                        } else if (!this.state.tab.settings.source_name) {
                                                                            this.state.tab.settings.source_name = {};
                                                                        }
                                                                        if (campaign.id in this.state.tab.settings.source_name) {
                                                                            delete this.state.tab.settings.source_name[campaign.id];
                                                                        } else {
                                                                            this.state.tab.settings.source_name[campaign.id] = "";
                                                                        }
                                                                        if (this.props.onTab) {
                                                                            this.props.onTab(this.state.tab);
                                                                        }
                                                                    }} className="flex flex-1 pl-2 pt-2 justify-end">
                                                                        <div>
                                                                            <div className="px-2 py-1 ml-1 hover:bg-gray-200 bg-gray-100 flex items-center rounded-full whitespace-no-wrap font-medium cursor-pointer text-xxs">
                                                                                {this.state.tab.settings && this.state.tab.settings.source_name && (campaign.id in this.state.tab.settings.source_name) && this.state.selected_campaigns_internal[campaign.id] ? "Remove" : "Edit"} name
                                                                                {this.state.tab.settings && this.state.tab.settings.source_name && (campaign.id in this.state.tab.settings.source_name) && this.state.selected_campaigns_internal[campaign.id] ? <XIcon className="w-3 ml-1" /> : <PencilAltIcon className="w-3 ml-1" />}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.tab.settings &&
                                                            this.state.tab.settings.source_name &&
                                                            (campaign.id in this.state.tab.settings.source_name) &&
                                                            this.state.selected_campaigns_internal[campaign.id] &&
                                                            <div className="flex flex-1 w-full -mt-1 pl-12 mb-2">
                                                                <div className="flex flex-1">
                                                                    <div className="w-full pl-12">
                                                                        <InputTailwind
                                                                            label={"Custom name for campaign"}
                                                                            value={this.state.tab.settings.source_name[campaign.id]}
                                                                            onChange={(value) => {
                                                                                this.state.tab.settings.source_name[campaign.id] = value;
                                                                                console.log(this.state.tab.settings.source_name);
                                                                                if (this.props.onTab) {
                                                                                    this.props.onTab(this.state.tab);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            !this.state.masterTemplate &&
                                                            !this.state.template &&
                                                            campaign.open &&
                                                            !campaign.loading &&
                                                            <div className="text-xs font-medium mb-1 pl-24">Adgroups</div>
                                                        }
                                                        {
                                                            !this.state.masterTemplate &&
                                                            !this.state.template &&
                                                            campaign.open &&
                                                            !campaign.loading &&
                                                            this.state.adgroups.length > 10 &&
                                                            <div className="flex flex-1 pl-24 mb-4 flex-col justify-center">
                                                                <InputTailwind
                                                                    label={null}
                                                                    placeholder={"Search adgroups ..."}
                                                                    value={this.state.search_adgroups}
                                                                    onChange={(value) => {
                                                                        this.setState({
                                                                            search_adgroups: value
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            this.state.adgroups.filter((adgroup) => {
                                                                return adgroup.campaign == campaign.id && campaign.open
                                                            }).filter((adgroup) => {
                                                                if (typeof this.state.search_adgroups == "string" && this.state.search_adgroups !== "") {
                                                                    return adgroup.name.toLowerCase().indexOf(this.state.search_adgroups.toLowerCase()) > -1;
                                                                } else {
                                                                    return true;
                                                                }
                                                            }).sort((a, b) => {
                                                                let a_active = typeof a.status == "string" && (a.status.toLowerCase() === "active" || a.status.toLowerCase() === "enabled");
                                                                let b_active = typeof b.status == "string" && (b.status.toLowerCase() === "active" || b.status.toLowerCase() === "enabled");
                                                                return (a_active === b_active) ? 0 : a_active ? -1 : 1;
                                                            }).map((adgroup, index) => {
                                                                return (
                                                                    <Fragment>
                                                                        <div
                                                                            className="flex flex-1 flex-row justify-left pl-24 mb-2">
                                                                            <div onClick={() => {
                                                                                if (!adgroup.open) {
                                                                                    this.props.getAds(adgroup);
                                                                                    adgroup.open = true;
                                                                                } else {
                                                                                    adgroup.open = false;
                                                                                }
                                                                                this.props.onAdgroups(this.state.adgroups);
                                                                            }}
                                                                                className="cursor-pointer min-w-10 min-h-10 h-10 w-10 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500">
                                                                                {
                                                                                    !adgroup.open && !adgroup.loading &&
                                                                                    <FeatherIcon
                                                                                        className={'stroke-current'}
                                                                                        size={16} icon="plus" />
                                                                                }
                                                                                {
                                                                                    adgroup.open && !adgroup.loading &&
                                                                                    <FeatherIcon
                                                                                        className={'stroke-current'}
                                                                                        size={16} icon="minus" />
                                                                                }
                                                                                {
                                                                                    adgroup.loading &&
                                                                                    <BeatLoader
                                                                                        sizeUnit={"px"}
                                                                                        size={5}
                                                                                        color={'#060534'}
                                                                                        loading={true}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            {
                                                                                !this.renders.preview() &&
                                                                                <div onClick={() => {
                                                                                    if (!adgroup.is_loading) {
                                                                                        if (this.state.selected_adgroups_internal[adgroup.id]) {
                                                                                            delete this.state.selected_adgroups_internal[adgroup.id];
                                                                                        } else {

                                                                                            let colors = this.functions.color(adgroup);
                                                                                            adgroup.hex = colors.hex;
                                                                                            adgroup.rgb = colors.rgb;
                                                                                            this.state.selected_adgroups_internal[adgroup.id] = adgroup;

                                                                                            //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                            if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                this.state.tab.breakdown = {
                                                                                                    title: "Overview",
                                                                                                    subtitle: "Aggregated data",
                                                                                                    icon: "globe",
                                                                                                    value: "overview"
                                                                                                };
                                                                                            }

                                                                                        }
                                                                                        this.props.onTab(this.state.tab);
                                                                                        this.props.onSelectedAdgroups(this.state.selected_adgroups_internal);
                                                                                    }
                                                                                }}
                                                                                    className={(this.state.selected_adgroups_internal[adgroup.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 min-w-10 min-h-10 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                    {
                                                                                        this.state.selected_adgroups_internal[adgroup.id] &&
                                                                                        <FeatherIcon
                                                                                            className={'stroke-current'}
                                                                                            size={16}
                                                                                            icon="check" />
                                                                                    }
                                                                                    {
                                                                                        adgroup.is_loading &&
                                                                                        <div
                                                                                            className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                            <BeatLoader
                                                                                                sizeUnit={"px"}
                                                                                                size={5}
                                                                                                color={'#060534'}
                                                                                                loading={true}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div className="flex flex-col truncate justify-center">
                                                                                <div data-tip='' data-for={adgroup.id + "_" + index} key={adgroup.id} className="font-medium truncate relative text-sm">
                                                                                    {adgroup.name}
                                                                                    <ReactTooltip effect='solid' id={adgroup.id + "_" + index}>
                                                                                        {adgroup.name}
                                                                                    </ReactTooltip>
                                                                                </div>
                                                                                <div className="text-xxs flex flex-row">
                                                                                    {
                                                                                        typeof adgroup.status == "string" && (adgroup.status.toLowerCase() === "active" || adgroup.status.toLowerCase() === "enabled") &&
                                                                                        <div className="text-green-500 font-medium text-xxs capitalize">{adgroup.status.toLowerCase()}</div>
                                                                                    }
                                                                                    {
                                                                                        typeof adgroup.status == "string" && (adgroup.status.toLowerCase() === "paused") &&
                                                                                        <div className="text-orange-500 font-medium text-xxs capitalize">{adgroup.status.toLowerCase()}</div>
                                                                                    }
                                                                                    {
                                                                                        typeof adgroup.status == "string" && (adgroup.status.toLowerCase() === "ended" || adgroup.status.toLowerCase() === "removed") &&
                                                                                        <div className="text-red-500 font-medium text-xxs capitalize">{adgroup.status.toLowerCase()}</div>
                                                                                    }
                                                                                    {
                                                                                        typeof adgroup.status == "string" && (adgroup.status.toLowerCase() !== "active" && adgroup.status.toLowerCase() !== "enabled" && adgroup.status.toLowerCase() !== "ended" && adgroup.status.toLowerCase() !== "removed" && adgroup.status.toLowerCase() !== "paused") &&
                                                                                        <div className="text-gray-500 font-medium text-xxs capitalize">{adgroup.status.toLowerCase()}</div>
                                                                                    }
                                                                                    {
                                                                                        this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                                                            return this.state.selected_ads_internal[key].client == adgroup.client && this.state.selected_ads_internal[key].adgroup == adgroup.id;
                                                                                        }).length > 0 &&
                                                                                        <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>Ads <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.selected_adgroups_internal[adgroup.id] &&
                                                                                <div onClick={() => {
                                                                                    if (!this.state.tab.settings) {
                                                                                        this.state.tab.settings = { title: "" };
                                                                                        this.state.tab.settings.source_name = {};
                                                                                    } else if (!this.state.tab.settings.source_name) {
                                                                                        this.state.tab.settings.source_name = {};
                                                                                    }
                                                                                    if (adgroup.id in this.state.tab.settings.source_name) {
                                                                                        delete this.state.tab.settings.source_name[adgroup.id];
                                                                                    } else {
                                                                                        this.state.tab.settings.source_name[adgroup.id] = "";
                                                                                    }
                                                                                    if (this.props.onTab) {
                                                                                        this.props.onTab(this.state.tab);
                                                                                    }
                                                                                }} className="flex flex-1 pl-2 pt-2 justify-end">
                                                                                    <div>
                                                                                        <div className="px-2 py-1 ml-1 hover:bg-gray-200 bg-gray-100 flex items-center rounded-full whitespace-no-wrap font-medium cursor-pointer text-xxs">
                                                                                            {this.state.tab.settings && this.state.tab.settings.source_name && (adgroup.id in this.state.tab.settings.source_name) && this.state.selected_adgroups_internal[adgroup.id] ? "Remove" : "Edit"} name
                                                                                            {this.state.tab.settings && this.state.tab.settings.source_name && (adgroup.id in this.state.tab.settings.source_name) && this.state.selected_adgroups_internal[adgroup.id] ? <XIcon className="w-3 ml-1" /> : <PencilAltIcon className="w-3 ml-1" />}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            this.state.tab.settings &&
                                                                            this.state.tab.settings.source_name &&
                                                                            (adgroup.id in this.state.tab.settings.source_name) &&
                                                                            this.state.selected_adgroups_internal[adgroup.id] &&
                                                                            <div className="flex flex-1 w-full -mt-1 pl-24 mb-2">
                                                                                <div className="flex flex-1 pl-12">
                                                                                    <div className="w-full">
                                                                                        <InputTailwind
                                                                                            label={"Custom name for adgroup"}
                                                                                            value={this.state.tab.settings.source_name[adgroup.id]}
                                                                                            onChange={(value) => {
                                                                                                this.state.tab.settings.source_name[adgroup.id] = value;
                                                                                                //console.log(this.state.tab.settings.source_name);
                                                                                                if (this.props.onTab) {
                                                                                                    this.props.onTab(this.state.tab);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !this.state.masterTemplate &&
                                                                            !this.state.template &&
                                                                            adgroup.open &&
                                                                            !adgroup.loading &&
                                                                            <div className="text-xs font-medium mb-1 ml-12 pl-24">Ads</div>
                                                                        }
                                                                        {
                                                                            !this.state.masterTemplate &&
                                                                            !this.state.template &&
                                                                            adgroup.open &&
                                                                            !adgroup.loading &&
                                                                            this.state.ads.filter((ad) => {
                                                                                return ad.adgroup == adgroup.id && adgroup.open
                                                                            }).length > 10 &&
                                                                            <div className="flex flex-1 pl-24 ml-12 mb-4 flex-col justify-center">
                                                                                <InputTailwind
                                                                                    label={null}
                                                                                    placeholder={"Search ads ..."}
                                                                                    value={this.state.search_ads}
                                                                                    onChange={(value) => {
                                                                                        this.setState({
                                                                                            search_ads: value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.ads.filter((ad) => {
                                                                                return ad.adgroup == adgroup.id && adgroup.open
                                                                            }).filter((ads) => {
                                                                                if (typeof this.state.search_ads == "string" && this.state.search_ads !== "") {
                                                                                    return ads.name.toLowerCase().indexOf(this.state.search_ads.toLowerCase()) > -1;
                                                                                } else {
                                                                                    return true;
                                                                                }
                                                                            }).sort((a, b) => {
                                                                                let a_active = typeof a.status == "string" && (a.status.toLowerCase() === "active" || a.status.toLowerCase() === "enabled");
                                                                                let b_active = typeof b.status == "string" && (b.status.toLowerCase() === "active" || b.status.toLowerCase() === "enabled");
                                                                                return (a_active === b_active) ? 0 : a_active ? -1 : 1;
                                                                            }).map((ad, index) => {
                                                                                return (
                                                                                    <Fragment>
                                                                                        <div
                                                                                            className="flex flex-1 flex-row justify-left ml-12 pl-24 mb-2">
                                                                                            <div className="h-10 w-10 flex rounded mr-2 bg-purple-100">

                                                                                            </div>
                                                                                            {
                                                                                                !this.renders.preview() &&
                                                                                                <div onClick={() => {
                                                                                                    if (!ad.is_loading) {
                                                                                                        if (this.state.selected_ads_internal[ad.id]) {
                                                                                                            delete this.state.selected_ads_internal[ad.id];
                                                                                                        } else {

                                                                                                            let colors = this.functions.color(ad);
                                                                                                            ad.hex = colors.hex;
                                                                                                            ad.rgb = colors.rgb;
                                                                                                            this.state.selected_ads_internal[ad.id] = ad;

                                                                                                            //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                                            if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                                this.state.tab.breakdown = {
                                                                                                                    title: "Overview",
                                                                                                                    subtitle: "Aggregated data",
                                                                                                                    icon: "globe",
                                                                                                                    value: "overview"
                                                                                                                };
                                                                                                            }

                                                                                                        }
                                                                                                        this.props.onTab(this.state.tab);
                                                                                                        this.props.onSelectedAds(this.state.selected_ads_internal);
                                                                                                    }
                                                                                                }}
                                                                                                    className={(this.state.selected_ads_internal[ad.id] ? "border-purple-500 bg-purple-100" : "") + " min-h-10 min-w-10 cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                                    {
                                                                                                        this.state.selected_ads_internal[ad.id] &&
                                                                                                        <FeatherIcon
                                                                                                            className={'stroke-current'}
                                                                                                            size={16}
                                                                                                            icon="check" />
                                                                                                    }
                                                                                                    {
                                                                                                        ad.is_loading &&
                                                                                                        <div
                                                                                                            className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                                            <BeatLoader
                                                                                                                sizeUnit={"px"}
                                                                                                                size={5}
                                                                                                                color={'#060534'}
                                                                                                                loading={true}
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                this.renders.preview() &&
                                                                                                <div onClick={() => {
                                                                                                    if (!ad.is_loading) {
                                                                                                        if (ad.id in this.state.selected_ads_internal) {
                                                                                                            delete this.state.selected_ads_internal[ad.id];
                                                                                                        } else {
                                                                                                            for (let key in this.state.selected_ads_internal) {
                                                                                                                delete this.state.selected_ads_internal[key];
                                                                                                            }
                                                                                                            let colors = this.functions.color(ad);
                                                                                                            ad.hex = colors.hex;
                                                                                                            ad.rgb = colors.rgb;
                                                                                                            this.state.selected_ads_internal[ad.id] = ad;

                                                                                                            //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                                            if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                                this.state.tab.breakdown = {
                                                                                                                    title: "Overview",
                                                                                                                    subtitle: "Aggregated data",
                                                                                                                    icon: "globe",
                                                                                                                    value: "overview"
                                                                                                                };
                                                                                                            }
                                                                                                        }
                                                                                                        this.props.onTab(this.state.tab);
                                                                                                        this.props.onSelectedAds(this.state.selected_ads_internal);
                                                                                                    }
                                                                                                }}
                                                                                                    className={((this.state.selected_ads_internal[ad.id]) ? "border-purple-500" : "") + " min-h-10 min-w-10 h-10 w-10 cursor-pointer p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                                    {
                                                                                                        this.state.selected_ads_internal[ad.id] &&
                                                                                                        <div
                                                                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                            <div class="flex truncate flex-1">
                                                                                                <div className="flex flex-col truncate justify-center">
                                                                                                    <div data-tip='' data-for={ad.id + "_" + index} key={ad.id} className="font-medium truncate relative text-sm">
                                                                                                        {ad.name}
                                                                                                        {
                                                                                                            !ad.name && ad.advertising_type === "RESPONSIVE_DISPLAY_AD" &&
                                                                                                            <>
                                                                                                                {
                                                                                                                    Array.isArray(ad.headlines) && ad.headlines.length > 0 &&
                                                                                                                    <>
                                                                                                                        {ad.headlines[0]}
                                                                                                                    </>
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                        <ReactTooltip effect='solid' id={ad.id + "_" + index}>
                                                                                                            <span>
                                                                                                                {ad.name}
                                                                                                                {
                                                                                                                    !ad.name && ad.advertising_type === "RESPONSIVE_DISPLAY_AD" &&
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            Array.isArray(ad.headlines) && ad.headlines.length > 0 &&
                                                                                                                            <>
                                                                                                                                {ad.headlines[0]}
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                }
                                                                                                            </span>
                                                                                                        </ReactTooltip>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="text-xxs flex flex-row">
                                                                                                        {
                                                                                                            typeof ad.status == "string" && (ad.status.toLowerCase() === "active" || ad.status.toLowerCase() === "enabled") &&
                                                                                                            <div className="text-green-500 font-medium text-xxs capitalize">{ad.status.toLowerCase()}</div>
                                                                                                        }
                                                                                                        {
                                                                                                            typeof ad.status == "string" && (ad.status.toLowerCase() === "paused") &&
                                                                                                            <div className="text-orange-500 font-medium text-xxs capitalize">{ad.status.toLowerCase()}</div>
                                                                                                        }
                                                                                                        {
                                                                                                            typeof ad.status == "string" && (ad.status.toLowerCase() === "ended" || ad.status.toLowerCase() === "removed") &&
                                                                                                            <div className="text-red-500 font-medium text-xxs capitalize">{ad.status.toLowerCase()}</div>
                                                                                                        }
                                                                                                        {
                                                                                                            typeof ad.status == "string" && (ad.status.toLowerCase() !== "active" && ad.status.toLowerCase() !== "enabled" && ad.status.toLowerCase() !== "ended" && ad.status.toLowerCase() !== "removed" && ad.status.toLowerCase() !== "paused") &&
                                                                                                            <div className="text-gray-500 font-medium text-xxs capitalize">{ad.status.toLowerCase()}</div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    this.state.selected_ads_internal[ad.id] &&
                                                                                                    <div onClick={() => {
                                                                                                        if (!this.state.tab.settings) {
                                                                                                            this.state.tab.settings = { title: "" };
                                                                                                            this.state.tab.settings.source_name = {};
                                                                                                        } else if (!this.state.tab.settings.source_name) {
                                                                                                            this.state.tab.settings.source_name = {};
                                                                                                        }
                                                                                                        if (ad.id in this.state.tab.settings.source_name) {
                                                                                                            delete this.state.tab.settings.source_name[ad.id];
                                                                                                        } else {
                                                                                                            this.state.tab.settings.source_name[ad.id] = "";
                                                                                                        }
                                                                                                        if (this.props.onTab) {
                                                                                                            this.props.onTab(this.state.tab);
                                                                                                        }
                                                                                                    }} className="flex flex-1 pl-2 pt-2 justify-end">
                                                                                                        <div>
                                                                                                            <div className="px-2 py-1 ml-1 hover:bg-gray-200 bg-gray-100 flex items-center rounded-full whitespace-no-wrap font-medium cursor-pointer text-xxs">
                                                                                                                {this.state.tab.settings && this.state.tab.settings.source_name && (ad.id in this.state.tab.settings.source_name) && this.state.selected_ads_internal[ad.id] ? "Remove" : "Edit"} name
                                                                                                                {this.state.tab.settings && this.state.tab.settings.source_name && (ad.id in this.state.tab.settings.source_name) && this.state.selected_ads_internal[ad.id] ? <XIcon className="w-3 ml-1" /> : <PencilAltIcon className="w-3 ml-1" />}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            this.state.tab.settings &&
                                                                                            this.state.tab.settings.source_name &&
                                                                                            (ad.id in this.state.tab.settings.source_name) &&
                                                                                            this.state.selected_ads_internal[ad.id] &&
                                                                                            <div className="flex flex-1 w-full -mt-1 pl-24 mb-2">
                                                                                                <div className="flex flex-1 pl-12">
                                                                                                    <div className="w-full">
                                                                                                        <InputTailwind
                                                                                                            label={"Custom name for ad"}
                                                                                                            value={this.state.tab.settings.source_name[ad.id]}
                                                                                                            onChange={(value) => {
                                                                                                                this.state.tab.settings.source_name[ad.id] = value;
                                                                                                                //console.log(this.state.tab.settings.source_name);
                                                                                                                if (this.props.onTab) {
                                                                                                                    this.props.onTab(this.state.tab);
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </Fragment>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            (this.state.campaigns.length - 1) !== index &&
                                                            <div className="border-t flex-1 flex mb-2" />
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                        }
                                        {
                                            !(typeof this.state.search_campaigns == "string" && this.state.search_campaigns !== "") &&
                                            account.show_campaigns &&
                                            <div className="mb-4 mt-2 pl-12">
                                                {
                                                    this.state.campaigns.length > 0 &&
                                                    account.channel !== "google_analytics_4" && account.channel !== "google_analytics" &&
                                                    //this.state.search_campaigns == "" &&
                                                    <span onClick={() => {
                                                        account.show_paused = !account.show_paused;
                                                        this.setState({
                                                            accounts: this.state.accounts
                                                        });
                                                    }} className="text-sm underline font-medium cursor-pointer">
                                                        {!account.show_paused && <span>Show all campaigns</span>}{account.show_paused && <span>Show less campaigns</span>}
                                                    </span>
                                                }
                                            </div>
                                        }
                                    </Fragment>
                                )
                            })
                        }
                        {
                            this.functions.anythingSelected() &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} class="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabindex="0">Next</button>
                            </div>
                        }
                        {
                            this.functions.multipleLevelsSelected() &&
                            <div className="flex flex-1 bg-yellow-100 rounded-md p-4 mt-4">
                                <div className="text-xs text-yellow-500 font-bold">
                                    OBS! You have selected multiple levels of datasources <span onClick={() => {

                                        //REMOVE CHANNELS
                                        this.state.selected_channels_internal = {};
                                        this.props.onSelectedChannels(this.state.selected_channels_internal);

                                        //REMOVE ADS
                                        this.state.selected_ads_internal = {};
                                        this.props.onSelectedAds(this.state.selected_ads_internal);

                                        //REMOVE ADGROUPS
                                        this.state.selected_adgroups_internal = {};
                                        this.props.onSelectedAdgroups(this.state.selected_adgroups_internal);

                                        //REMOVE CAMPAIGNS
                                        this.state.selected_campaigns_internal = {};
                                        this.props.onSelectedCampaigns(this.state.selected_campaigns_internal);

                                        //REMOVE ACCOUNT
                                        this.state.selected_accounts_internal = {};
                                        this.props.onSelectedAccounts(this.state.selected_accounts_internal);

                                    }} className="underline cursor-pointer">Clear all</span>
                                </div>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }

}

class SelectCellBreakdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            accounts: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            channel_breakdowns: [],
            custom_breakdowns: [],
            ga_dimensions: GoogleAnalyticsDimensions,
            ga4_dimensions: GoogleAnalytics4Dimensions,
            adform_dimensions: AdformDimensions,
            open_channel: {},
            master_template: false,
            search_breakdowns: ""
        };
    };

    async componentDidMount() {
        await this.promisedSetState({
            tab: this.props.tab ? this.props.tab : {},
            open: this.props.open,
            master_template: this.props.masterTemplate,
            accounts: this.props.accounts ? this.props.accounts : [],
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            selected_channels_internal: this.props.selectedChannelsInternal ? this.props.selectedChannelsInternal : {},
            selected_accounts_internal: this.props.selectedAccountsInternal ? this.props.selectedAccountsInternal : {},
            selected_campaigns_internal: this.props.selectedCampaignsInternal ? this.props.selectedCampaignsInternal : {},
            selected_adgroups_internal: this.props.selectedAdgroupsInternal ? this.props.selectedAdgroupsInternal : {},
            selected_ads_internal: this.props.selectedAdsInternal ? this.props.selectedAdsInternal : {},
        });
        let channels = [];
        channels.push({ channel: "google", breakdowns: this.functions.breakdownsGoogle() });
        channels.push({ channel: "adform", breakdowns: this.state.adform_dimensions });
        channels.push({ channel: "facebook", breakdowns: this.functions.breakdownsFacebook() });
        channels.push({ channel: "bidtheatre", breakdowns: this.functions.breakdownsBidtheatre() });
        channels.push({ channel: "linkedin", breakdowns: this.functions.breakdownsLinkedin() });
        channels.push({ channel: "tiktok", breakdowns: this.functions.breakdownsTiktok() });
        channels.push({ channel: "twitter", breakdowns: this.functions.breakdownsTwitter() });
        channels.push({ channel: "google_analytics", breakdowns: this.state.ga_dimensions });
        channels.push({ channel: "google_analytics_4", breakdowns: this.state.ga4_dimensions });
        channels.push({ channel: "snapchat", breakdowns: this.functions.breakdownsSnapchat() });
        channels.push({ channel: "bing", breakdowns: this.functions.breakdownsBing() });
        channels.push({ channel: "dv360", breakdowns: this.functions.breakdownsDv360() });
        channels.push({ channel: "google_search_consoole", breakdowns: this.functions.breakdownsGoogleSC() });
        if (!(Array.isArray(this.state.custom_breakdowns) && this.state.custom_breakdowns.length > 0)) {
            let merged_breakdowns = await this.functions.getCustomDatasourceBreakdowns(channels);
            await this.promisedSetState({
                channel_breakdowns: merged_breakdowns
            });
        } else {
            channels = channels.concat(this.state.custom_breakdowns);
            await this.promisedSetState({
                channel_breakdowns: channels
            });
        }
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        await this.promisedSetState({
            tab: nextProps.tab ? nextProps.tab : {},
            open: nextProps.open,
            master_template: nextProps.masterTemplate,
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            accounts: nextProps.accounts ? nextProps.accounts : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            ads: nextProps.ads ? nextProps.ads : [],
            selected_channels_internal: nextProps.selectedChannelsInternal ? nextProps.selectedChannelsInternal : {},
            selected_accounts_internal: nextProps.selectedAccountsInternal ? nextProps.selectedAccountsInternal : {},
            selected_campaigns_internal: nextProps.selectedCampaignsInternal ? nextProps.selectedCampaignsInternal : {},
            selected_adgroups_internal: nextProps.selectedAdgroupsInternal ? nextProps.selectedAdgroupsInternal : {},
            selected_ads_internal: nextProps.selectedAdsInternal ? nextProps.selectedAdsInternal : {},
        });
        let channels = [];
        channels.push({ channel: "google", breakdowns: this.functions.breakdownsGoogle() });
        channels.push({ channel: "adform", breakdowns: this.state.adform_dimensions });
        channels.push({ channel: "facebook", breakdowns: this.functions.breakdownsFacebook() });
        channels.push({ channel: "bidtheatre", breakdowns: this.functions.breakdownsBidtheatre() });
        channels.push({ channel: "linkedin", breakdowns: this.functions.breakdownsLinkedin() });
        channels.push({ channel: "tiktok", breakdowns: this.functions.breakdownsTiktok() });
        channels.push({ channel: "twitter", breakdowns: this.functions.breakdownsTwitter() });
        channels.push({ channel: "google_analytics", breakdowns: this.state.ga_dimensions });
        channels.push({ channel: "google_analytics_4", breakdowns: this.state.ga4_dimensions });
        channels.push({ channel: "snapchat", breakdowns: this.functions.breakdownsSnapchat() });
        channels.push({ channel: "bing", breakdowns: this.functions.breakdownsBing() });
        channels.push({ channel: "dv360", breakdowns: this.functions.breakdownsDv360() });
        channels.push({ channel: "google_search_console", breakdowns: this.functions.breakdownsGoogleSC() });
        if (!(Array.isArray(this.state.custom_breakdowns) && this.state.custom_breakdowns.length > 0)) {
            let merged_breakdowns = await this.functions.getCustomDatasourceBreakdowns(channels);
            await this.promisedSetState({
                channel_breakdowns: merged_breakdowns
            });
        } else {
            channels = channels.concat(this.state.custom_breakdowns);
            await this.promisedSetState({
                channel_breakdowns: channels
            });
        }
    }

    functions = {
        valid: () => {
            return true
        },
        onlyAccountSelected: () => {
            let other_total = 0;
            let account_total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                account_total += 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                account_total += 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                other_total += 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                other_total += 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                other_total += 1;
            }
            return other_total < 1 && account_total > 0
        },
        onlyAccountAndCampaignSelected: () => {
            let other_total = 0;
            let account_total = 0;
            let campaign_total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                account_total += 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                account_total += 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                campaign_total += 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                other_total += 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                other_total += 1;
            }
            return other_total < 1 && (account_total > 0 || campaign_total > 0)
        },
        anythingSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 0
        },
        amountSelected: () => {
            let total = 0;
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + Object.keys(this.state.selected_campaigns_internal).length;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + Object.keys(this.state.selected_adgroups_internal).length;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + Object.keys(this.state.selected_ads_internal).length;
            }
            return total;
        },
        selectedDataSourcesChannells: () => {
            let channels = {};
            if (this.state.selected_channels_internal) {
                for (let key in this.state.selected_channels_internal) {
                    channels[this.state.selected_channels_internal[key].channel] = true;
                }
            }
            if (this.state.selected_accounts_internal) {
                for (let key in this.state.selected_accounts_internal) {
                    channels[this.state.selected_accounts_internal[key].channel] = true;
                }
            }
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    channels[this.state.selected_campaigns_internal[key].channel] = true;
                }
            }
            if (this.state.selected_adgroups_internal) {
                for (let key in this.state.selected_adgroups_internal) {
                    channels[this.state.selected_adgroups_internal[key].channel] = true;
                }
            }
            if (this.state.selected_ads_internal) {
                for (let key in this.state.selected_ads_internal) {
                    channels[this.state.selected_ads_internal[key].channel] = true;
                }
            }
            return channels;
        },
        breakdownsBing: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" },
                        { name: "Device", value: "device", parent: "basic" },
                        { name: "Age & Gender", value: "age_gender", parent: "basic" },
                        { name: "Country & City ", value: "country_city", parent: "basic" },
                        { name: "Date", value: "date", parent: "basic" }
                    ]
                },
                {
                    title: "Resources",
                    values: [
                        { name: "Keywords", value: "keyword", parent: "Resources" },
                    ]
                }
            ];
            if (this.functions.onlyAccountSelected()) {
                breakdowns[1].values.push({ name: "Campaigns", value: "campaign", parent: "Resources" });
                breakdowns[1].values.push({ name: "Adgroup", value: "adgroup", parent: "Resources" });
                //breakdowns[1].values.push({ name: "Ad", value: "ad", parent: "Resources" });
            }
            return breakdowns;
        },
        breakdownsDv360: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" },
                        //{ name: "Browser", value: "FILTER_BROWSER", parent: "basic" },
                        { name: "Active View: Custom Metric ID", value: "FILTER_ACTIVE_VIEW_CUSTOM_METRIC_ID", parent: "basic" },
                        { name: "Active View: Custom Metric Name", value: "FILTER_ACTIVE_VIEW_CUSTOM_METRIC_NAME", parent: "basic" },
                        { name: "Active View Expected Viewability", value: "FILTER_ACTIVE_VIEW_EXPECTED_VIEWABILITY", parent: "basic" },
                        { name: "Advertiser ID", value: "FILTER_ADVERTISER", parent: "basic" },
                        { name: "Advertiser Currency", value: "FILTER_ADVERTISER_CURRENCY", parent: "basic" },
                        { name: "Advertiser Integration Code", value: "FILTER_ADVERTISER_INTEGRATION_CODE", parent: "basic" },
                        { name: "Advertiser Status", value: "FILTER_ADVERTISER_INTEGRATION_STATUS", parent: "basic" },
                        { name: "Advertiser", value: "FILTER_ADVERTISER_NAME", parent: "basic" },
                        { name: "Advertiser Time Zone", value: "FILTER_ADVERTISER_TIMEZONE", parent: "basic" },
                        { name: "Ad Position", value: "FILTER_AD_POSITION", parent: "basic" },
                        { name: "Ad Type", value: "FILTER_AD_TYPE", parent: "basic" },
                        { name: "Age", value: "FILTER_AGE", parent: "basic" },
                        { name: "Algorithm", value: "FILTER_ALGORITHM", parent: "basic" },
                        { name: "Algorithm ID", value: "FILTER_ALGORITHM_ID", parent: "basic" },
                        { name: "AMP Page Request", value: "FILTER_AMP_PAGE_REQUEST", parent: "basic" },
                        { name: "Anonymous Inventory Modeling", value: "FILTER_ANONYMOUS_INVENTORY_MODELING", parent: "basic" },
                        { name: "App/URL", value: "FILTER_APP_URL", parent: "basic" },
                        { name: "App/URL Excluded", value: "FILTER_APP_URL_EXCLUDED", parent: "basic" },
                        { name: "Asset Combination", value: "FILTER_ASSET_COMBINATION_STRING", parent: "basic" },
                        { name: "Attributed Userlist", value: "FILTER_ATTRIBUTED_USERLIST", parent: "basic" },
                        { name: "Attributed Userlist Cost", value: "FILTER_ATTRIBUTED_USERLIST_COST", parent: "basic" },
                        { name: "Attributed Userlist Type", value: "FILTER_ATTRIBUTED_USERLIST_TYPE", parent: "basic" },
                        { name: "Attribution Model", value: "FILTER_ATTRIBUTION_MODEL", parent: "basic" },
                        { name: "Audience List", value: "FILTER_AUDIENCE_LIST", parent: "basic" },
                        { name: "Audience List Cost", value: "FILTER_AUDIENCE_LIST_COST", parent: "basic" },
                        { name: "Audience List Type", value: "FILTER_AUDIENCE_LIST_TYPE", parent: "basic" },
                        { name: "Audience Name", value: "FILTER_AUDIENCE_NAME", parent: "basic" },
                        { name: "Audience Type", value: "FILTER_AUDIENCE_TYPE", parent: "basic" },
                        { name: "Audio Feed Type", value: "FILTER_AUDIO_FEED_TYPE_NAME", parent: "basic" },
                        { name: "Authorized Seller State", value: "FILTER_AUTHORIZED_SELLER_STATE", parent: "basic" },
                        { name: "Bid Strategy Type", value: "FILTER_BID_STRATEGY_TYPE_NAME", parent: "basic" },
                        { name: "Billable Outcome", value: "FILTER_BILLABLE_OUTCOME", parent: "basic" },
                        { name: "Brand Lift Type", value: "FILTER_BRAND_LIFT_TYPE", parent: "basic" },
                        //{ name: "Browser", value: "FILTER_BROWSER", parent: "basic" },
                        { name: "Budget Segment Budget", value: "FILTER_BUDGET_SEGMENT_BUDGET", parent: "basic" },
                        { name: "Budget Segment Name", value: "FILTER_BUDGET_SEGMENT_DESCRIPTION", parent: "basic" },
                        { name: "Budget Segment End Date", value: "FILTER_BUDGET_SEGMENT_END_DATE", parent: "basic" },
                        { name: "Budget Segment Pacing Percentage", value: "FILTER_BUDGET_SEGMENT_PACING_PERCENTAGE", parent: "basic" },
                        { name: "Budget Segment Start Date", value: "FILTER_BUDGET_SEGMENT_START_DATE", parent: "basic" },
                        { name: "Budget Type (Segment)", value: "FILTER_BUDGET_SEGMENT_TYPE", parent: "basic" },
                        { name: "Insertion Order Daily Frequency", value: "FILTER_CAMPAIGN_DAILY_FREQUENCY", parent: "basic" },
                        { name: "ISP or Carrier ID", value: "FILTER_CARRIER", parent: "basic" },
                        { name: "ISP or Carrier", value: "FILTER_CARRIER_NAME", parent: "basic" },
                        { name: "Channel ID", value: "FILTER_CHANNEL_ID", parent: "basic" },
                        { name: "Channel", value: "FILTER_CHANNEL_NAME", parent: "basic" },
                        { name: "Channel Type", value: "FILTER_CHANNEL_TYPE", parent: "basic" },
                        { name: "City ID", value: "FILTER_CITY", parent: "basic" },
                        { name: "City", value: "FILTER_CITY_NAME", parent: "basic" },
                        { name: "CM360 Placement ID", value: "FILTER_CM360_PLACEMENT_ID", parent: "basic" },
                        { name: "Companion Creative ID", value: "FILTER_COMPANION_CREATIVE_ID", parent: "basic" },
                        { name: "Companion Creative", value: "FILTER_COMPANION_CREATIVE_NAME", parent: "basic" },
                        { name: "Publisher ID", value: "FILTER_CONTAINER_PERMISSIONED_LIST", parent: "basic" },
                        { name: "Conversion Ad Event Type", value: "FILTER_CONVERSION_AD_EVENT_TYPE", parent: "basic" },
                        { name: "Conversion Ad Event Type ID", value: "FILTER_CONVERSION_AD_EVENT_TYPE_ID", parent: "basic" },
                        { name: "Time to Conversion", value: "FILTER_CONVERSION_DELAY", parent: "basic" },
                        { name: "Conversion Source", value: "FILTER_CONVERSION_SOURCE", parent: "basic" },
                        { name: "Conversion Source ID", value: "FILTER_CONVERSION_SOURCE_ID", parent: "basic" },
                        { name: "Country", value: "FILTER_COUNTRY", parent: "basic" },
                        { name: "Country ID", value: "FILTER_COUNTRY_ID", parent: "basic" },
                        { name: "Creative", value: "FILTER_CREATIVE", parent: "basic" },
                        { name: "Creative Asset", value: "FILTER_CREATIVE_ASSET", parent: "basic" },
                        { name: "Creative Attributes", value: "FILTER_CREATIVE_ATTRIBUTE", parent: "basic" },
                        { name: "Creative Height", value: "FILTER_CREATIVE_HEIGHT", parent: "basic" },
                        { name: "Creative ID", value: "FILTER_CREATIVE_ID", parent: "basic" },
                        { name: "Creative Integration Code", value: "FILTER_CREATIVE_INTEGRATION_CODE", parent: "basic" },
                        { name: "Creative Rendered in AMP", value: "FILTER_CREATIVE_RENDERED_IN_AMP", parent: "basic" },
                        { name: "Creative Size", value: "FILTER_CREATIVE_SIZE", parent: "basic" },
                        { name: "Creative Source", value: "FILTER_CREATIVE_SOURCE", parent: "basic" },
                        { name: "Creative Status", value: "FILTER_CREATIVE_STATUS", parent: "basic" },
                        { name: "Creative Type", value: "FILTER_CREATIVE_TYPE", parent: "basic" },
                        { name: "Creative Width", value: "FILTER_CREATIVE_WIDTH", parent: "basic" },
                        { name: "CTV Audience Expansion", value: "FILTER_CTV_AUDIENCE_EXPANSION", parent: "basic" },
                        { name: "Data Provider ID", value: "FILTER_DATA_PROVIDER", parent: "basic" },
                        { name: "Data Provider", value: "FILTER_DATA_PROVIDER_NAME", parent: "basic" },
                        { name: "Data Source", value: "FILTER_DATA_SOURCE", parent: "basic" },
                        { name: "Date", value: "FILTER_DATE", parent: "basic" },
                        { name: "Day of Week", value: "FILTER_DAY_OF_WEEK", parent: "basic" },
                        { name: "Detailed Demographics", value: "FILTER_DETAILED_DEMOGRAPHICS", parent: "basic" },
                        { name: "Detailed Demographics ID", value: "FILTER_DETAILED_DEMOGRAPHICS_ID", parent: "basic" },
                        { name: "Device", value: "FILTER_DEVICE", parent: "basic" },
                        { name: "Device Make", value: "FILTER_DEVICE_MAKE", parent: "basic" },
                        { name: "Device Model", value: "FILTER_DEVICE_MODEL", parent: "basic" },
                        { name: "Device Type", value: "FILTER_DEVICE_TYPE", parent: "basic" },
                        { name: "DFP Insertion Order ID", value: "FILTER_DFP_ORDER_ID", parent: "basic" },
                        { name: "Digital Content Label", value: "FILTER_DIGITAL_CONTENT_LABEL", parent: "basic" },
                        { name: "DMA Code", value: "FILTER_DMA", parent: "basic" },
                        { name: "DMA", value: "FILTER_DMA_NAME", parent: "basic" },
                        { name: "Domain", value: "FILTER_DOMAIN", parent: "basic" },
                        { name: "Eligible Cookies on First-Party Audience List", value: "FILTER_ELIGIBLE_COOKIES_ON_FIRST_PARTY_AUDIENCE_LIST", parent: "basic" },
                        { name: "Eligible Cookies on Third-Party Audience List and Interest", value: "FILTER_ELIGIBLE_COOKIES_ON_THIRD_PARTY_AUDIENCE_LIST_AND_INTEREST", parent: "basic" },
                        { name: "Attributed Event Platform Type", value: "FILTER_EVENT_PLATFORM_TYPE", parent: "basic" },
                        { name: "Event Type", value: "FILTER_EVENT_TYPE", parent: "basic" },
                        { name: "Exchange", value: "FILTER_EXCHANGE", parent: "basic" },
                        { name: "Exchange Code", value: "FILTER_EXCHANGE_CODE", parent: "basic" },
                        { name: "Exchange ID", value: "FILTER_EXCHANGE_ID", parent: "basic" },
                        { name: "Asset", value: "FILTER_EXTENSION", parent: "basic" },
                        { name: "Asset (upgraded)", value: "FILTER_EXTENSION_ASSET", parent: "basic" },
                        { name: "Asset Status (upgraded)", value: "FILTER_EXTENSION_ASSET_STATUS", parent: "basic" },
                        { name: "Asset Type (upgraded)", value: "FILTER_EXTENSION_ASSET_TYPE", parent: "basic" },
                        { name: "Asset Status", value: "FILTER_EXTENSION_STATUS", parent: "basic" },
                        { name: "Asset Type", value: "FILTER_EXTENSION_TYPE", parent: "basic" },
                        { name: "First Party Audience List Cost", value: "FILTER_FIRST_PARTY_AUDIENCE_LIST_COST", parent: "basic" },
                        { name: "First Party Audience List Type", value: "FILTER_FIRST_PARTY_AUDIENCE_LIST_TYPE", parent: "basic" },
                        { name: "Floodlight Activity", value: "FILTER_FLOODLIGHT_ACTIVITY", parent: "basic" },
                        { name: "Floodlight Activity ID", value: "FILTER_FLOODLIGHT_ACTIVITY_ID", parent: "basic" },
                        { name: "Format", value: "FILTER_FORMAT", parent: "basic" },
                        { name: "DFP Insertion Order", value: "FILTER_GAM_INSERTION_ORDER", parent: "basic" },
                        { name: "DFP Line Item", value: "FILTER_GAM_LINE_ITEM", parent: "basic" },
                        { name: "DFP Line Item ID", value: "FILTER_GAM_LINE_ITEM_ID", parent: "basic" },
                        { name: "Gender", value: "FILTER_GENDER", parent: "basic" },
                        { name: "Gender (gmail)", value: "FILTER_GMAIL_GENDER", parent: "basic" },
                        { name: "Sponsored Product Id", value: "FILTER_GUILDER_PRODUCT_ID", parent: "basic" },
                        { name: "Household Income", value: "FILTER_HOUSEHOLD_INCOME", parent: "basic" },
                        { name: "Impression Counting Method", value: "FILTER_IMPRESSION_COUNTING_METHOD", parent: "basic" },
                        { name: "Rejection Reasond", value: "FILTER_IMPRESSION_LOSS_REJECTION_REASON", parent: "basic" },
                        { name: "Insertion Order ID", value: "FILTER_INSERTION_ORDER", parent: "basic" },
                        { name: "Insertion Order Goal Type", value: "FILTER_INSERTION_ORDER_GOAL_TYPE", parent: "basic" },
                        { name: "Insertion Order Goal Value", value: "FILTER_INSERTION_ORDER_GOAL_VALUE", parent: "basic" },
                        { name: "Insertion Order Integration Code", value: "FILTER_INSERTION_ORDER_INTEGRATION_CODE", parent: "basic" },
                        { name: "Insertion Order", value: "FILTER_INSERTION_ORDER_NAME", parent: "basic" },
                        { name: "Insertion Order Status", value: "FILTER_INSERTION_ORDER_STATUS", parent: "basic" },
                        { name: "Inventory Commitment Type", value: "FILTER_INVENTORY_COMMITMENT_TYPE", parent: "basic" },
                        { name: "Inventory Delivery Method", value: "FILTER_INVENTORY_DELIVERY_METHOD", parent: "basic" },
                        { name: "Format", value: "FILTER_INVENTORY_FORMAT", parent: "basic" },
                        { name: "Inventory Media Cost Type", value: "FILTER_INVENTORY_MEDIA_COST_TYPE", parent: "basic" },
                        { name: "Inventory Rate Type", value: "FILTER_INVENTORY_RATE_TYPE", parent: "basic" },
                        { name: "Inventory Source ID (Legacy)", value: "FILTER_INVENTORY_SOURCE", parent: "basic" },
                        { name: "Inventory Sources Permissions", value: "FILTER_INVENTORY_SOURCES_PERMISSIONS", parent: "basic" },
                        { name: "Inventory Source ID (external)", value: "FILTER_INVENTORY_SOURCE_EXTERNAL_ID", parent: "basic" },
                        { name: "Inventory Source Group", value: "FILTER_INVENTORY_SOURCE_GROUP", parent: "basic" },
                        { name: "Inventory Source Group ID", value: "FILTER_INVENTORY_SOURCE_GROUP_ID", parent: "basic" },
                        { name: "Inventory Source ID", value: "FILTER_INVENTORY_SOURCE_ID", parent: "basic" },
                        { name: "Inventory Source", value: "FILTER_INVENTORY_SOURCE_NAME", parent: "basic" },
                        { name: "Inventory Source Type", value: "FILTER_INVENTORY_SOURCE_TYPE", parent: "basic" },
                        { name: "Keyword", value: "FILTER_KEYWORD", parent: "basic" },
                        { name: "Life Event", value: "FILTER_LIFE_EVENT", parent: "basic" },
                        { name: "Life Events", value: "FILTER_LIFE_EVENTS", parent: "basic" },
                        { name: "Line Item ID", value: "FILTER_LINE_ITEM", parent: "basic" },
                        { name: "Line Item Budget", value: "FILTER_LINE_ITEM_BUDGET", parent: "basic" },
                        { name: "Line Item Daily Frequency", value: "FILTER_LINE_ITEM_DAILY_FREQUENCY", parent: "basic" },
                        { name: "Line Item End Date", value: "FILTER_LINE_ITEM_END_DATE", parent: "basic" },
                        { name: "Line Item Integration Code", value: "FILTER_LINE_ITEM_INTEGRATION_CODE", parent: "basic" },
                        { name: "Line Item Lifetime Frequency", value: "FILTER_LINE_ITEM_LIFETIME_FREQUENCY", parent: "basic" },
                        { name: "Line Item", value: "FILTER_LINE_ITEM_NAME", parent: "basic" },
                        { name: "Line Item Pacing Percentage", value: "FILTER_LINE_ITEM_PACING_PERCENTAGE", parent: "basic" },
                        { name: "Line Item Start Date", value: "FILTER_LINE_ITEM_START_DATE", parent: "basic" },
                        { name: "Line Item Status", value: "FILTER_LINE_ITEM_STATUS", parent: "basic" },
                        { name: "Line Item Type", value: "FILTER_LINE_ITEM_TYPE", parent: "basic" },
                        { name: "Asset (upgraded Location)", value: "FILTER_LOCATION_ASSET", parent: "basic" },
                        { name: "Asset Status (upgraded Location)", value: "FILTER_LOCATION_ASSET_STATUS", parent: "basic" },
                        { name: "Asset Type (upgraded Location)", value: "FILTER_LOCATION_ASSET_TYPE", parent: "basic" },
                        { name: "Matched Genre Target", value: "FILTER_MATCHED_GENRE_TARGET", parent: "basic" },
                        { name: "Match Ratio", value: "FILTER_MATCH_RATIO", parent: "basic" },
                        { name: "Measurement Source", value: "FILTER_MEASUREMENT_SOURCE", parent: "basic" },
                        { name: "Campaign ID", value: "FILTER_MEDIA_PLAN", parent: "basic" },
                        { name: "Campaign", value: "FILTER_MEDIA_PLAN_NAME", parent: "basic" },
                        { name: "Media Type", value: "FILTER_MEDIA_TYPE", parent: "basic" },
                        { name: "Business Chain", value: "FILTER_MOBILE_GEO", parent: "basic" },
                        { name: "Month", value: "FILTER_MONTH", parent: "basic" },
                        { name: "MRAID Support", value: "FILTER_MRAID_SUPPORT", parent: "basic" },
                        { name: "Age (Nielsen)", value: "FILTER_NIELSEN_AGE", parent: "basic" },
                        { name: "Country (Nielsen)", value: "FILTER_NIELSEN_COUNTRY_CODE", parent: "basic" },
                        { name: "Date Range for Cumulative Metrics (Nielsen)", value: "FILTER_NIELSEN_DATE_RANGE", parent: "basic" },
                        { name: "Device ID (Nielsen)", value: "FILTER_NIELSEN_DEVICE_ID", parent: "basic" },
                        { name: "Gender (Nielsen)", value: "FILTER_NIELSEN_GENDER", parent: "basic" },
                        { name: "Restatement Date (Nielsen)", value: "FILTER_NIELSEN_RESTATEMENT_DATE", parent: "basic" },
                        { name: "OM SDK Capable", value: "FILTER_OMID_CAPABLE", parent: "basic" },
                        { name: "OM SDK Available", value: "FILTER_OM_SDK_AVAILABLE", parent: "basic" },
                        { name: "Order ID", value: "FILTER_ORDER_ID", parent: "basic" },
                        { name: "Operating System", value: "FILTER_OS", parent: "basic" },
                        { name: "Other Advertiser ID", value: "FILTER_OTHER_ADVERTISER_ID", parent: "basic" },
                        { name: "Other Advertiser", value: "FILTER_OTHER_ADVERTISER_NAME", parent: "basic" },
                        { name: "Other Insertion Order ID", value: "FILTER_OTHER_CAMPAIGN_ID", parent: "basic" },
                        { name: "Other Insertion Order", value: "FILTER_OTHER_CAMPAIGN_NAME", parent: "basic" },
                        { name: "Other Device Type", value: "FILTER_OTHER_DEVICE_TYPE", parent: "basic" },
                        { name: "Category", value: "FILTER_PAGE_CATEGORY", parent: "basic" },
                        { name: "Environment", value: "FILTER_PAGE_LAYOUT", parent: "basic" },
                        { name: "Partner ID", value: "FILTER_PARTNER", parent: "basic" },
                        { name: "Partner Currency", value: "FILTER_PARTNER_CURRENCY", parent: "basic" },
                        { name: "Partner", value: "FILTER_PARTNER_NAME", parent: "basic" },
                        { name: "Partner Status", value: "FILTER_PARTNER_STATUS", parent: "basic" },
                        { name: "Placement (All YouTube Channels)", value: "FILTER_PLACEMENT_ALL_YOUTUBE_CHANNELS", parent: "basic" },
                        { name: "Placement Name (All YouTube Channels)", value: "FILTER_PLACEMENT_NAME_ALL_YOUTUBE_CHANNELS", parent: "basic" },
                        { name: "Platform", value: "FILTER_PLATFORM", parent: "basic" },
                        { name: "Playback Method", value: "FILTER_PLAYBACK_METHOD", parent: "basic" },
                        { name: "Position in Content", value: "FILTER_POSITION_IN_CONTENT", parent: "basic" },
                        { name: "Public Inventory", value: "FILTER_PUBLIC_INVENTORY", parent: "basic" },
                        { name: "Publisher Property", value: "FILTER_PUBLISHER_PROPERTY", parent: "basic" },
                        { name: "Publisher Property ID", value: "FILTER_PUBLISHER_PROPERTY_ID", parent: "basic" },
                        { name: "Publisher Property Section", value: "FILTER_PUBLISHER_PROPERTY_SECTION", parent: "basic" },
                        { name: "Publisher Property Section ID", value: "FILTER_PUBLISHER_PROPERTY_SECTION_ID", parent: "basic" },
                        { name: "Publisher Traffic Source", value: "FILTER_PUBLISHER_TRAFFIC_SOURCE", parent: "basic" },
                        { name: "Quarter", value: "FILTER_QUARTER", parent: "basic" },
                        { name: "Refreshed Ad", value: "FILTER_REFRESHED_AD_NAME", parent: "basic" },
                        { name: "Refund Reason", value: "FILTER_REFUND_REASON", parent: "basic" },
                        { name: "Region ID", value: "FILTER_REGION", parent: "basic" },
                        { name: "Region", value: "FILTER_REGION_NAME", parent: "basic" },
                        { name: "Remarketing List", value: "FILTER_REMARKETING_LIST", parent: "basic" },
                        { name: "Rewarded", value: "FILTER_REWARDED", parent: "basic" },
                        { name: "Sensitive Category", value: "FILTER_SENSITIVE_CATEGORY", parent: "basic" },
                        { name: "Served Pixel Density", value: "FILTER_SERVED_PIXEL_DENSITY", parent: "basic" },
                        { name: "App/URL ID", value: "FILTER_SITE_ID", parent: "basic" },
                        { name: "Language", value: "FILTER_SITE_LANGUAGE", parent: "basic" },
                        { name: "Video Skippable Support", value: "FILTER_SKIPPABLE_SUPPORT", parent: "basic" },
                        { name: "Subdomain", value: "FILTER_SUBDOMAIN", parent: "basic" },
                        { name: "Targeted Data Providers", value: "FILTER_TARGETED_DATA_PROVIDERS", parent: "basic" },
                        { name: "Attributed Userlist ID", value: "FILTER_TARGETED_USER_LIST", parent: "basic" },
                        { name: "Optimized Targeting", value: "FILTER_TARGETING_EXPANSION", parent: "basic" },
                        { name: "Third Party Audience List Cost", value: "FILTER_THIRD_PARTY_AUDIENCE_LIST_COST", parent: "basic" },
                        { name: "Third Party Audience List Type", value: "FILTER_THIRD_PARTY_AUDIENCE_LIST_TYPE", parent: "basic" },
                        { name: "Time of Day", value: "FILTER_TIME_OF_DAY", parent: "basic" },
                        { name: "YouTube A (trueview)", value: "FILTER_TRUEVIEW_AD", parent: "basic" },
                        { name: "YouTube Ad Group (trueview)", value: "FILTER_TRUEVIEW_AD_GROUP", parent: "basic" },
                        { name: "YouTube Ad ID (trueview)", value: "FILTER_TRUEVIEW_AD_GROUP_AD_ID", parent: "basic" },
                        { name: "YouTube Ad Group ID (trueview)", value: "FILTER_TRUEVIEW_AD_GROUP_ID", parent: "basic" },
                        { name: "YouTube Ad Type (trueview)", value: "FILTER_TRUEVIEW_AD_TYPE_NAME", parent: "basic" },
                        { name: "Age (YouTube) (trueview)", value: "FILTER_TRUEVIEW_AGE", parent: "basic" },
                        { name: "Audience Segment (trueview)", value: "FILTER_TRUEVIEW_AUDIENCE_SEGMENT", parent: "basic" },
                        { name: "Audience Segment Type (trueview)", value: "FILTER_TRUEVIEW_AUDIENCE_SEGMENT_TYPE", parent: "basic" },
                        { name: "Category (trueview)", value: "FILTER_TRUEVIEW_CATEGORY", parent: "basic" },
                        { name: "City (trueview)", value: "FILTER_TRUEVIEW_CITY", parent: "basic" },
                        { name: "Click Type (trueview)", value: "FILTER_TRUEVIEW_CLICK_TYPE_NAME", parent: "basic" },
                        { name: "Conversion Type (trueview)", value: "FILTER_TRUEVIEW_CONVERSION_TYPE", parent: "basic" },
                        { name: "Country (YouTube) (trueview)", value: "FILTER_TRUEVIEW_COUNTRY", parent: "basic" },
                        { name: "Custom Affinity (trueview)", value: "FILTER_TRUEVIEW_CUSTOM_AFFINITY", parent: "basic" },
                        { name: "Detailed Demographics (trueview)", value: "FILTER_TRUEVIEW_DETAILED_DEMOGRAPHICS", parent: "basic" },
                        { name: "Detailed Demographics ID (trueview)", value: "FILTER_TRUEVIEW_DETAILED_DEMOGRAPHICS_ID", parent: "basic" },
                        { name: "DMA ID (trueview)", value: "FILTER_TRUEVIEW_DMA", parent: "basic" },
                        { name: "DMA (trueview)", value: "FILTER_TRUEVIEW_DMA_NAME", parent: "basic" },
                        { name: "Gender (trueview)", value: "FILTER_TRUEVIEW_GENDER", parent: "basic" },
                        { name: "Household Income (trueview)", value: "FILTER_TRUEVIEW_HOUSEHOLD_INCOME", parent: "basic" },
                        { name: "Age (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_AGE", parent: "basic" },
                        { name: "Category (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_CATEGORY", parent: "basic" },
                        { name: "City (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_CITY", parent: "basic" },
                        { name: "Country (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_COUNTRY", parent: "basic" },
                        { name: "Country Name (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_COUNTRY_NAME", parent: "basic" },
                        { name: "Gender (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_GENDER", parent: "basic" },
                        { name: "Interest (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_INTEREST", parent: "basic" },
                        { name: "Language (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_LANGUAGE", parent: "basic" },
                        { name: "Parental Status (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_PARENTAL_STATUS", parent: "basic" },
                        { name: "Region (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_REGION_NAME", parent: "basic" },
                        { name: "Remarketing List ID (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_REMARKETING_LIST", parent: "basic" },
                        { name: "Time of Day (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_TIME_OF_DAY", parent: "basic" },
                        { name: "YouTube Channel (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_YOUTUBE_CHANNEL", parent: "basic" },
                        { name: "YouTube Video (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_YOUTUBE_VIDEO", parent: "basic" },
                        { name: "Zip Code (trueview IAR)", value: "FILTER_TRUEVIEW_IAR_ZIPCODE", parent: "basic" },
                        { name: "Interest (trueview)", value: "FILTER_TRUEVIEW_INTEREST", parent: "basic" },
                        { name: "Keyword (trueview)", value: "FILTER_TRUEVIEW_KEYWORD", parent: "basic" },
                        { name: "Parental Status (trueview)", value: "FILTER_TRUEVIEW_PARENTAL_STATUS", parent: "basic" },
                        { name: "Placement (Managed) (trueview)", value: "FILTER_TRUEVIEW_PLACEMENT", parent: "basic" },
                        { name: "Placement ID (Managed) (trueview)", value: "FILTER_TRUEVIEW_PLACEMENT_ID", parent: "basic" },
                        { name: "Region ID (trueview)", value: "FILTER_TRUEVIEW_REGION", parent: "basic" },
                        { name: "Region (trueview)", value: "FILTER_TRUEVIEW_REGION_NAME", parent: "basic" },
                        { name: "Remarketing List ID (trueview)", value: "FILTER_TRUEVIEW_REMARKETING_LIST", parent: "basic" },
                        { name: "Remarketing List (trueview)", value: "FILTER_TRUEVIEW_REMARKETING_LIST_NAME", parent: "basic" },
                        { name: "Optimized Targeting (trueview)", value: "FILTER_TRUEVIEW_TARGETING_EXPANSION", parent: "basic" },
                        { name: "Placement (All) (trueview)", value: "FILTER_TRUEVIEW_URL", parent: "basic" },
                        { name: "Zipcode (trueview)", value: "FILTER_TRUEVIEW_ZIPCODE", parent: "basic" },
                        { name: "Audience List ID", value: "FILTER_USER_LIST", parent: "basic" },
                        { name: "First Party Audience List ID", value: "FILTER_USER_LIST_FIRST_PARTY", parent: "basic" },
                        { name: "First Party Audience List", value: "FILTER_USER_LIST_FIRST_PARTY_NAME", parent: "basic" },
                        { name: "Third Party Audience List ID", value: "FILTER_USER_LIST_THIRD_PARTY", parent: "basic" },
                        { name: "Third Party Audience List", value: "FILTER_USER_LIST_THIRD_PARTY_NAME", parent: "basic" },
                        { name: "Date (UTC)", value: "FILTER_UTC_DATE", parent: "basic" },
                        { name: "Variant ID", value: "FILTER_VARIANT_ID", parent: "basic" },
                        { name: "Variant Name", value: "FILTER_VARIANT_NAME", parent: "basic" },
                        { name: "Variant Version", value: "FILTER_VARIANT_VERSION", parent: "basic" },
                        { name: "Vendor Measurement Mode", value: "FILTER_VENDOR_MEASUREMENT_MODE", parent: "basic" },
                        { name: "Audibility At Complete", value: "FILTER_VERIFICATION_AUDIBILITY_COMPLETE", parent: "basic" },
                        { name: "Audibility At Start", value: "FILTER_VERIFICATION_AUDIBILITY_START", parent: "basic" },
                        { name: "Verification Video Player Size", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE", parent: "basic" },
                        { name: "Video Player Size at Completion", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE_COMPLETE", parent: "basic" },
                        { name: "Video Player Size at First Quartile", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE_FIRST_QUARTILE", parent: "basic" },
                        { name: "Video Player Size at Midpoint", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE_MID_POINT", parent: "basic" },
                        { name: "Video Player Size at Start", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE_START", parent: "basic" },
                        { name: "Video Player Size at Third Quartile", value: "FILTER_VERIFICATION_VIDEO_PLAYER_SIZE_THIRD_QUARTILE", parent: "basic" },
                        { name: "Verification Video Position", value: "FILTER_VERIFICATION_VIDEO_POSITION", parent: "basic" },
                        { name: "Video Resized", value: "FILTER_VERIFICATION_VIDEO_RESIZED", parent: "basic" },
                        { name: "Video Ad Position In Stream", value: "FILTER_VIDEO_AD_POSITION_IN_STREAM", parent: "basic" },
                        { name: "Companion Creative Size", value: "FILTER_VIDEO_COMPANION_CREATIVE_SIZE", parent: "basic" },
                        { name: "Video Content Duration", value: "FILTER_VIDEO_CONTENT_DURATION", parent: "basic" },
                        { name: "Video Content Live Stream", value: "FILTER_VIDEO_CONTENT_LIVE_STREAM", parent: "basic" },
                        { name: "Video Continuous Play", value: "FILTER_VIDEO_CONTINUOUS_PLAY", parent: "basic" },
                        { name: "Video Creative Duration", value: "FILTER_VIDEO_CREATIVE_DURATION", parent: "basic" },
                        { name: "Video Creative Duration (Skippable)", value: "FILTER_VIDEO_CREATIVE_DURATION_SKIPPABLE", parent: "basic" },
                        { name: "Video Duration", value: "FILTER_VIDEO_DURATION", parent: "basic" },
                        { name: "Max Video Duration", value: "FILTER_VIDEO_DURATION_SECONDS", parent: "basic" },
                        { name: "Max Video Duration Range", value: "FILTER_VIDEO_DURATION_SECONDS_RANGE", parent: "basic" },
                        { name: "Video Format Support", value: "FILTER_VIDEO_FORMAT_SUPPORT", parent: "basic" },
                        { name: "Video Player Size", value: "FILTER_VIDEO_PLAYER_SIZE", parent: "basic" },
                        { name: "Digital Content Label", value: "FILTER_VIDEO_RATING_TIER", parent: "basic" },
                        { name: "Video Skippable Support", value: "FILTER_VIDEO_SKIPPABLE_SUPPORT", parent: "basic" },
                        { name: "Week", value: "FILTER_WEEK", parent: "basic" },
                        { name: "Year", value: "FILTER_YEAR", parent: "basic" },
                        { name: "YouTube Adapted Audience List (Youtube)", value: "FILTER_YOUTUBE_ADAPTED_AUDIENCE_LIST", parent: "basic" },
                        { name: "YouTube Ad Video (Youtube)", value: "FILTER_YOUTUBE_AD_VIDEO", parent: "basic" },
                        { name: "YouTube Ad Video ID (Youtube)", value: "FILTER_YOUTUBE_AD_VIDEO_ID", parent: "basic" },
                        { name: "YouTube Channel (Youtube)", value: "FILTER_YOUTUBE_CHANNEL", parent: "basic" },
                        { name: "Advertiser (Youtube)", value: "FILTER_YOUTUBE_PROGRAMMATIC_GUARANTEED_ADVERTISER", parent: "basic" },
                        { name: "Insertion Order (Youtube)", value: "FILTER_YOUTUBE_PROGRAMMATIC_GUARANTEED_INSERTION_ORDER", parent: "basic" },
                        { name: "Partner (Youtube)", value: "FILTER_YOUTUBE_PROGRAMMATIC_GUARANTEED_PARTNER", parent: "basic" },
                        { name: "YouTube Video (Youtube)", value: "FILTER_YOUTUBE_VIDEO", parent: "basic" },
                        { name: "Zip Code ID (Youtube)", value: "FILTER_ZIP_CODE", parent: "basic" },
                        { name: "Zip Code (Youtube)", value: "FILTER_ZIP_POSTAL_CODE", parent: "basic" },
                    ]
                }
            ];
            return breakdowns;
        },
        breakdownsGoogleSC: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" },
                        { name: "country", value: "country", parent: "basic" },
                        { name: "device", value: "device", parent: "basic" },
                        { name: "page", value: "page", parent: "basic" },
                        { name: "query", value: "query", parent: "basic" },
                        { name: "search appearance", value: "searchAppearance", parent: "basic" },
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsTiktok: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" },
                        { name: "Date", value: "date", parent: "Popular" },
                        { name: "Age", value: "age", parent: "Popular" },
                        { name: "Gender", value: "gender", parent: "Popular" },
                        { name: "Country code", value: "country_code", parent: "Popular" }
                    ]
                },
            ];
            if (this.functions.onlyAccountSelected()) {
                breakdowns.push({
                    title: "Resources",
                    values: [

                    ]
                });
                //breakdowns[1].values.push({ name: "Campaigns", value: "campaigns", parent: "Basic" });
                //breakdowns[1].values.push({ name: "Adgroups", value: "adgroups", parent: "Basic" });
                breakdowns[1].values.push({ name: "Ads", value: "ads", parent: "Basic" });
            }
            return breakdowns;
        },
        breakdownsSnapchat: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" },
                        { name: "Gender", value: "gender", parent: "basic" },
                        { name: "Age", value: "age", parent: "basic" },
                        { name: "Country", value: "country", parent: "basic" },
                        { name: "Region", value: "region", parent: "basic" },
                        { name: "Dma", value: "dma", parent: "basic" },
                        { name: "Date", value: "date", parent: "basic" },
                        { name: "Weekly", value: "weekly", parent: "basic" }
                    ]
                },
            ];
            if (this.functions.onlyAccountSelected()) {
                breakdowns.push({
                    title: "Resources",
                    values: [

                    ]
                });
                //breakdowns[1].values.push({ name: "Campaigns", value: "campaigns", parent: "Basic" });
                //breakdowns[1].values.push({ name: "Adgroups", value: "adgroups", parent: "Basic" });
                breakdowns[1].values.push({ name: "Ads", value: "ads", parent: "Basic" });
            }
            return breakdowns;
        },
        breakdownsTwitter: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" },
                        { name: "Date", value: "date", parent: "Popular" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsGoogle: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" },
                        { name: "Gender", value: "gender", parent: "Popular" },
                        { name: "Age", value: "age", parent: "Popular" },
                        { name: "Device", value: "device", parent: "Popular" },
                        { name: "Region", value: "region", parent: "Popular" },
                        { name: "County", value: "county", parent: "Popular" },
                        { name: "City", value: "city", parent: "Popular" },
                        { name: "District", value: "district", parent: "Popular" },
                        { name: "Date", value: "date", parent: "Popular" },
                        { name: "Weekday", value: "dayOfWeek", parent: "Popular" },
                        { name: "Month of the year", value: "monthOfYear", parent: "Popular" },
                        { name: "Ad network type", value: "adNetworkType", parent: "Popular" },
                        { name: "Conversion", value: "conversion", parent: "Popular" }
                    ]
                },
                {
                    title: "Resources",
                    values: [
                        { name: "Search terms", value: "search_terms", parent: "Resources" },
                        { name: "Keywords", value: "keyword", parent: "Resources" }
                    ]
                }
            ];
            if (this.functions.onlyAccountAndCampaignSelected()) {
                breakdowns[0].values.push({ name: "Topic", value: "topic_view", parent: "Popular" });
                breakdowns[0].values.push({ name: "Audience campaign", value: "audience", parent: "Popular" });
                breakdowns[0].values.push({ name: "Audience adgroup", value: "audience_adgroup", parent: "Popular" });
                //breakdowns[0].values.push({ name: "Audience (custom)", value: "audience_custom", parent: "Popular" });
                //breakdowns[0].values.push({ name: "Audience (combined)", value: "audience_combined", parent: "Popular" });
                breakdowns[0].values.push({ name: "Placement (detail)", value: "placement_detail", parent: "Popular" });
                breakdowns[0].values.push({ name: "Placement (group)", value: "placement_group", parent: "Popular" });
                //breakdowns[0].values.push({ name: "Placement (type)", value: "placement_type", parent: "Popular" });
                breakdowns[0].values.push({ name: "Display/video keyword", value: "display_keyword", parent: "Popular" });
            }
            if (this.functions.onlyAccountSelected()) {
                breakdowns[1].values.push({ name: "Campaigns", value: "campaigns", parent: "Resources" });
                breakdowns[1].values.push({ name: "Adgroups", value: "adgroups", parent: "Resources" });
                breakdowns[1].values.push({ name: "Campaign assets", value: "campaign_assets", parent: "Resources" });
                breakdowns[1].values.push({ name: "Adgroup assets", value: "adgroup_assets", parent: "Resources" });
                //breakdowns[1].values.push({ name: "Ads", value: "ads", parent: "Popular" });
                breakdowns[1].values.push({ name: "Bidding strategy", value: "bidding_strategy", parent: "Resources" });
                breakdowns[0].values.push({ name: "Shopping", value: "shopping", parent: "Popular" });
            }
            return breakdowns;
        },
        breakdownsLinkedin: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" },
                        { name: "Daily", value: "date", parent: "Popular" }
                    ]
                },
                {
                    title: "Compnay",
                    values: [
                        { name: "Company", value: "MEMBER_COMPANY", parent: "Popular" },
                        { name: "Company Industry", value: "MEMBER_INDUSTRY", parent: "Popular" },
                        { name: "Company Size", value: "MEMBER_COMPANY_SIZE", parent: "Popular" },
                    ]
                },
                {
                    title: "Job",
                    values: [
                        { name: "Job Title", value: "MEMBER_JOB_TITLE", parent: "Popular" },
                        { name: "Job Function", value: "MEMBER_JOB_FUNCTION", parent: "Popular" },
                        { name: "Job Seniority", value: "MEMBER_SENIORITY", parent: "Popular" },
                    ]
                },
                {
                    title: "Location",
                    values: [
                        { name: "Location", value: "MEMBER_REGION_V2", parent: "Popular" },
                        { name: "Country/Region", value: "MEMBER_COUNTRY_V2", parent: "Popular" },
                        { name: "County", value: "MEMBER_COUNTY", parent: "Popular" }
                    ]
                },
            ];
            if (this.functions.onlyAccountSelected()) {
                breakdowns.push({
                    title: "Resources",
                    values: [

                    ]
                });
                //breakdowns[1].values.push({ name: "Campaigns", value: "campaigns", parent: "Basic" });
                //breakdowns[1].values.push({ name: "Adgroups", value: "adgroups", parent: "Basic" });
                breakdowns[4].values.push({ name: "Ads", value: "ads", parent: "Basic" });
            }
            return breakdowns;
        },
        breakdownsBidtheatre: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Basic" },
                        { name: "Year / Month / Day", value: "day", parent: "Basic" },
                        { name: "Campaign Label", value: "campaignLabel", parent: "Basic" },
                        { name: "Campaign Package", value: "campaignPackage", parent: "Basic" },
                        { name: "Zone", value: "zone", parent: "Basic" },
                        { name: "Category", value: "category", parent: "Basic" },
                        { name: "Label", value: "label", parent: "Basic" },
                        { name: "Campaign Type", value: "campaignType", parent: "Basic" },
                        { name: "Site Label", value: "siteLabel", parent: "Basic" },
                        { name: "Zone Label", value: "zoneLabel", parent: "Basic" },
                        { name: "Advertiser Label", value: "advertiserLabel", parent: "Basic" },
                        { name: "Client", value: "client", parent: "Basic" },
                        { name: "Adgroup", value: "adgroup", parent: "Basic" },
                        { name: "Ad", value: "ad", parent: "Basic" },
                        { name: "Publisher", value: "publisher", parent: "Basic" },
                        { name: "rtb Supplier", value: "rtbSupplier", parent: "Basic" },
                        { name: "rtb Site", value: "rtbSite", parent: "Basic" },
                        { name: "rtb Supplier", value: "rtbSupplier", parent: "Basic" }
                    ]
                },
                {
                    title: "Time",
                    values: [
                        { name: "Year", value: "year", parent: "Time" },
                        { name: "Year / week", value: "week", parent: "Time" },
                        { name: "Year / Month", value: "month", parent: "Time" },
                        { name: "Year / Month / Day", value: "day", parent: "Time" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsAdform: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular", description: "" }]
                },
                {
                    title: "Banner", values: [
                        { name: "Banner/Adgroups", value: "banner", parent: "Banner", description: "" },
                        { name: "Banner ID", value: "bannerID", parent: "Banner", description: "" },
                        { name: "Banner Size", value: "bannerSize", parent: "Banner", description: "" },
                        { name: "Banner type", value: "bannerType", parent: "Banner", description: "" },
                        { name: "Banner type ID", value: "bannerTypeID", parent: "Banner", description: "" },
                        { name: "Banner format", value: "bannerFormat", parent: "Banner", description: "" },
                        { name: "Banner format ID", value: "bannerFormatID", parent: "Banner", description: "" },
                        { name: "Banner member", value: "bannerMember", parent: "Banner", description: "" },
                        { name: "Backup banner", value: "bannerBackup", parent: "Banner", description: "" },
                        { name: "Backup banner", value: "bannerBackup", parent: "Banner", description: "" },
                    ]
                },
                {
                    title: "Site Tracking", values: [
                        { name: "Page", value: "page", parent: "Site Tracking", description: "Website tracking dimension. Page is a definable content unit in advertiser's site. Usually one tracking point is implemented per page." },
                    ]
                },
                {
                    title: "Time", values: [
                        { name: "Date", value: "date", parent: "Time", decription: "This dimension groups information by date when a transaction happened." },
                    ]
                },
                {
                    title: "Technical", values: [
                        { name: "Device Type", value: "deviceType", parent: "Technical", description: "Technical dimension, groups data by device type, which can be: Desktop and Laptop, Small Screen Phone, Smart Phone, Tablet, Other Mobile, Media Hub, Console, TV, eReader, Smart Watch, Smart Speaker. It shows what share of traffic comes from different device types." },
                    ]
                },
                {
                    title: "RTB", values: [
                        { name: "RTB Domain", value: "rtbDomain", parent: "RTB", description: "This dimension groups data by RTB domain. RTB domains show 2nd level URLs of publisher sites (e.g. cnn.com), where a banner was displayed when impression was registered. RTB domain values are tracked and sent to Adform by AdExchanges." },
                        { name: "RTB Audience", value: "rtbAudience", parent: "RTB", description: "This dimension groups information by RTB audience selected in the Targeting step. An audience is a customized segment of users to be targeted, e.g. women from Denmark using Android devices." }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsGoogleAnalytics: () => {
            let beakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" }
                    ]
                },
                {
                    title: "User",
                    values: [
                        { name: "User Type", value: "ga:userType", parent: "User" },
                        { name: "Count of Sessions", value: "ga:sessionCount", parent: "User" }
                    ]
                },
                {
                    title: "Traffic sources",
                    values: [
                        { name: "Medium", value: "ga:medium", parent: "Traffic sources" },
                        { name: "Source / Medium", value: "ga:sourceMedium", parent: "Traffic sources" },
                        { name: "Full Referrer", value: "ga:fullReferrer", parent: "Traffic sources" },
                        { name: "Referral path", value: "ga:referralPath", parent: "Traffic sources" }
                    ]
                }
            ];
            return beakdowns;
        },
        breakdownsFacebook: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Basic" },
                        { name: "Ad Format Asset", value: "ad_format_asset", parent: "Basic" },
                        { name: "App ID", value: "app_id", parent: "Basic" },
                        { name: "Body Asset", value: "body_asset", parent: "Basic" },
                        { name: "Call To Action Asset", value: "call_to_action_asset", parent: "Basic" },
                        { name: "Date", value: "date", parent: "Basic" },
                        { name: "Weekly", value: "weekly", parent: "Basic" },
                        { name: "Description Asset", value: "description_asset", parent: "Basic" },
                        { name: "Image Asset", value: "image_asset", parent: "Basic" },
                        { name: "Link URL Asset", value: "link_url_asset", parent: "Basic" },
                        { name: "Skan Conversion ID", value: "skan_conversion_id", parent: "Basic" },
                        { name: "Title Asset", value: "title_asset", parent: "Basic" },
                        { name: "Video Asset", value: "video_asset", parent: "Basic" },
                        { name: "DMA", value: "dma", parent: "Basic" },
                        { name: "Frequency Value", value: "frequency_value", parent: "Basic" },
                        { name: "Hourly stats by advertiser timezone", value: "hourly_stats_aggregated_by_advertiser_time_zone", parent: "Basic" },
                        { name: "Hourly stats by audience timezone", value: "hourly_stats_aggregated_by_audience_time_zone", parent: "Basic" },
                        { name: "Place Page ID", value: "place_page_id", parent: "Basic" },
                        { name: "Country", value: "country", parent: "Basic" },
                        { name: "Region", value: "region", parent: "Basic" },
                        { name: "Age", value: "age", parent: "Basic" },
                        { name: "Gender", value: "gender", parent: "Basic" },
                        { name: "Platform posistion", value: "platform_position", parent: "Basic" },
                        { name: "Publisher Platform", value: "publisher_platform", parent: "Basic" },
                        { name: "Impression Device", value: "impression_device", parent: "Basic" },
                        //{ name: "Shopping", value: "product_id", parent: "Basic" }
                    ]
                }
            ];
            if (this.functions.onlyAccountSelected()) {
                breakdowns.push({
                    title: "Resources",
                    values: [

                    ]
                });
                breakdowns[1].values.push({ name: "Campaigns", value: "campaigns", parent: "Basic" });
                breakdowns[1].values.push({ name: "Adgroups", value: "adgroups", parent: "Basic" });
                breakdowns[1].values.push({ name: "Ads", value: "ads", parent: "Basic" });
                breakdowns[1].values.push({ name: "Shopping", value: "product_id", parent: "Basic" });
            }
            return breakdowns;
        },
        getCustomDatasourceBreakdowns: async (channel_breakdowns) => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({
                    loading_custom_breakdowns: true
                });
                try {
                    let response = await this.calls.getCustomDatasourceBreakdowns();
                    channel_breakdowns = channel_breakdowns.concat(response.data);
                    await this.promisedSetState({
                        custom_breakdowns: response.data,
                        loading_custom_breakdowns: false
                    });
                    resolve(channel_breakdowns);
                } catch (error) {
                    await this.promisedSetState({
                        loading_custom_breakdowns: false
                    });
                    resolve(channel_breakdowns);
                }
            })
        },
        search: (search, options) => {
            if (!search || search == "") {
                return options;
            }
            let result = fuzzysort.go(search, options.map((item) => { return item.name }));
            let keywords = [];
            let old_keywords = JSON.parse(JSON.stringify(options));
            result.map((item) => {
                for (let i = 0; i < old_keywords.length; i++) {
                    if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                        let html_string = "";
                        if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                            for (let m = 0; m < item.target.length; m++) {
                                if (!item.indexes.includes(m)) {
                                    html_string = html_string + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                } else {
                                    html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                }
                            }
                        }
                        old_keywords[i].html = html_string;
                        keywords.push(old_keywords[i]);
                        old_keywords.splice(i, 1);
                        break;
                    }
                }
            });
            return keywords;
        }
    };

    calls = {
        getCustomDatasourceBreakdowns: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listCustomDatasourceBreakdowns";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div
                    className={(this.functions.valid() ? "" : "opacity-50 ") + " px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            if (this.props.onToggle) {
                                this.props.onToggle(this.state.open);
                            }
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-semibold text-sm">
                                Select breakdown
                            </h3>
                            {
                                !(this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0) &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    There are <span
                                        className="font-bold ml-1 mr-1">{this.state.channel_breakdowns.length}</span> breakdowns
                                    available
                                </p>
                            }
                            {
                                (this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0) &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    You have selected <span className="font-bold ml-1 mr-1">{this.state.channel_breakdowns.filter((item) => {
                                        let channels = this.functions.selectedDataSourcesChannells();
                                        return channels[item.channel];
                                    }).map((item) => {
                                        let dimension = "";
                                        if (this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel]) {
                                            Object.keys(this.state.tab.channel_breakdowns[item.channel]).map((breakdown) => {
                                                if (this.state.tab.channel_breakdowns[item.channel][breakdown]) {
                                                    dimension = this.state.tab.channel_breakdowns[item.channel][breakdown].name;
                                                    dimension += " (" + item.channel + ")";
                                                }
                                            });
                                        }
                                        return dimension;
                                    }).join(", ")}</span> breakdowns
                                </p>
                            }
                        </div>
                        {
                            this.functions.anythingSelected() &&
                            <div className="flex flex-row">
                                {
                                    this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                                    <div
                                        className="mr-2 text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    if (this.props.onToggle) {
                                        this.props.onToggle(this.state.open);
                                    }
                                }}
                                    className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="flex flex-1 mb-4 flex-col justify-center">
                            <InputTailwind
                                label={null}
                                placeholder={"Search breakdowns ..."}
                                value={this.state.search_breakdowns}
                                onChange={(value) => {
                                    this.setState({
                                        search_breakdowns: value
                                    });
                                }}
                            />
                        </div>
                        <div className="">
                            {
                                this.state.channel_breakdowns.filter((item) => {
                                    let channels = this.functions.selectedDataSourcesChannells();
                                    return channels[item.channel];
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={item.channel + i}>
                                            <div
                                                className="flex flex-1 flex-row justify-center mb-2">
                                                <div onClick={() => {
                                                    if (!(this.state.tab.celltype && this.state.tab.celltype.id === "performance")) {
                                                        this.state.open_channel[item.channel] = !this.state.open_channel[item.channel];
                                                        this.setState({
                                                            open_channel: this.state.open_channel,
                                                            channel_breakdowns: this.state.channel_breakdowns
                                                        })
                                                    }
                                                }}
                                                    className={"text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center"}>
                                                    {
                                                        !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                                                        !this.state.open_channel[item.channel] &&
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={16}
                                                            icon="plus" />
                                                    }
                                                    {
                                                        !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                                                        this.state.open_channel[item.channel] &&
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={16}
                                                            icon="minus" />
                                                    }
                                                </div>
                                                {
                                                    [
                                                        { name: 'facebook', icon: 'facebook_icon.svg', style: { width: '0.4rem' } },
                                                        { name: 'google', icon: 'google_icon.svg' },
                                                        { name: 'google_analytics', icon: 'google_analytics_icon.png' },
                                                        { name: 'google_analytics_4', icon: 'ga_icon.png' },
                                                        { name: 'google_shopping', icon: 'google_shopping_icon.png' },
                                                        { name: 'adform', icon: 'adform_icon.png' },
                                                        { name: 'linkedin', icon: 'linkedin_icon.svg' },
                                                        { name: 'twitter', icon: 'twitter_icon.svg' },
                                                        { name: 'bidtheatre', icon: 'bidtheatre_icon.png' },
                                                        { name: 'snapchat', icon: 'snapchat.svg' },
                                                        { name: 'bing', icon: 'bing-logo.png' },
                                                        { name: 'dv360', icon: 'dv360-logo.svg' }
                                                    ].filter((channel) => { return (item.channel == channel.name) }).map((item) => {
                                                        return (
                                                            <div
                                                                className={"bg-" + item.name.replace('_', '').replace('_', '') + "-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center"}>
                                                                <img className={item.style ? "" : "w-3"}
                                                                    style={item.style ? item.style : {}}
                                                                    src={require('../assets/images/' + item.icon)} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    item.custom &&
                                                    <div
                                                        style={{ backgroundColor: "#" + item.color }}
                                                        className={"mr-2 h-10 w-10 rounded flex justify-center align-middle items-center"}>
                                                        {
                                                            item.logo &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + item.logo + ")" }}></div>
                                                        }
                                                        {
                                                            !item.logo && item.channel && typeof item.channel == "string" &&
                                                            <div className="w-full h-full relative flex items-center justify-center text-semibold text-white text-sm uppercase">
                                                                {item.channel[0]}{item.channel[1]}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className="flex flex-1 flex-col capitalize">
                                                    <div className="font-medium text-sm">
                                                        {item.channel.replace("_", " ").replace("_", " ")}
                                                    </div>
                                                    {
                                                        this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel] &&
                                                        <div className="text-xxs">
                                                            Selected <span className="font-bold">{Object.keys(this.state.tab.channel_breakdowns[item.channel]).map((breakdown) => {
                                                                return this.state.tab.channel_breakdowns[item.channel][breakdown].name
                                                            }).join(",")}</span>
                                                        </div>
                                                        ||
                                                        <div className="text-xxs">
                                                            {item.breakdowns.length} categories available
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <SlideDown closed={!this.state.open_channel[item.channel] && this.state.search_breakdowns == ""}>
                                                <div className="ml-12">
                                                    {
                                                        item.breakdowns.filter((item) => {
                                                            if (this.state.tab.celltype && this.state.tab.celltype.id === "performance") {
                                                                return false;
                                                            } else {
                                                                return true;
                                                            }
                                                        }).map((inner_item, index) => {
                                                            return (
                                                                <Fragment key={"innner" + index}>
                                                                    <div
                                                                        className="flex flex-1 flex-row justify-center mb-2">
                                                                        <div onClick={() => {
                                                                            this.state.open_channel[item.channel + index] = !this.state.open_channel[item.channel + index];
                                                                            this.setState({
                                                                                open_channel: this.state.open_channel,
                                                                                channel_breakdowns: this.state.channel_breakdowns
                                                                            })
                                                                        }}
                                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            {
                                                                                !this.state.open_channel[item.channel + index] &&
                                                                                <FeatherIcon className={'stroke-current'}
                                                                                    size={16}
                                                                                    icon="plus" />
                                                                            }
                                                                            {
                                                                                this.state.open_channel[item.channel + index] &&
                                                                                <FeatherIcon className={'stroke-current'}
                                                                                    size={16}
                                                                                    icon="minus" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col capitalize">
                                                                            <div className="font-medium text-sm">
                                                                                {inner_item.title}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                Breakdowns {inner_item.values.length} available
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <SlideDown closed={!this.state.open_channel[item.channel + index] && this.state.search_breakdowns == ""}>
                                                                        {
                                                                            false &&
                                                                            <div className="text-xs font-bold mb-1 pl-10">
                                                                                Search
                                                                            </div>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            <div className="flex flex-1 flex-col justify-center mb-2 pl-10">
                                                                                <input
                                                                                    onChange={(event) => {
                                                                                        inner_item.search = event.target.value;
                                                                                        this.setState({
                                                                                            channel_breakdowns: this.state.channel_breakdowns
                                                                                        });
                                                                                    }}
                                                                                    placeholder={"Search ..."}
                                                                                    value={inner_item.search ? inner_item.search : ""}
                                                                                    className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.functions.search(this.state.search_breakdowns, inner_item.values).filter((item) => {
                                                                                if (this.state.tab && this.state.tab.celltype && this.state.tab.celltype.value === "chart") {
                                                                                    return item.value !== "search_terms";
                                                                                } else {
                                                                                    return true
                                                                                }
                                                                            }).filter((item) => {
                                                                                if ((item.value === "search_terms" || item.value === "page") && this.functions.amountSelected() > 1) {
                                                                                    return false;
                                                                                } else {
                                                                                    return true;
                                                                                }
                                                                            }).map((dimension) => {
                                                                                return (
                                                                                    <Fragment>
                                                                                        <div className="ml-12 flex flex-1 flex-row mb-2">
                                                                                            <div onClick={() => {
                                                                                                if (!this.state.tab.channel_breakdowns) {
                                                                                                    this.state.tab.channel_breakdowns = {};
                                                                                                }
                                                                                                this.state.tab.channel_breakdowns[item.channel] = {};
                                                                                                this.state.tab.channel_breakdowns[item.channel][dimension.value] = dimension;
                                                                                                this.state.tab.channel_breakdowns[item.channel][dimension.value].filters = [];
                                                                                                //this.state.tab.channel_breakdowns[item.channel][dimension.value].filters = [{ name: "", id: Math.floor((Math.random() * 9999999999) + 1), items: [{ id: Math.floor((Math.random() * 9999999999) + 1), field: "" }] }];
                                                                                                if (this.props.onTab) {
                                                                                                    this.props.onTab(this.state.tab);
                                                                                                }
                                                                                            }}
                                                                                                className={((this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel] && this.state.tab.channel_breakdowns[item.channel][dimension.value]) ? "border-purple-500" : "") + " h-10 w-10 p-1 cursor-pointer rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                                {
                                                                                                    this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel] && this.state.tab.channel_breakdowns[item.channel][dimension.value] &&
                                                                                                    <div
                                                                                                        className="w-full h-full rounded-full bg-purple-500" />
                                                                                                }
                                                                                            </div>
                                                                                            <div className="flex flex-1 flex-col capitalize">
                                                                                                <div className="font-medium text-sm">
                                                                                                    {dimension.name}
                                                                                                </div>
                                                                                                <div className="text-xxs">
                                                                                                    {dimension.parent}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            false &&
                                                                                            dimension.value !== "overview" &&
                                                                                            this.state.tab.channel_breakdowns &&
                                                                                            this.state.tab.channel_breakdowns[item.channel] &&
                                                                                            this.state.tab.channel_breakdowns[item.channel][dimension.value] &&
                                                                                            <div className="text-xs font-bold mb-1 pl-20">
                                                                                                Filter (optional)
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            false &&
                                                                                            dimension.value !== "overview" &&
                                                                                            this.state.tab.channel_breakdowns &&
                                                                                            this.state.tab.channel_breakdowns[item.channel] &&
                                                                                            this.state.tab.channel_breakdowns[item.channel][dimension.value] &&
                                                                                            <div className="flex flex-1 flex-col justify-center mb-2 pl-20">
                                                                                                <input
                                                                                                    onChange={(event) => {
                                                                                                        this.state.tab.channel_breakdowns[item.channel][dimension.value].filter = event.target.value;
                                                                                                        if (this.props.onTab) {
                                                                                                            this.props.onTab(this.state.tab);
                                                                                                        }
                                                                                                    }}
                                                                                                    placeholder={"Filer value"}
                                                                                                    value={this.state.tab.channel_breakdowns[item.channel][dimension.value].filter}
                                                                                                    className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </Fragment>

                                                                                )
                                                                            })
                                                                        }
                                                                    </SlideDown>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </SlideDown>
                                            {
                                                (this.state.channel_breakdowns.filter((item) => {
                                                    let channels = this.functions.selectedDataSourcesChannells();
                                                    return channels[item.channel];
                                                }).length - 1) !== i &&
                                                <div className="border-t flex-1 flex mb-2" />
                                            }
                                        </Fragment>

                                    )
                                })
                            }
                        </div>
                        {
                            this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} class="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabindex="0">Next</button>
                            </div>
                        }
                    </SlideDown >
                </div >
            </>
        )
    }
}

class SelectCellChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            channels: {},
            accounts: {},
            campaigns: {},
            adgroups: {},
            ads: {}
        };
    };

    componentDidMount() {
        this.setState({
            tab: this.props.tab ? this.props.tab : {},
            channels: this.props.selectedChannels ? this.props.selectedChannels : {},
            accounts: this.props.selectedAccounts ? this.props.selectedAccounts : {},
            campaigns: this.props.selectedCampaigns ? this.props.selectedCampaigns : {},
            adgroups: this.props.selectedAdgroups ? this.props.selectedAdgroups : {},
            ads: this.props.selectedAds ? this.props.selectedAds : {},
            open: this.props.open
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            tab: nextProps.tab ? nextProps.tab : {},
            channels: nextProps.selectedChannels ? nextProps.selectedChannels : {},
            accounts: nextProps.selectedAccounts ? nextProps.selectedAccounts : {},
            campaigns: nextProps.selectedCampaigns ? nextProps.selectedCampaigns : {},
            adgroups: nextProps.selectedAdgroups ? nextProps.selectedAdgroups : {},
            ads: nextProps.selectedAds ? nextProps.selectedAds : {},
            open: nextProps.open
        })
    }

    functions = {
        valid: () => {
            return true;
        },
        chartTypes: () => {
            let chartTypes = [
                {
                    title: "Bar",
                    subtitle: "Display data in a bar chart",
                    icon: "bar-chart-2",
                    value: "bar"
                },
                {
                    title: "Line",
                    subtitle: "Display data in a line chart",
                    icon: "activity",
                    value: "line"
                },
                {
                    title: "Donut",
                    subtitle: "Display data in a donut chart",
                    icon: "pie-chart",
                    value: "donut"
                },
            ];
            return chartTypes;
        },
        donutFormValidation: () => {
            let amount_of_datasources = 0;
            let breakdowns = {};
            let channels = {};
            amount_of_datasources += Object.keys(this.state.channels).length;
            amount_of_datasources += Object.keys(this.state.accounts).length;
            amount_of_datasources += Object.keys(this.state.campaigns).length;
            amount_of_datasources += Object.keys(this.state.adgroups).length;
            amount_of_datasources += Object.keys(this.state.ads).length;
            Object.keys(this.state.accounts).map((item) => {
                channels[this.state.accounts[item].channel] = true;
            });
            Object.keys(this.state.campaigns).map((item) => {
                channels[this.state.campaigns[item].channel] = true;
            });
            Object.keys(this.state.adgroups).map((item) => {
                channels[this.state.adgroups[item].channel] = true;
            });
            Object.keys(this.state.ads).map((item) => {
                channels[this.state.ads[item].channel] = true;
            });
            for (let channel in this.state.tab.channel_breakdowns) {
                for (let breakdown in this.state.tab.channel_breakdowns[channel]) {
                    if (breakdown !== "basic") {
                        breakdowns[breakdown] = channel;
                    }
                }
            }
            if (amount_of_datasources > 0 && Object.keys(channels).length > 1 && ("overview" in breakdowns) && Object.keys(breakdowns).length === 1) {
                return true;
            } else if (amount_of_datasources > 0 && Object.keys(channels).length === 1) {
                return true;
            } else if (amount_of_datasources === 1) {
                return true;
            } else {
                return false;
            }
        }
    };

    render() {
        return (
            <>
                <div
                    className={(this.functions.valid() ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            this.props.onToggle(this.state.open);
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-semibold text-sm">
                                Select chart type
                            </h3>
                            {
                                !this.state.tab.chart &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    There are <span
                                        className="font-bold ml-1 mr-1">{this.functions.chartTypes().length}</span> metrics
                                    available
                                </p>
                            }
                            {
                                this.state.tab.chart && this.state.tab.chart.value &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    You have selected <span
                                        className="font-bold ml-1 mr-1">{this.state.tab.chart.title}</span>
                                </p>
                            }
                        </div>
                        {
                            this.functions.valid() &&
                            <div className="flex flex-row">
                                {
                                    this.state.tab.chart &&
                                    <div
                                        className=" text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    this.props.onToggle(this.state.open);
                                }}
                                    className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="">
                            {
                                this.functions.chartTypes().map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div
                                                className={(((this.functions.donutFormValidation() && item.value === "donut") || item.value !== "donut") ? "cursor-pointer " : "cursor-not-allowed opacity-50 ") + (i !== (this.functions.chartTypes().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                <div
                                                    className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'}
                                                        size={17}
                                                        icon={item.icon} />
                                                </div>
                                                <div onClick={() => {
                                                    if ((this.functions.donutFormValidation() && item.value === "donut") || item.value !== "donut") {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = {
                                                                title: "",
                                                                chart_setting: { title: "Breakdown/Metric", subtitle: "Combine breakdown and metrics", value: "breakdown/metric" },
                                                                chart_numbers: { title: "Disabled", subtitle: "Inline values will only show on hover", value: "disabled" }
                                                            }
                                                        } else {
                                                            if (item.value === "donut" || item.value === "line") {
                                                                try {
                                                                    delete this.state.tab.settings.groupBreakdowns;
                                                                    delete this.state.tab.settings.groupBreakdownsGroups;
                                                                    delete this.state.tab.settings.groupBreakdownsType;
                                                                } catch (error) { }
                                                            }
                                                        }
                                                        this.state.tab.chart = item;
                                                        this.props.onTab(this.state.tab);
                                                        this.props.onNext();
                                                    }
                                                }}
                                                    className={((this.state.tab.chart && this.state.tab.chart.value === item.value) ? "border-purple-500" : "") + " h-10 w-10 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                    {
                                                        this.state.tab.chart && this.state.tab.chart.value === item.value &&
                                                        <div
                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                    }
                                                </div>
                                                <div className="flex flex-1 flex-col">
                                                    <div className="font-medium text-sm">
                                                        {item.title}
                                                    </div>
                                                    <div className="text-xxs">
                                                        {item.subtitle}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.tab.chart && this.state.tab.chart.value &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} class="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabindex="0">Next</button>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }
}

class SelectCellDaterange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            channels: {},
            accounts: {},
            campaigns: {},
            adgroups: {},
            ads: {},
            dateranges: []
        };
    };

    componentDidMount() {
        let dateranges = [
            {
                title: "Last 7 days",
                subtitle: ("From " + moment().subtract(7, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "7_days",
                disabled: false
            },
            {
                title: "Last 10 days",
                subtitle: ("From " + moment().subtract(10, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "10_days",
                disabled: false
            },
            {
                title: "Last 14 days",
                subtitle: ("From " + moment().subtract(14, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "14_days",
                disabled: false
            },
            {
                title: "last 20 days",
                subtitle: ("From " + moment().subtract(20, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "20_days",
                disabled: false
            },
            {
                title: "Last 30 days",
                subtitle: ("From " + moment().subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "30_days",
                disabled: false
            },
            {
                title: "Last week",
                subtitle: ("From " + moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_week",
                disabled: false
            },
            {
                title: "Last month",
                subtitle: ("From " + moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_month",
                disabled: false
            },
            {
                title: "This month",
                subtitle: ("From " + moment().startOf('month').format("YYYY-MM-DD") + " To " + (moment().subtract(1, 'days').format("DD") == "01" ? moment().format("YYYY-MM-DD") : moment().subtract(1, 'days').format("YYYY-MM-DD"))),
                icon: "calendar",
                value: "this_month",
                disabled: false
            },
            {
                title: "Custom dates",
                subtitle: "Add custom start date and end date",
                icon: "calendar",
                value: "custom_dates",
                disabled: false
            }
        ];
        this.setState({
            dateranges: dateranges,
            tab: this.props.tab ? this.props.tab : {},
            channels: this.props.selectedChannels ? this.props.selectedChannels : {},
            accounts: this.props.selectedAccounts ? this.props.selectedAccounts : {},
            campaigns: this.props.selectedCampaigns ? this.props.selectedCampaigns : {},
            adgroups: this.props.selectedAdgroups ? this.props.selectedAdgroups : {},
            ads: this.props.selectedAds ? this.props.selectedAds : {},
            open: this.props.open
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            tab: nextProps.tab ? nextProps.tab : {},
            channels: nextProps.selectedChannels ? nextProps.selectedChannels : {},
            accounts: nextProps.selectedAccounts ? nextProps.selectedAccounts : {},
            campaigns: nextProps.selectedCampaigns ? nextProps.selectedCampaigns : {},
            adgroups: nextProps.selectedAdgroups ? nextProps.selectedAdgroups : {},
            ads: nextProps.selectedAds ? nextProps.selectedAds : {},
            open: nextProps.open
        })
    }

    functions = {
        valid: () => {
            return true;
        },
        dailySelected: () => {
            let daily_selected = false;
            if (this.state.tab.channel_breakdowns) {
                for (let channel in this.state.tab.channel_breakdowns) {
                    for (let breakdown in this.state.tab.channel_breakdowns[channel]) {
                        if (breakdown == "date" || breakdown == "ga:date") {
                            daily_selected = true;
                        }
                    }
                }
            }
            return daily_selected;
        },
        compareFormValidation: () => {
            let amount_of_datasources = 0;
            amount_of_datasources += Object.keys(this.state.channels).length;
            amount_of_datasources += Object.keys(this.state.accounts).length;
            amount_of_datasources += Object.keys(this.state.campaigns).length;
            amount_of_datasources += Object.keys(this.state.adgroups).length;
            amount_of_datasources += Object.keys(this.state.ads).length;
            if ((amount_of_datasources > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart") || (this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart")) {
                return false;
            } else if (this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.chart && this.state.tab.chart.value === "donut") {
                return false;
            } else {
                return true;
            }
        }
    };

    render() {
        return (
            <>
                <div
                    className={(this.functions.valid() ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            this.props.onToggle(this.state.open);
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-semibold text-sm">
                                Select date range
                            </h3>
                            {
                                !(this.state.tab.daterange && this.state.tab.daterange.value) &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    There are <span
                                        className="font-bold ml-1 mr-1">{this.state.dateranges.length}</span> ranges
                                    available
                                </p>
                            }
                            {
                                this.state.tab.daterange && this.state.tab.daterange.value &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    You have selected <span
                                        className="font-bold ml-1 mr-1">{this.state.tab.daterange.title}</span>
                                </p>
                            }
                        </div>
                        {
                            this.functions.valid() &&
                            <div className="flex flex-row">
                                {
                                    (
                                        (this.state.tab.daterange && this.state.tab.daterange.value && this.state.tab.daterange.value !== "custom_dates")
                                        ||
                                        (this.state.tab.daterange && this.state.tab.daterange.value && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)
                                    ) &&
                                    <div
                                        className="mr-2 border-purple-500 border-2 text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    this.props.onToggle(this.state.open);
                                }}
                                    className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="">
                            {
                                this.state.dateranges.filter((item) => {
                                    if (this.state.tab.celltype && this.state.tab.celltype.id === "performance") {
                                        return item.value == "custom_dates";
                                    } else {
                                        return true;
                                    }
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div
                                                className={((this.functions.dailySelected() && item.value == "lifetime") ? "opacity-50 cursor-not-allowed" : "") + " mb-2 flex flex-1 flex-row justify-center"}>
                                                <div onClick={() => {

                                                }}
                                                    className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'}
                                                        size={17} icon={item.icon} />
                                                </div>
                                                <div onClick={() => {
                                                    if (!(this.functions.dailySelected() && item.value == "lifetime")) {
                                                        if (this.state.tab.daterange && this.state.tab.daterange.compare_dates) {
                                                            item.compare_dates = this.state.tab.daterange.compare_dates;
                                                        }
                                                        if (this.state.tab.daterange && this.state.tab.daterange.compare_last_year) {
                                                            item.compare_last_year = this.state.tab.daterange.compare_last_year;
                                                        }
                                                        this.state.tab.daterange = item;
                                                        this.setState({
                                                            tab: this.state.tab
                                                        }, () => {
                                                            this.props.onTab(this.state.tab);
                                                        });
                                                    }
                                                }}
                                                    className={`cursor-pointer h-10 w-10 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center ${this.functions.dailySelected() && item.value == "lifetime" && "cursor-not-allowed"} ${this.state.tab.daterange && this.state.tab.daterange.value === item.value && "border-purple-500"}`}
                                                >
                                                    {
                                                        this.state.tab.daterange && this.state.tab.daterange.value === item.value &&
                                                        <div
                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                    }
                                                </div>
                                                <div className="flex flex-1 flex-col">
                                                    <div className="font-medium text-sm">
                                                        {item.title}
                                                    </div>
                                                    {
                                                        (item.value === "custom_dates" && this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) ?
                                                            (<div className="text-xxs">
                                                                From {this.state.tab.daterange.start_date} to {this.state.tab.daterange.end_date}
                                                            </div>) :
                                                            (<div className="text-xxs">
                                                                {item.subtitle}
                                                            </div>)
                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" &&
                            <div className="w-full border-1.5 rounded-md mt-4 p-4 pb-0 pt-2 bg-custom-input">
                                <SingleDatepicker
                                    large={true}
                                    maxDate={(this.state.tab.celltype && this.state.tab.celltype.id === "performance") ? null : new Date()}
                                    from={this.state.tab.daterange.start_date}
                                    to={this.state.tab.daterange.end_date}
                                    onChange={(data) => {
                                        if (!this.state.tab.daterange) {
                                            this.state.tab.daterange = {};
                                        }
                                        this.state.tab.daterange.start_date = data.start_date;
                                        this.state.tab.daterange.end_date = data.end_date;
                                        this.setState({
                                            tab: this.state.tab
                                        }, () => {
                                            this.props.onTab(this.state.tab);
                                        });
                                    }}
                                />
                            </div>
                        }
                        {
                            !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                            <div className={(this.functions.compareFormValidation() ? "" : "opacity-50 ") + " font-medium text-xs truncate mb-2 mt-5"}>
                                Comparison settings
                            </div>
                        }
                        {
                            !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                            <div className="mb-2">
                                <div className={(this.functions.compareFormValidation() ? "" : "opacity-50 cursor-not-allowed") + " flex flex-row"}>
                                    <div
                                        className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'}
                                            size={17} icon={"trending-up"} />
                                    </div>
                                    <div onClick={() => {
                                        if (this.functions.compareFormValidation()) {
                                            if (!this.state.tab.daterange) {
                                                this.state.tab.daterange = {};
                                                this.state.tab.daterange.compare_dates = { title: "Enabled", value: "enabled" };
                                            } else if (!this.state.tab.daterange.compare_dates) {
                                                this.state.tab.daterange.compare_dates = { title: "Enabled", value: "enabled" }
                                            } else {
                                                delete this.state.tab.daterange.compare_last_year;
                                                delete this.state.tab.daterange.compare_dates;
                                                delete this.state.tab.daterange.compare_dates;
                                            }
                                            this.setState({
                                                tab: this.state.tab
                                            }, () => {
                                                this.props.onTab(this.state.tab);
                                            });
                                        }
                                    }}
                                        className={(this.functions.compareFormValidation() ? "cursor-pointer " : "cursor-not-allowed ") + (this.functions.compareFormValidation() && this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                        {
                                            this.functions.compareFormValidation() &&
                                            this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                                            <FeatherIcon
                                                className={'stroke-current'}
                                                size={16}
                                                icon="check" />
                                        }
                                    </div>
                                    <div className="flex flex-1 flex-col truncate">
                                        <div className="font-medium text-sm truncate">
                                            Compare with preceding period
                                        </div>
                                        <div className="text-xxs">
                                            Evaluate selected time period with previous period
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !this.functions.compareFormValidation() &&
                            <div className="col-span-6 p-3 rounded-md font-medium text-xs text-orange-500 bg-orange-100 mb-2">
                                OBS! Selection is not supported for comparing with preceding period
                            </div>
                        }
                        {
                            this.functions.compareFormValidation() &&
                            this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                            <div className="relative">
                                {
                                    this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                    <div className="bg-white bg-opacity-50 absolute left-0 right-0 bottom-0 top-0"></div>
                                }
                                <div className="flex flex-row">
                                    <div
                                        className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'}
                                            size={17} icon={"clock"} />
                                    </div>
                                    <div onClick={() => {
                                        if (!this.state.tab.daterange.compare_last_year) {
                                            this.state.tab.daterange.compare_last_year = { title: "Enabled", value: "enabled" }
                                        } else {
                                            delete this.state.tab.daterange.compare_last_year;
                                        }
                                        this.setState({
                                            tab: this.state.tab
                                        }, () => {
                                            this.props.onTab(this.state.tab);
                                        });
                                    }}
                                        className={(this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                        {
                                            this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" &&
                                            <FeatherIcon
                                                className={'stroke-current'}
                                                size={16}
                                                icon="check" />
                                        }
                                    </div>
                                    <div className="flex flex-1 flex-col">
                                        <div className="font-medium text-sm">
                                            Same daterange one year ago
                                        </div>
                                        <div className="text-xxs">
                                            Selected period one year ago
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.functions.compareFormValidation() &&
                            this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                            <div className="mt-2 relative">
                                {
                                    this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" &&
                                    <div className="bg-white bg-opacity-50 absolute left-0 right-0 bottom-0 top-0"></div>
                                }
                                <div className="flex flex-row">
                                    <div
                                        className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'}
                                            size={17} icon={"calendar"} />
                                    </div>
                                    <div onClick={() => {
                                        if (!this.state.tab.daterange.compare_custom_date) {
                                            this.state.tab.daterange.compare_custom_date = { title: "Enabled", value: "enabled" }
                                        } else {
                                            delete this.state.tab.daterange.compare_custom_date;
                                        }
                                        this.setState({
                                            tab: this.state.tab
                                        }, () => {
                                            this.props.onTab(this.state.tab);
                                        });
                                    }}
                                        className={(this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                        {
                                            this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                            <FeatherIcon
                                                className={'stroke-current'}
                                                size={16}
                                                icon="check" />
                                        }
                                    </div>
                                    <div className="flex flex-1 flex-col truncate">
                                        <div className="font-medium text-sm">
                                            Custom daterange
                                        </div>
                                        <div className="text-xxs">
                                            {
                                                this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                                this.state.tab.daterange.compare_custom_date.start_date &&
                                                this.state.tab.daterange.compare_custom_date.end_date &&
                                                <span> From {this.state.tab.daterange.compare_custom_date.start_date} to {this.state.tab.daterange.compare_custom_date.end_date}</span>
                                                ||
                                                <span>Select daterange</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                            <div className="w-full border-1.5 rounded-md mt-4 p-4 pt-2 pb-0 bg-custom-input">
                                <SingleDatepicker
                                    large={true}
                                    maxDate={new Date()}
                                    from={this.state.tab.daterange.compare_custom_date.start_date}
                                    to={this.state.tab.daterange.compare_custom_date.end_date}
                                    onChange={(data) => {
                                        this.state.tab.daterange.compare_custom_date.start_date = data.start_date;
                                        this.state.tab.daterange.compare_custom_date.end_date = data.end_date;
                                        this.setState({
                                            tab: this.state.tab
                                        }, () => {
                                            this.props.onTab(this.state.tab);
                                        });
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.tab.daterange && this.state.tab.daterange.value && (this.state.tab.daterange.value !== "custom_dates" || (this.state.tab.daterange.value == "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)) &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} class="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabindex="0">Next</button>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }
}

class SelectCellMetric extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            metrics: [],
            default_metrics: [],
            categories: [{ name: "custom" }, { name: "calculated" }],
            selected_channels_internal: {},
            //categories: [{ name: 'default' }, { name: "custom" }, { name: "calculated" }],
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            search_metric: ""
        };
    };

    componentDidMount() {
        let metrics = { ...(this.props.metrics ? this.props.metrics : {}) }
        this.setState({
            metrics: metrics,
            tab: this.props.tab ? this.props.tab : {},
            open: this.props.open,
            selected_channels_internal: this.props.selectedChannels ? this.props.selectedChannels : {},
            selected_accounts_internal: this.props.selectedAccounts ? this.props.selectedAccounts : {},
            selected_campaigns_internal: this.props.selectedCampaigns ? this.props.selectedCampaigns : {},
            selected_adgroups_internal: this.props.selectedAdgroups ? this.props.selectedAdgroups : {},
            selected_ads_internal: this.props.selectedAdsInternal ? this.props.selectedAdsInternal : {}
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let metrics = { ...(nextProps.metrics ? nextProps.metrics : {}) }
        this.setState({
            metrics: metrics,
            tab: nextProps.tab ? nextProps.tab : {},
            open: nextProps.open,
            selected_channels_internal: nextProps.selectedChannels ? nextProps.selectedChannels : {},
            selected_accounts_internal: nextProps.selectedAccounts ? nextProps.selectedAccounts : {},
            selected_campaigns_internal: nextProps.selectedCampaigns ? nextProps.selectedCampaigns : {},
            selected_adgroups_internal: nextProps.selectedAdgroups ? nextProps.selectedAdgroups : {},
            selected_ads_internal: nextProps.selectedAds ? nextProps.selectedAds : {}
        })
    }

    functions = {
        valid: () => {
            return this.state.tab && this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0;
        },
        color: (item) => {
            let colors = {
                hex: "",
                rgb: ""
            }
            const channelColor = {
                facebook: "#3A5998",
                google: "#4384F4",
                linkedin: "#0D65A1",
                google_analytics: "#e8710a",
                google_analytics_4: "#e8710a",
                google_shopping: "#34A853",
                adform: "#60C1A4",
                bidtheatre: "#f48e22",
                tiktok: "#000000",
                twitter: "#50ABF1",
                snapchat: "#FFFC00",
                bing: "#0C8484",
                dv360: "#34A853",
                google_search_console: "#34A145",
            }
            colors.hex = channelColor[item.channel] || "#3A5998";

            colors.rgb = colors.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                , (m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1).match(/.{2}/g)
                .map(x => parseInt(x, 16));
            return colors;
        },
        getMetrics: () => {
            this.setState({
                loading: true
            }, () => {
                this.calls.getMetrics().then((response) => {
                    let metrics = { ...(response.data ? response.data : {}) }
                    this.setState({
                        metrics: metrics,
                        loading: true
                    });
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        },
        search: (search, options) => {
            if (!search || search == "") {
                return options;
            }
            let result = fuzzysort.go(search, options.map((item) => { return item.name }));
            let keywords = [];
            let old_keywords = JSON.parse(JSON.stringify(options));
            result.map((item) => {
                for (let i = 0; i < old_keywords.length; i++) {
                    if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                        let html_string = "";
                        if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                            for (let m = 0; m < item.target.length; m++) {
                                if (!item.indexes.includes(m)) {
                                    html_string = html_string + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                } else {
                                    html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                }
                            }
                        }
                        old_keywords[i].html = html_string;
                        keywords.push(old_keywords[i]);
                        old_keywords.splice(i, 1);
                        break;
                    }
                }
            });
            return keywords;
        },
        calculatedMetric: (metric) => {
            let new_string = JSON.parse(JSON.stringify(metric.calculation));
            for (let key in metric.metrics) {
                new_string = new_string.replace("<" + key + ">", metric.metrics[key].name);
            }
            return new_string;
        }
    };

    calls = {
        getMetrics: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v2/metrics?custom=true";
            return apiRegister.call(options, url);
        },
    };

    render() {
        return (
            <>
                <div
                    className={"px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            this.props.onToggle(this.state.open);
                        }} className="flex flex-col flex-1 cursor-pointer truncate">
                            <h3 className="mb-0 font-semibold text-sm">
                                Select metrics
                            </h3>
                            {
                                this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0 &&
                                <div className="text-xxs truncate pr-3">
                                    You have selected <span className="font-bold">{Object.keys(this.state.tab.metrics).join(", ")}</span>
                                </div>
                                ||
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Choose between default, custom and calculated
                                </p>
                            }
                        </div>
                        {
                            <div className="flex flex-row">
                                {
                                    this.functions.valid() &&
                                    <div
                                        className="mr-2 text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    this.props.onToggle(this.state.open);
                                }}
                                    className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="flex flex-1 mb-4 flex-col justify-center">
                            <InputTailwind
                                label={null}
                                placeholder={"Search metrics ..."}
                                value={this.state.search_metric}
                                onChange={(value) => {
                                    this.setState({
                                        search_metric: value
                                    });
                                }}
                            />
                        </div>
                        <div className="">
                            {
                                this.state.categories.map((category) => {
                                    return (
                                        <Fragment>
                                            <div className="">
                                                <div
                                                    className={"cursor-pointer flex flex-1 flex-row justify-center mb-2"}>
                                                    <div onClick={() => {
                                                        category.open = !category.open;
                                                        this.setState({
                                                            categories: this.state.categories
                                                        })
                                                    }}
                                                        className="text-purple-500 mr-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                                        {
                                                            !category.open &&
                                                            <FeatherIcon className={'stroke-current'} size={16}
                                                                icon={"plus"} />
                                                        }
                                                        {
                                                            category.open &&
                                                            <FeatherIcon className={'stroke-current'} size={16}
                                                                icon={"minus"} />
                                                        }
                                                    </div>
                                                    <div className="flex flex-1 flex-col truncate">
                                                        <div className="font-medium text-sm capitalize">
                                                            {category.name}
                                                        </div>
                                                        {
                                                            this.state.tab.metrics && this.state.metrics[category.name] && this.state.metrics[category.name].filter((item) => { return this.state.tab.metrics[item.name] }).length > 0 &&
                                                            <div className="text-xxs truncate">
                                                                You have selected <span className="font-bold">{Object.keys(this.state.tab.metrics).filter((metric_name) => { return this.state.metrics[category.name].filter((inner_item) => { return inner_item.name == metric_name }).length > 0 }).join(", ")}</span>
                                                            </div>
                                                            ||
                                                            <div className="text-xxs">
                                                                Your have {this.state.metrics[category.name] && this.state.metrics[category.name].length} metrics available
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <SlideDown closed={!category.open && this.state.search_metric == ""}>
                                                    {
                                                        this.state.metrics[category.name] && this.functions.search(this.state.search_metric, this.state.metrics[category.name]).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div className="flex flex-row pl-12">
                                                                        <div
                                                                            className={"cursor-pointer mb-2 flex flex-1 flex-row justify-center"}>
                                                                            {
                                                                                ((this.state.tab.celltype && this.state.tab.celltype.value === "number") || (this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.chart && this.state.tab.chart.value === "donut")) &&
                                                                                (
                                                                                    <div onClick={() => {

                                                                                        if (this.state.tab.metrics) {
                                                                                            this.state.tab.metrics = {};
                                                                                        }

                                                                                        //SET COLORS FOR EACH DATASOURCE
                                                                                        if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                                                                                            for (let key in this.state.selected_accounts_internal) {
                                                                                                if (!this.state.selected_accounts_internal[key].metric_color) {
                                                                                                    this.state.selected_accounts_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_accounts_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_accounts_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                                                                                            for (let key in this.state.selected_campaigns_internal) {
                                                                                                if (!this.state.selected_campaigns_internal[key].metric_color) {
                                                                                                    this.state.selected_campaigns_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_campaigns_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_campaigns_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                                                                                            for (let key in this.state.selected_adgroups_internal) {
                                                                                                if (!this.state.selected_adgroups_internal[key].metric_color) {
                                                                                                    this.state.selected_adgroups_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_adgroups_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_adgroups_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                                                                                            for (let key in this.state.selected_ads_internal) {
                                                                                                if (!this.state.selected_ads_internal[key].metric_color) {
                                                                                                    this.state.selected_ads_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_ads_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_ads_internal[key]);
                                                                                            }
                                                                                        }

                                                                                        this.state.tab.metrics[item.name] = item;
                                                                                        this.props.onTab(this.state.tab);
                                                                                        if (!(this.state.tab.celltype && this.state.tab.celltype.id === "performance")) {
                                                                                            this.props.onNext();
                                                                                        }

                                                                                    }}
                                                                                        className={(this.state.tab.metrics && this.state.tab.metrics[item.name] ? "border-purple-500" : "") + " h-10 w-10 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                        {
                                                                                            this.state.tab.metrics && this.state.tab.metrics[item.name] &&
                                                                                            <div
                                                                                                className="w-full h-full rounded-full bg-purple-500" />
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                                ||
                                                                                (
                                                                                    <div onClick={() => {
                                                                                        if (!this.state.tab.metrics) {
                                                                                            this.state.tab.metrics = {};
                                                                                        }
                                                                                        if (!this.state.tab.table_metrics_index) {
                                                                                            this.state.tab.table_metrics_index = [];
                                                                                        }
                                                                                        if (item.name in this.state.tab.metrics) {
                                                                                            delete this.state.tab.metrics[item.name];
                                                                                            this.state.tab.table_metrics_index = this.state.tab.table_metrics_index.filter((metric) => {
                                                                                                return metric.name !== item.name
                                                                                            });
                                                                                        } else {
                                                                                            item.metric_type = "custom";
                                                                                            this.state.tab.metrics[item.name] = item;
                                                                                            this.state.tab.table_metrics_index.push(item);
                                                                                        }

                                                                                        //SET COLORS FOR EACH DATASOURCE
                                                                                        if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                                                                                            for (let key in this.state.selected_accounts_internal) {
                                                                                                if (!this.state.selected_accounts_internal[key].metric_color) {
                                                                                                    this.state.selected_accounts_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_accounts_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_accounts_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                                                                                            for (let key in this.state.selected_campaigns_internal) {
                                                                                                if (!this.state.selected_campaigns_internal[key].metric_color) {
                                                                                                    this.state.selected_campaigns_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_campaigns_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_campaigns_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                                                                                            for (let key in this.state.selected_adgroups_internal) {
                                                                                                if (!this.state.selected_adgroups_internal[key].metric_color) {
                                                                                                    this.state.selected_adgroups_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_adgroups_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_adgroups_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                                                                                            for (let key in this.state.selected_ads_internal) {
                                                                                                if (!this.state.selected_ads_internal[key].metric_color) {
                                                                                                    this.state.selected_ads_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_ads_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_ads_internal[key]);
                                                                                            }
                                                                                        }

                                                                                        this.props.onTab(this.state.tab);
                                                                                    }}
                                                                                        className={(this.state.tab.metrics && this.state.tab.metrics[item.name] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                        {
                                                                                            this.state.tab.metrics && this.state.tab.metrics[item.name] &&
                                                                                            <FeatherIcon
                                                                                                className={'stroke-current'}
                                                                                                size={16}
                                                                                                icon="check" />
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            <div
                                                                                className="flex flex-1 flex-col">
                                                                                <div
                                                                                    className="font-medium text-sm">
                                                                                    {item.name}
                                                                                </div>
                                                                                <div className="text-xxs capitalize flex flex-wrap">
                                                                                    {
                                                                                        (category.name == "custom" || category.name == "default") &&
                                                                                        item.channels &&
                                                                                        Object.keys(item.channels).map((channel, index) => {
                                                                                            let last = index === (Object.keys(item.channels).length - 1);
                                                                                            return (<span className="mr-1">{channel.replace("_", " ").replace("_", " ")}{!last ? "," : ""}</span>)
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        category.name == "calculated" &&
                                                                                        <span>{this.functions.calculatedMetric(item)}</span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.tab.celltype && this.state.tab.celltype.id === "performance" &&
                                                                        this.state.tab.metrics && this.state.tab.metrics[item.name] &&
                                                                        <div className="w-full pl-12 mb-4">
                                                                            <InputTailwind
                                                                                label={item.name + " Target"}
                                                                                value={this.state.tab.settings && this.state.tab.settings.performance_target ? this.state.tab.settings.performance_target[item.name] : ""}
                                                                                onChange={(value) => {
                                                                                    if (!this.state.tab.settings) {
                                                                                        this.state.tab.settings = { title: "" };
                                                                                    }
                                                                                    if (!this.state.tab.settings.performance_target) {
                                                                                        this.state.tab.settings.performance_target = {};
                                                                                    }
                                                                                    this.state.tab.settings.performance_target[item.name] = value;
                                                                                    this.props.onTab(this.state.tab);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    }
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </SlideDown>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0 &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} class="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabindex="0">Next</button>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }
}

class SelectCellSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            table_default: [
                {
                    title: "preview",
                    description: "Show preview of ad",
                    value: "remove_preview"
                },
                {
                    title: "channel",
                    description: "Shows channel of datasource",
                    value: "remove_channel"
                },
                {
                    title: "breakdown",
                    description: "Show data breakdown",
                    value: "remove_breakdown"
                },
                {
                    title: "breakdown value",
                    description: "Show data breakdown value",
                    value: "remove_breakdown_value"
                },
                {
                    title: "level",
                    description: "Shows if datasource is campaign, adgroup or ad",
                    value: "remove_level"
                },
                {
                    title: "id",
                    description: "Shows id of datasource",
                    value: "remove_id"
                },
                {
                    title: "name",
                    description: "Shows name of datasource",
                    value: "remove_name"
                }
            ],
            placements: [
                { id: 0, name: "Feed", description: "Ads that appear in a user's scrollable content", value: "feed", icon: <ViewBoardsIcon style={{ width: 14, height: 14, transform: 'rotate(-90deg)' }} />, allowed: { facebook: true, linkedin: true, tiktok: true, twitter: true } },
                { id: 3, name: "Feed (Instagram)", description: "Ads that appear in a users scrollable content on Instagram", icon: <ViewBoardsIcon style={{ width: 14, height: 14, transform: 'rotate(-90deg)' }} />, value: "feed_instagram", allowed: { facebook: true } },
                { id: 1, name: "Story", description: "Ads that appear in a user's swipable content", value: "story", icon: <ViewBoardsIcon style={{ width: 14, height: 14 }} />, allowed: { facebook: true, snapchat: true } },
                { id: 4, name: "Story (Instagram)", description: "Ads that appear in a users swipable content on Instagram", icon: <ViewBoardsIcon style={{ width: 14, height: 14 }} />, value: "story_instagram", allowed: { facebook: true } },
                { id: 2, name: "Remove frame", description: "Remove ad frame and only show media", value: "no_frame", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, allowed: { facebook: true, linkedin: true, tiktok: true, twitter: true, snapchat: true } },
                { id: 5, name: "Square", description: "Display ad in square format", value: "square", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, allowed: { google: true } },
                { id: 6, name: "Billboard", description: "Display ad in rectangle format", value: "billboard", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, allowed: { google: true } },
                { id: 6, name: "Skyscraper", description: "Display ad in vertical format", value: "skyscraper", icon: <PhotographIcon style={{ width: 14, height: 14 }} />, allowed: { google: true } },
            ],
            custom_color_string: "",
            selectedId: ""
        };
    };

    async componentDidMount() {
        await this.promisedSetState({
            tab: this.props.tab,
            open: this.props.open,
            master_template: this.props.masterTemplate,
            selected_channels_internal: this.props.selectedChannelsInternal ? this.props.selectedChannelsInternal : {},
            selected_accounts_internal: this.props.selectedAccountsInternal ? this.props.selectedAccountsInternal : {},
            selected_campaigns_internal: this.props.selectedCampaignsInternal ? this.props.selectedCampaignsInternal : {},
            selected_adgroups_internal: this.props.selectedAdgroupsInternal ? this.props.selectedAdgroupsInternal : {},
            selected_ads_internal: this.props.selectedAdsInternal ? this.props.selectedAdsInternal : {},
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            tab: nextProps.tab,
            open: nextProps.open,
            master_template: nextProps.masterTemplate,
            selected_channels_internal: nextProps.selectedChannelsInternal ? nextProps.selectedChannelsInternal : {},
            selected_accounts_internal: nextProps.selectedAccountsInternal ? nextProps.selectedAccountsInternal : {},
            selected_campaigns_internal: nextProps.selectedCampaignsInternal ? nextProps.selectedCampaignsInternal : {},
            selected_adgroups_internal: nextProps.selectedAdgroupsInternal ? nextProps.selectedAdgroupsInternal : {},
            selected_ads_internal: nextProps.selectedAdsInternal ? nextProps.selectedAdsInternal : {},
        });
    }

    functions = {
        valid: () => {
            return true;
        },
        selectedDataSources: () => {
            let sources = [];
            if (this.state.selected_channels_internal) {
                for (let key in this.state.selected_channels_internal) {
                    sources.push(this.state.selected_channels_internal[key]);
                }
            }
            if (this.state.selected_accounts_internal) {
                for (let key in this.state.selected_accounts_internal) {
                    sources.push(this.state.selected_accounts_internal[key]);
                }
            }
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    sources.push(this.state.selected_campaigns_internal[key]);
                }
            }
            if (this.state.selected_adgroups_internal) {
                for (let key in this.state.selected_adgroups_internal) {
                    sources.push(this.state.selected_adgroups_internal[key]);
                }
            }
            if (this.state.selected_ads_internal) {
                for (let key in this.state.selected_ads_internal) {
                    sources.push(this.state.selected_ads_internal[key]);
                }
            }
            return sources;
        },
        selectedMetrics: () => {
            let metrics = [];
            if (this.state.tab && this.state.tab.metrics) {
                for (let metric in this.state.tab.metrics) {
                    metrics.push(this.state.tab.metrics[metric]);
                }
            }
            return metrics;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        {/*TABLE METRICS SORT*/ }
        const SortableItem = SortableElement(({ item, index }) => {
            return (
                <div style={{ zIndex: 99999999 }}
                    className="w-full flex flex-row justify-center cursor-pointer align-middle items-center mb-2">
                    <div
                        className="text-purple-500 text-sm font-medium bg-purple-100 h-10 w-10 flex rounded justify-center align-middle items-center">
                        {item.index + 1}
                    </div>
                    <div className="text-xs flex flex-1 ml-2">
                        <div className="flex flex-1 flex-col">
                            <div className="font-medium text-sm">
                                {item.alias ? item.alias : item.name}
                            </div>
                            <div className="text-xxs capitalize">
                                {item.calculated ? "Calculated metric" : "Custom metric"}
                            </div>
                        </div>
                    </div>
                    <div
                        className="text-purple-500 text-xs cursor-pointer bg-purple-100 h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                        <FeatherIcon className="stroke-current transform rotate-90"
                            size={16}
                            icon={'code'}
                        />
                    </div>
                </div>
            )
        });
        const SortableList = SortableContainer(({ items }) => (
            <div className="">
                {items.map((item, index) => {
                    item.index = index;
                    if (item) {
                        return (
                            <SortableItem
                                index={index}
                                item={item}
                            />
                        )
                    }
                })}
            </div>
        ));
        const onSortEnd = ({ oldIndex, newIndex }) => {
            if (!Array.isArray(this.state.tab.table_metrics_index)) {
                this.state.tab.table_metrics_index = [];
            }
            this.state.tab.table_metrics_index = arrayMove(this.state.tab.table_metrics_index, oldIndex, newIndex);
            this.props.onTab(this.state.tab);
        };

        return (
            <>
                <div
                    className={(this.functions.valid() ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            this.props.onToggle(this.state.open);
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-semibold text-sm">
                                Settings
                            </h3>
                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                {this.state.tab.celltype && this.state.tab.celltype.value === "preview" ? "Select ad placement" : "Select color of each data source and edit data"}
                            </p>
                        </div>
                        {
                            this.functions.valid() &&
                            <div className="flex flex-row">
                                {
                                    this.functions.valid() &&
                                    <div
                                        className="mr-2 text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    this.props.onToggle(this.state.open);
                                }}
                                    className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                            icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        {
                            this.state.tab.celltype && (this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "number" || this.state.tab.celltype.value === "table") &&
                            <div className="grid grid-cols-12 gap-4">
                                <div className={`col-span-${(this.state.tab.celltype.value === "number" && !(this.state.tab.celltype && this.state.tab.celltype.id === "performance")) ? '6' : '12'}`}>
                                    <div className="flex flex-1 flex-col mb-4 justify-center">
                                        <InputTailwind
                                            label={"Cell title"}
                                            value={this.state.tab.settings ? this.state.tab.settings.title : ""}
                                            onChange={(value) => {
                                                if (!this.state.tab.settings) {
                                                    this.state.tab.settings = { title: "" };
                                                }
                                                this.state.tab.settings.title = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                </div>
                                {
                                    this.state.tab.celltype.value === "number" &&
                                    !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                                    <div className="col-span-6">
                                        <DropdownTailwind
                                            label={"Text align"}
                                            selected={this.state.tab.settings && this.state.tab.settings.align && this.state.tab.settings.align.value ? this.state.tab.settings.align : {
                                                id: 1,
                                                title: "Center",
                                                name: "Center",
                                                value: "center"
                                            }}
                                            options={[
                                                { id: 1, title: "Center", name: "Center", value: "center" },
                                                { id: 2, title: "Right", name: "Right", value: "end" },
                                                { id: 3, title: "Left", name: "Left", value: "left" }
                                            ]}
                                            onChange={(value) => {
                                                this.state.tab.settings.align = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "number" &&
                            !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                            <div>
                                <div className="grid grid-cols-12 gap-4 relative">
                                    <div className="col-span-6">
                                        <Popover>
                                            {({ open, close }) => {
                                                return (
                                                    <div className="flex flex-1 flex-col mb-4 justify-center">
                                                        <div className="text-xs font-medium mb-1">
                                                            Icon
                                                        </div>
                                                        <div className="flex truncate">
                                                            <Popover.Button
                                                                className={`w-full border-1.5 flex items-center truncate focus:outline-none px-2 py-3 text-sm pl-3 font-medium rounded-md bg-gray-50 cursor-pointer`}>
                                                                {this.state.tab.settings && this.state.tab.settings.icon ? this.state.tab.settings.icon.name : "Click to select"}
                                                            </Popover.Button>
                                                            {
                                                                this.state.tab.settings && this.state.tab.settings.icon &&
                                                                <button
                                                                    onClick={() => {
                                                                        delete this.state.tab.settings.icon;
                                                                        this.props.onTab(this.state.tab);
                                                                    }}
                                                                    className="ml-2 rounded-md border bg-red-500 text-white flex flex-row items-center justify-center font-bold text-xs cursor-pointer min-w-12 min-h-12 h-12 w-12">
                                                                    <FeatherIcon
                                                                        size={16}
                                                                        icon="trash" />
                                                                </button>
                                                            }
                                                        </div>
                                                        <Popover.Panel
                                                            transition
                                                            style={{ top: "70px" }}
                                                            className="origin-top-right absolute w-full flex overflow-hidden z-50 border-1.5 right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div
                                                                className="flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                                                <IconPicker
                                                                    filter={this.state.tab.settings && this.state.tab.settings.icon ? this.state.tab.settings.icon.name : null}
                                                                    onSelect={(val) => {
                                                                        if (!this.state.tab.settings) {
                                                                            this.state.tab.settings = { title: "" }
                                                                        }
                                                                        if (this.state.tab.settings.icon) {
                                                                            this.state.tab.settings.icon.name = val.name;
                                                                            this.state.tab.settings.icon.path = val.path;
                                                                        } else {
                                                                            this.state.tab.settings.icon = {
                                                                                name: val.name,
                                                                                path: val.path,
                                                                                size: {
                                                                                    id: 2,
                                                                                    title: "Medium",
                                                                                    name: "Medium",
                                                                                    value: "medium"
                                                                                }
                                                                            };
                                                                        }
                                                                        this.props.onTab(this.state.tab);
                                                                        close();
                                                                    }}>
                                                                </IconPicker>
                                                            </div>
                                                        </Popover.Panel>
                                                    </div>
                                                )
                                            }}
                                        </Popover>
                                    </div>
                                    <div className="col-span-6 relative">
                                        <DropdownTailwind
                                            label={"Icon size"}
                                            selected={this.state.tab.settings && this.state.tab.settings.icon ? this.state.tab.settings.icon.size : { id: 0, name: "Select size" }}
                                            options={[
                                                { id: 1, title: "Small", name: "Small", value: "small" },
                                                { id: 2, title: "Medium", name: "Medium", value: "medium" },
                                                { id: 3, title: "Large", name: "Large", value: "large" }
                                            ]}
                                            onChange={(value) => {
                                                this.state.tab.settings.icon.size = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "number" &&
                            this.state.tab.celltype && this.state.tab.celltype.id !== "performance" &&
                            <div className="mb-4 w-full grid grid-cols-12 gap-4">
                                <div
                                    className={this.state.tab.settings && this.state.tab.settings.customNumber ? "col-span-6" : "col-span-12"}>
                                    <div className="text-xs font-medium mb-1">
                                        Custom value
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm">
                                                {this.state.tab.settings && this.state.tab.settings.customNumber ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.tab.settings && this.state.tab.settings.customNumber ? true : false}
                                                    onSwitch={async () => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        this.state.tab.settings.customNumberValue = "";
                                                        this.state.tab.settings.customNumber = !this.state.tab.settings.customNumber;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.tab.settings && this.state.tab.settings.customNumber &&
                                    <div className="col-span-6">
                                        <div className="text-xs text-opacity-0 font-medium">
                                            Value
                                        </div>
                                        <div className='flex flex-col w-full'>
                                            <InputTailwind
                                                disabled={!(this.state.tab.settings && this.state.tab.settings.customNumber)}
                                                value={this.state.tab.settings.customNumberValue}
                                                label=''
                                                placeholder='number'
                                                onChange={(value) => {
                                                    this.state.tab.settings.customNumberValue = value;
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                            <div className="grid grid-cols-12 gap-4 mb-4">
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Show inline values"}
                                        selected={this.state.tab.settings && this.state.tab.settings.chart_numbers && this.state.tab.settings.chart_numbers.value ? this.state.tab.settings.chart_numbers : { id: 1, title: "Disabled", name: "Disabled", subtitle: "Inline values will only show on hover", value: "disabled", default: true }}
                                        options={[
                                            { id: 1, title: "Disabled", name: "Disabled", subtitle: "Inline values will only show on hover", value: "disabled", default: true },
                                            { id: 2, title: "Enabled", name: "Enabled", subtitle: "Inline values will be visible", value: "enabled" }
                                        ]}
                                        onChange={(value) => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.chart_numbers = value;
                                            this.props.onTab(this.state.tab);
                                        }}
                                    />
                                </div>
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Show datasources"}
                                        selected={this.state.tab.settings && this.state.tab.settings.show_datasources && this.state.tab.settings.show_datasources.value ? this.state.tab.settings.show_datasources : { id: 2, title: "Enabled", name: "Enabled", subtitle: "Datasources values will be visible", value: "enabled", default: true }}
                                        options={[
                                            { id: 1, title: "Disabled", name: "Disabled", subtitle: "Datasources wont show above chart", value: "disabled" },
                                            { id: 2, title: "Enabled", name: "Enabled", subtitle: "Datasources values will be visible", value: "enabled", default: true }
                                        ]}
                                        onChange={(value) => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.show_datasources = value;
                                            this.props.onTab(this.state.tab);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        {
                            this.state.tab.celltype && (this.state.tab.celltype.value === "chart") &&
                            <div className="grid grid-cols-12 gap-4 mb-4">
                                {
                                    //((this.state.tab.chart && this.state.tab.chart.value === "bar" && this.functions.selectedDataSources().length < 2) || (this.state.tab.chart && this.state.tab.chart.value == "donut")) &&
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            label={"Sort"}
                                            selected={this.state.tab.settings && this.state.tab.settings.chart_sort && this.state.tab.settings.chart_sort.value ? this.state.tab.settings.chart_sort : { id: 1, title: "No sort", name: "No sort", subtitle: "Dont sort chart", value: "none", default: true }}
                                            options={[
                                                { id: 1, title: "No sort", name: "No sort", subtitle: "Dont sort chart", value: "none", default: true },
                                                { id: 2, title: "Descending", name: "Descending", subtitle: "Sort values by highest to lowest", value: "descending" },
                                                { id: 3, title: "Ascending", name: "Ascending", subtitle: "Sort values by lowest to highest", value: "ascending" }
                                            ]}
                                            onChange={(value) => {
                                                if (!this.state.tab.settings) {
                                                    this.state.tab.settings = { title: "" };
                                                }
                                                this.state.tab.settings.chart_sort = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                }
                                <div className="col-span-4">
                                    <InputTailwind
                                        label={"Labels limit (number)"}
                                        type={"number"}
                                        value={this.state.tab.settings ? this.state.tab.settings.label_limit : ""}
                                        onChange={(value) => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.label_limit = value;
                                            this.props.onTab(this.state.tab);
                                        }}
                                    />
                                </div>
                                <div className="col-span-4">
                                    <InputTailwind
                                        label={"Character limit (number)"}
                                        type={"number"}
                                        value={this.state.tab.settings ? this.state.tab.settings.limit_characters : ""}
                                        onChange={(value) => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.limit_characters = value;
                                            this.props.onTab(this.state.tab);
                                        }}
                                    />
                                </div>
                                {
                                    <div className="bg-orange-100 rounded-md col-span-12 text-orange-500 p-4 text-sm font-medium">
                                        Sort and label limit are not possible if more than 1 datasources is shown in chart 
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                            <div className="w-full mb-4">
                                <div className="text-xs font-medium mb-1">
                                    Single default chart color
                                </div>
                                <div className={"w-full border-1.5 items-center justify-center flex flex-row rounded-md"}>
                                    <div className="ml-4">
                                        <SwitchTailwind
                                            value={this.state.tab.settings && this.state.tab.settings.use_default_color ? true : false}
                                            onSwitch={async () => {
                                                if (!this.state.tab.settings) {
                                                    this.state.tab.settings = { title: "" };
                                                }
                                                this.state.tab.settings.default_color = "453FF1";
                                                this.state.tab.settings.use_default_color = !this.state.tab.settings.use_default_color;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                    <div className='overflow-hidden flex flex-1 rounded-md relative'>
                                        {
                                            !(this.state.tab.settings && this.state.tab.settings.use_default_color) &&
                                            <div className="absolute right-0 left-0 top-0 bottom-0 bg-white bg-opacity-75 cursor-not-allowed"></div>
                                        }
                                        <div
                                            style={{ backgroundColor: "#fcfcfc" }}
                                            className="flex items-center flex-1 p-1">
                                            <span className={`pl-3`}>#</span>
                                            <input
                                                id="default_color"
                                                className="w-full border-none p-2 bg-transparent"
                                                type="text"
                                                placeholder=''
                                                value={this.state.tab.settings && this.state.tab.settings.default_color ? this.state.tab.settings.default_color : ""}
                                                onClick={(e) => {
                                                    this.setState({ selectedId: e.target.id })
                                                }}
                                                onChange={async (e) => {
                                                    if (!this.state.tab.settings) {
                                                        this.state.tab.settings = { title: "" };
                                                    }
                                                    e.target.value = e.target.value.replace(/#/g, "")
                                                    this.state.tab.settings.default_color = e.target.value;
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                        <div className="relative overflow-hidden w-16 border-l-1.5">
                                            {
                                                (this.state.tab.settings && this.state.tab.settings.use_default_color) &&
                                                <input
                                                    style={{ width: "100%", height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.default_color ? this.state.tab.settings.default_color : "ffffff")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.default_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !(this.state.tab.settings && this.state.tab.settings.use_default_color) &&
                            this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                            <div className="w-full">
                                <DropdownTailwind
                                    label={"Multiple chart colors"}
                                    selected={this.state.tab.settings && this.state.tab.settings.chart_colors && this.state.tab.settings.chart_colors.value ? this.state.tab.settings.chart_colors : { id: 1, title: "Datasource/Metric", name: "Set color by Datasource/Metric", value: "datasource_metric", default: true }}
                                    options={[
                                        { id: 1, title: "Datasource/Metric", name: "Set color by Datasource/Metric", value: "datasource_metric", default: true },
                                        { id: 2, title: "Metric", name: "Set color by Metric", value: "metric" }
                                    ]}
                                    onChange={(value) => {
                                        if (!this.state.tab.settings) {
                                            this.state.tab.settings = { title: "" };
                                        }
                                        this.state.tab.settings.chart_colors = value;
                                        this.props.onTab(this.state.tab);
                                    }}
                                />
                            </div>
                        }
                        {
                            !(this.state.tab.settings && this.state.tab.settings.use_default_color) &&
                            this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                            <div className="mt-4 mb-4">
                                <div className="text-xs font-medium mb-1">
                                    {this.state.tab.settings && this.state.tab.settings.chart_colors ? this.state.tab.settings && this.state.tab.settings.chart_colors.name : "Datasource/Metric"}
                                </div>
                                {
                                    ((this.state.tab.settings && this.state.tab.settings.chart_colors && this.state.tab.settings.chart_colors.value == "metric") ? [{ type: "metric", id: "metric", hex: "#453FF1" }] : this.functions.selectedDataSources()).map((item, i) => {
                                        return (
                                            <Fragment key={i}>
                                                {
                                                    this.functions.selectedMetrics().map((metric, index) => {
                                                        return (
                                                            <Fragment key={i + index}>
                                                                <div
                                                                    className="flex flex-1 flex-row justify-center mb-2">
                                                                    <div onClick={() => {
                                                                        if (metric.setColor) {
                                                                            delete metric.setColor;
                                                                        } else {
                                                                            metric.setColor = i + "_" + index;
                                                                        }
                                                                        this.props.onSelected({
                                                                            selected_channels_internal: this.state.selected_channels_internal,
                                                                            selected_accounts_internal: this.state.selected_accounts_internal,
                                                                            selected_campaigns_internal: this.state.selected_campaigns_internal,
                                                                            selected_adgroups_internal: this.state.selected_adgroups_internal,
                                                                            selected_ads_internal: this.state.selected_ads_internal
                                                                        });
                                                                    }}
                                                                        style={{ backgroundColor: (this.state.tab.settings && this.state.tab.settings.metric_color && this.state.tab.settings.metric_color[item.id] && this.state.tab.settings.metric_color[item.id][metric.id] ? this.state.tab.settings.metric_color[item.id][metric.id].hex : (typeof item.hex === "string" ? "#" + item.hex.replace("#", "") : "#ffffff")) }}
                                                                        className="cursor-pointer mr-2 h-10 w-10 rounded-full text-purple-500 flex justify-center align-middle items-center">
                                                                    </div>
                                                                    <div
                                                                        className="flex flex-1 truncate flex-col">
                                                                        {
                                                                            item.type !== "metric" &&
                                                                            <div
                                                                                className="font-medium truncate text-sm">
                                                                                <span className="text-purple-500 mr-2">{metric.name}:</span>{item.name}
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.type == "metric" &&
                                                                            <div
                                                                                className="font-medium text-sm">
                                                                                <span className="text-purple-500 mr-2">{metric.name}</span>
                                                                            </div>
                                                                        }
                                                                        <div className="text-xxs">
                                                                            {this.state.tab.settings && this.state.tab.settings.metric_color && this.state.tab.settings.metric_color[item.id] && this.state.tab.settings.metric_color[item.id][metric.id] ? this.state.tab.settings.metric_color[item.id][metric.id].hex : item.hex}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    metric.setColor == (i + "_" + index) &&
                                                                    <div className="mt-3">
                                                                        <div className="flex w-full mb-4">
                                                                            <div className="flex flex-col w-full">
                                                                                <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "custom_color_string" ? "border-purple-500" : ""}`}>
                                                                                    <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                                                        <div
                                                                                            style={{ backgroundColor: "#fcfcfc" }}
                                                                                            className="flex items-center col-span-3 p-1">
                                                                                            <span className={`pl-3`}>#</span>
                                                                                            <input
                                                                                                id="custom_color_string"
                                                                                                className={`w-full border-none p-2 bg-transparent`}
                                                                                                type="text"
                                                                                                placeholder='5a67d8'
                                                                                                value={this.state.tab.settings && this.state.tab.settings.metric_color && this.state.tab.settings.metric_color[item.id] && this.state.tab.settings.metric_color[item.id][metric.id] ? this.state.tab.settings.metric_color[item.id][metric.id].hex.replace(/#/g, "") : item.hex ? item.hex.replace(/#/g, "") : ""}
                                                                                                onClick={(e) => {
                                                                                                    this.setState({ selectedId: e.target.id })
                                                                                                }}
                                                                                                onChange={async (e) => {
                                                                                                    if (!this.state.tab.settings) {
                                                                                                        this.state.tab.settings = { title: '' }
                                                                                                    }
                                                                                                    if (!("metric_color" in this.state.tab.settings)) {
                                                                                                        this.state.tab.settings.metric_color = {};
                                                                                                    }
                                                                                                    if (!(item.id in this.state.tab.settings.metric_color)) {
                                                                                                        this.state.tab.settings.metric_color[item.id] = {};
                                                                                                    }
                                                                                                    this.state.tab.settings.metric_color[item.id][metric.id] = { hex: "#" + e.target.value, rgb: "" };
                                                                                                    this.props.onTab(this.state.tab);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                                                            <input
                                                                                                style={{ width: 300, height: 65, bottom: -10 }}
                                                                                                className="absolute cursor-pointer"
                                                                                                type="color"
                                                                                                value={this.state.tab.settings && this.state.tab.settings.metric_color && this.state.tab.settings.metric_color[item.id] && this.state.tab.settings.metric_color[item.id][metric.id] ? this.state.tab.settings.metric_color[item.id][metric.id].hex : item.hex}
                                                                                                onChange={async (e) => {
                                                                                                    if (!this.state.tab.settings) {
                                                                                                        this.state.tab.settings = { title: '' }
                                                                                                    }
                                                                                                    if (!("metric_color" in this.state.tab.settings)) {
                                                                                                        this.state.tab.settings.metric_color = {};
                                                                                                    }
                                                                                                    if (!(item.id in this.state.tab.settings.metric_color)) {
                                                                                                        this.state.tab.settings.metric_color[item.id] = {};
                                                                                                    }
                                                                                                    this.state.tab.settings.metric_color[item.id][metric.id] = { hex: e.target.value, rgb: "" };
                                                                                                    this.props.onTab(this.state.tab);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <CirclePicker
                                                                            width={"100%"}
                                                                            colors={["#3DEDB0", "#FF5900", "#3A5998", "#4384F4", "#0D65A1", "#50ABF1", "#e8710a", "#34A853", "#60C1A4", "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b", "#000000"]}
                                                                            circleSize={41}
                                                                            onChange={async (value) => {
                                                                                metric.setColor = false;
                                                                                if (!this.state.tab.settings) {
                                                                                    this.state.tab.settings = { title: '' }
                                                                                }
                                                                                if (!("metric_color" in this.state.tab.settings)) {
                                                                                    this.state.tab.settings.metric_color = {};
                                                                                }
                                                                                if (!(item.id in this.state.tab.settings.metric_color)) {
                                                                                    this.state.tab.settings.metric_color[item.id] = {};
                                                                                }
                                                                                this.state.tab.settings.metric_color[item.id][metric.id] = { hex: value.hex, rgb: value.rgb };
                                                                                this.props.onTab(this.state.tab);
                                                                            }}
                                                                        />
                                                                    </div>

                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }

                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                            <div className="flex flex-1 flex-col justify-center mb-4">
                                <div className="text-xs font-medium mb-2">
                                    Table columns
                                </div>
                                <div>
                                    {
                                        this.state.table_default.map((item) => {
                                            return (
                                                <Fragment>
                                                    <div
                                                        className="w-full flex flex-row justify-center cursor-pointer align-middle items-center">
                                                        <div
                                                            className="text-purple-500 text-xs font-bold bg-purple-100 h-10 w-10 flex rounded  justify-center align-middle items-center">
                                                            <FeatherIcon
                                                                className={'stroke-current'}
                                                                size={16}
                                                                icon="lock" />
                                                        </div>
                                                        <div onClick={() => {
                                                            if (!this.state.tab.settings) {
                                                                this.state.tab.settings = {};
                                                            }
                                                            item.open = !item.open;
                                                            if (item.open) {
                                                                this.state.table_default = this.state.table_default.map((inner_item) => {
                                                                    if (inner_item.title !== item.title) {
                                                                        inner_item.open = false;
                                                                    }
                                                                    return inner_item;
                                                                });
                                                            }
                                                            this.setState({
                                                                tab: this.state.tab,
                                                                table_default: this.state.table_default
                                                            });
                                                            this.props.onTab(this.state.tab);
                                                        }}
                                                            className="text-purple-500 ml-2 text-xs font-bold bg-purple-100 h-10 w-10 flex rounded cursor-pointer justify-center align-middle items-center">
                                                            {
                                                                !item.open &&
                                                                <FeatherIcon
                                                                    className={'stroke-current'}
                                                                    size={16}
                                                                    icon="plus" />
                                                            }
                                                            {
                                                                item.open &&
                                                                <FeatherIcon
                                                                    className={'stroke-current'}
                                                                    size={16}
                                                                    icon="minus" />
                                                            }
                                                        </div>
                                                        <div className="text-xs flex flex-1 ml-2">
                                                            <div
                                                                className="flex flex-1 flex-col truncate">
                                                                <div
                                                                    className="font-medium text-sm truncate capitalize">
                                                                    {this.state.tab.settings && this.state.tab.settings[item.value + "_title"] && this.state.tab.settings[item.value + "_title"] !== "" ? this.state.tab.settings[item.value + "_title"] : item.title}
                                                                </div>
                                                                <div className="text-xxs">
                                                                    {item.description}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div onClick={() => {
                                                            this.state.tab[item.value] = !this.state.tab[item.value];
                                                            this.props.onTab(this.state.tab);
                                                        }}
                                                            className={((!this.state.tab[item.value]) ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                            {
                                                                !this.state.tab[item.value] &&
                                                                <FeatherIcon
                                                                    className={'stroke-current'}
                                                                    size={16}
                                                                    icon="check" />
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        item.open &&
                                                        this.state.tab.settings &&
                                                        <div className="w-full mt-2">
                                                            <InputTailwind
                                                                value={this.state.tab.settings[item.value + "_title"]}
                                                                placeholder={"Change column name"}
                                                                onChange={(value) => {
                                                                    if (!this.state.tab.settings) {
                                                                        this.state.tab.settings = {};
                                                                    }
                                                                    this.state.tab.settings[item.value + "_title"] = value;
                                                                    this.props.onTab(this.state.tab);
                                                                }}
                                                            />
                                                            {
                                                                false &&
                                                                <input
                                                                    onChange={(event) => {
                                                                        if (!this.state.tab.settings) {
                                                                            this.state.tab.settings = {};
                                                                        }
                                                                        this.state.tab.settings[item.value + "_title"] = event.target.value;
                                                                        this.props.onTab(this.state.tab);
                                                                    }}
                                                                    placeholder={"Change column name"}
                                                                    value={this.state.tab.settings[item.value + "_title"]}
                                                                    className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                />
                                                            }
                                                        </div>
                                                    }
                                                    <div className="w-full h-2"></div>
                                                </Fragment>

                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    <SortableList
                                        items={Array.isArray(this.state.tab.table_metrics_index) ? this.state.tab.table_metrics_index : []}
                                        onSortEnd={onSortEnd}
                                        axis="y"
                                        distance={1}
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "preview" &&
                            (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length < 1) &&
                            (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length < 1) &&
                            <>
                                {
                                    this.state.placements.filter((placement) => placement.allowed[this.state.selected_ads_internal && this.state.selected_ads_internal[Object.keys(this.state.selected_ads_internal)[0]].channel]).map((item) => {
                                        return (
                                            <div className='cursor-pointer mb-2 flex flex-1'
                                                key={item.id}
                                            >
                                                <div className='text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center'>
                                                    {item.icon}
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        this.state.tab.settings.selected_placement = item;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                    className={`h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center ${this.state.tab.settings && this.state.tab.settings.selected_placement && this.state.tab.settings.selected_placement.value === item.value && "border-purple-500"}`}>
                                                    {
                                                        this.state.tab.settings && this.state.tab.settings.selected_placement && this.state.tab.settings.selected_placement.value === item.value &&
                                                        <div className="w-full h-full rounded-full bg-purple-500" />
                                                    }
                                                </div>
                                                <div className='flex flex-col'>
                                                    <div className='font-bold text-xs'>
                                                        {item.name}
                                                    </div>
                                                    <div className='text-xxs'>
                                                        {item.description}
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </>
                        }

                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                            <div className="grid grid-cols-12 gap-4 mb-4">
                                <div className="col-span-6">
                                    <div className="text-xs font-medium mb-1">
                                        Table header background
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "table_header_background_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#fcfcfc" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="table_header_background_color"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='FFFFFF'
                                                    value={this.state.tab.settings && this.state.tab.settings.table_header_background_color ? this.state.tab.settings.table_header_background_color : "fcfcfc"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.tab.settings.table_header_background_color = e.target.value;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 110, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.table_header_background_color ? this.state.tab.settings.table_header_background_color : "fcfcfc")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.table_header_background_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <div className="text-xs font-medium mb-1">
                                        Table header font
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "table_header_font_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#33333" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="table_header_font_color"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='FFFFFF'
                                                    value={this.state.tab.settings && this.state.tab.settings.table_header_font_color ? this.state.tab.settings.table_header_font_color : "333333"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.tab.settings.table_header_font_color = e.target.value;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 110, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.table_header_font_color ? this.state.tab.settings.table_header_font_color : "333333")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.table_header_font_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                            <div className="grid grid-cols-12 gap-4 mb-4">
                                <div className="col-span-6">
                                    <div className="text-xs font-medium mb-1">
                                        Table rows background
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "table_row_background_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#ffffff" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="table_row_background_color"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='FFFFFF'
                                                    value={this.state.tab.settings && this.state.tab.settings.table_row_background_color ? this.state.tab.settings.table_row_background_color : "ffffff"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.tab.settings.table_row_background_color = e.target.value;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 110, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.table_row_background_color ? this.state.tab.settings.table_row_background_color : "ffffff")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.table_row_background_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <div className="text-xs font-medium mb-1">
                                        Table rows font
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "table_row_font_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#ffffff" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="table_row_font_color"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='333333'
                                                    value={this.state.tab.settings && this.state.tab.settings.table_row_font_color ? this.state.tab.settings.table_row_font_color : "333333"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.tab.settings.table_row_font_color = e.target.value;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 110, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.table_row_font_color ? this.state.tab.settings.table_row_font_color : "333333")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.table_row_font_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.id !== "performance" &&
                            !(this.state.tab.settings && this.state.tab.settings.customNumber && this.state.tab.celltype && this.state.tab.celltype.value === "number") &&
                            this.state.tab.celltype && (this.state.tab.celltype.value === "table" || (this.state.tab.celltype.value === "chart" && (this.state.tab.chart && (this.state.tab.chart.value === "bar" || this.state.tab.chart.value === "line"))) || this.state.tab.celltype.value === "number") &&
                            <div className="mb-4 grid grid-cols-12 gap-4">
                                <div className={this.state.tab.settings && this.state.tab.settings.groupBreakdowns ? "col-span-6" : "col-span-12"}>
                                    <div className="text-xs font-medium mb-1">
                                        Group values
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm">
                                                {this.state.tab.settings && this.state.tab.settings.groupBreakdowns ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.tab.settings && this.state.tab.settings.groupBreakdowns ? true : false}
                                                    onSwitch={async () => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        this.state.tab.settings.groupBreakdowns = !this.state.tab.settings.groupBreakdowns;
                                                        if (this.state.tab.settings.groupBreakdowns) {
                                                            if (this.state.tab.chart && this.state.tab.chart.value === "line") {
                                                                this.state.tab.settings.groupBreakdownsType = { id: 2, title: "Channel", name: "Channel", value: "channel" };
                                                            } else {
                                                                this.state.tab.settings.groupBreakdownsType = { id: 1, title: "Breakdown", name: "Breakdown", value: "breakdown_value", default: true };
                                                            }
                                                            if (!Array.isArray(this.state.tab.settings.groupBreakdownsGroups)) {
                                                                this.state.tab.settings.groupBreakdownsGroups = [];
                                                            }
                                                            if (!(this.state.tab.settings.groupBreakdownsGroups.length > 0 && this.state.tab && this.state.tab.celltype && this.state.tab.celltype.value == "number")) {
                                                                this.state.tab.settings.groupBreakdownsGroups.push({ id: Math.floor((Math.random() * 9999999999) + 1), items: [{ id: Math.floor((Math.random() * 9999999999) + 1), field: "" }] });
                                                                if (this.props.onTab) {
                                                                    this.props.onTab(this.state.tab);
                                                                }
                                                            }
                                                        } else {
                                                            delete this.state.tab.settings.groupBreakdownsGroups;
                                                        }
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.tab.settings && this.state.tab.settings.groupBreakdowns &&
                                    <div className="col-span-6">
                                        <DropdownTailwind
                                            label={"Group by"}
                                            selected={this.state.tab.settings && this.state.tab.settings.groupBreakdownsType && this.state.tab.settings.groupBreakdownsType.value ? this.state.tab.settings.groupBreakdownsType : { id: 1, title: "Breakdown", name: "Breakdown", value: "breakdown_value", default: true }}
                                            options={this.state.tab.celltype.value === "number" ? [{ id: 1, title: "Breakdown", name: "Breakdown", value: "breakdown_value", default: true }] : [
                                                { id: 1, title: "Breakdown", name: "Breakdown", value: "breakdown_value", default: true },
                                                { id: 2, title: "Channel", name: "Channel", value: "channel" },
                                                { id: 3, title: "Datasource name", name: "Datasource name", value: "name" }
                                            ].filter((item) => {
                                                if (this.state.tab.chart && this.state.tab.chart.value === "line") {
                                                    return item.value !== "breakdown_value";
                                                } else {
                                                    return true;
                                                }
                                            })}
                                            onChange={(value) => {
                                                if (!this.state.tab.settings) {
                                                    this.state.tab.settings = { title: "" };
                                                }
                                                this.state.tab.settings.groupBreakdownsType = value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.id !== "performance" &&
                            !(this.state.tab.settings && this.state.tab.settings.customNumber && this.state.tab.celltype && this.state.tab.celltype.value === "number") &&
                            this.state.tab.celltype && (this.state.tab.celltype.value === "table" || (this.state.tab.celltype.value === "chart" && (this.state.tab.chart && (this.state.tab.chart.value === "bar" || this.state.tab.chart.value === "line"))) || this.state.tab.celltype.value === "number") &&
                            this.state.tab.settings && this.state.tab.settings.groupBreakdowns &&
                            <div>
                                {
                                    (Array.isArray(this.state.tab.settings.groupBreakdownsGroups) ? this.state.tab.settings.groupBreakdownsGroups : []).map((group, index) => {
                                        let last = (this.state.tab.settings.groupBreakdownsGroups.length - 1) == index;
                                        return (
                                            <div className="bg-gray-100 mb-2 rounded-md p-4">
                                                {
                                                    this.state.tab && this.state.tab.celltype && this.state.tab.celltype.value !== "number" &&
                                                    <div className="flex flex-1 w-full mb-2">
                                                        <div className="flex flex-1">
                                                            <div className="w-full">
                                                                <InputTailwind
                                                                    label={"Group name"}
                                                                    value={group.name}
                                                                    onChange={(value) => {
                                                                        group.name = value;
                                                                        if (this.props.onTab) {
                                                                            this.props.onTab(this.state.tab);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            index > 0 &&
                                                            last &&
                                                            <div className="ml-2">
                                                                <div className="mb-1 text-xs font-medium text-gray-700">Catch rest</div>
                                                                <div className="h-12 border-1.5 px-4 text-sm font-medium bg-white rounded-md flex items-center justify-center">
                                                                    <SwitchTailwind
                                                                        value={group.catch_all ? true : false}
                                                                        onSwitch={async () => {
                                                                            group.catch_all = !group.catch_all;
                                                                            if (this.props.onTab) {
                                                                                this.props.onTab(this.state.tab);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                                                    this.state.tab.chart && (this.state.tab.chart.value === "bar" || this.state.tab.chart.value === "line") &&
                                                    <div className="w-full mb-2">
                                                        <div className="text-xs font-medium mb-1">
                                                            Color
                                                        </div>
                                                        <div className={"w-full h-12 border-1.5 items-center bg-white justify-center flex flex-row rounded-md"}>
                                                            <div className="ml-4">
                                                                <SwitchTailwind
                                                                    value={group.useColor}
                                                                    onSwitch={async () => {
                                                                        group.color = "453FF1";
                                                                        group.useColor = !group.useColor;
                                                                        this.props.onTab(this.state.tab);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='overflow-hidden flex flex-1 rounded-md relative'>
                                                                {
                                                                    !group.useColor &&
                                                                    <div className="absolute right-0 left-0 z-10 top-0 bottom-0 bg-white bg-opacity-75 cursor-not-allowed"></div>
                                                                }
                                                                <div
                                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                                    className="flex items-center flex-1 p-1 relative">
                                                                    <span className={`pl-3`}>#</span>
                                                                    <input
                                                                        id="default_color"
                                                                        className="w-full border-none p-2 bg-transparent"
                                                                        type="text"
                                                                        placeholder=''
                                                                        value={group.color ? group.color : ""}
                                                                        onClick={(e) => {
                                                                            this.setState({ selectedId: e.target.id })
                                                                        }}
                                                                        onChange={async (e) => {
                                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                                            group.color = e.target.value;
                                                                            this.props.onTab(this.state.tab);
                                                                        }}
                                                                    />
                                                                </div>
                                                                {
                                                                    group.useColor &&
                                                                    <div className="relative overflow-hidden w-16 border-l-1.5">
                                                                        <input
                                                                            style={{ width: "100%", height: 65, bottom: -10 }}
                                                                            className="absolute cursor-pointer"
                                                                            type="color"
                                                                            value={"#" + group.color}
                                                                            onChange={async (e) => {
                                                                                let color_string = e.target.value.slice(1);
                                                                                group.color = color_string;
                                                                                this.props.onTab(this.state.tab);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    !group.catch_all &&
                                                    group.items.map((item, index) => {
                                                        return (
                                                            <div className="mb-2 flex">
                                                                <div className="flex flex-1">
                                                                    <div className="flex flex-2">
                                                                        <div className="w-full">
                                                                            <InputTailwind
                                                                                label={index == 0 ? "Fields" : ""}
                                                                                value={item.field}
                                                                                onChange={(value) => {
                                                                                    item.field = value;
                                                                                    if (this.props.onTab) {
                                                                                        this.props.onTab(this.state.tab);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex flex-1">
                                                                        <div className="w-full ml-2">
                                                                            <DropdownTailwind
                                                                                label={"Match type"}
                                                                                selected={item.match_type && item.match_type.value ? item.match_type : { id: 1, name: "Exact match", value: "exact", default: true }}
                                                                                options={[
                                                                                    { id: 1, name: "Exact match", value: "exact", default: true },
                                                                                    { id: 2, name: "Substring", value: "substring" }
                                                                                ]}
                                                                                onChange={(value) => {
                                                                                    item.match_type = value;
                                                                                    if (this.props.onTab) {
                                                                                        this.props.onTab(this.state.tab);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div onClick={() => {
                                                                    group.items = group.items.filter((inner_item) => {
                                                                        return inner_item.id !== item.id;
                                                                    });
                                                                    if (this.props.onTab) {
                                                                        this.props.onTab(this.state.tab);
                                                                    }
                                                                }} className={(index == 0 ? "mt-5.5" : "mt-1") + " w-12 h-12 ml-2 inline-flex cursor-pointer bg-white border-1.5 rounded-md items-center justify-center"}><TrashIcon className="w-5 text-red-500"></TrashIcon></div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="flex">
                                                    {
                                                        !group.catch_all &&
                                                        <div onClick={() => {
                                                            group.items.push({ id: Math.floor((Math.random() * 9999999999) + 1), field: "" });
                                                            if (this.props.onTab) {
                                                                this.props.onTab(this.state.tab);
                                                            }
                                                        }} className="text-sm font-medium mt-4 inline-flex hover:text-purple-500 cursor-pointer">Add extra field<PlusIcon className="w-5 ml-2" /></div>
                                                    }
                                                    <div className="flex flex-1"></div>
                                                    <div className="flex">
                                                        <div onClick={() => {
                                                            this.state.tab.settings.groupBreakdownsGroups = this.state.tab.settings.groupBreakdownsGroups.filter((inner_item) => {
                                                                return group.id !== inner_item.id;
                                                            });
                                                            if (this.props.onTab) {
                                                                this.props.onTab(this.state.tab);
                                                            }
                                                        }} className="text-sm text-red-500 font-medium mt-4 inline-flex hover:text-red-700 cursor-pointer">Remove group</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    !(Array.isArray(this.state.tab.settings.groupBreakdownsGroups) && this.state.tab.settings.groupBreakdownsGroups.length > 0 && this.state.tab && this.state.tab.celltype && this.state.tab.celltype.value == "number") &&
                                    !(Array.isArray(this.state.tab.settings.groupBreakdownsGroups) && this.state.tab.settings.groupBreakdownsGroups.filter((item) => { return item.catch_all }).length > 0) &&
                                    <div onClick={() => {
                                        if (!Array.isArray(this.state.tab.settings.groupBreakdownsGroups)) {
                                            this.state.tab.settings.groupBreakdownsGroups = [];
                                        }
                                        if (!(this.state.tab.settings.groupBreakdownsGroups.length > 0 && this.state.tab && this.state.tab.celltype && this.state.tab.celltype.value == "number")) {
                                            this.state.tab.settings.groupBreakdownsGroups.push({ id: Math.floor((Math.random() * 9999999999) + 1), items: [{ id: Math.floor((Math.random() * 9999999999) + 1), field: "" }] });
                                            if (this.props.onTab) {
                                                this.props.onTab(this.state.tab);
                                            }
                                        }
                                    }} className="text-sm mb-4 rounded-md font-medium hover:text-purple-500 bg-gray-100 p-4 flex cursor-pointer">Add new group<PlusIcon className="w-5 ml-2" /></div>
                                }
                            </div>
                        }
                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                            <div className="mb-4 grid grid-cols-12 gap-4">
                                <div className={this.state.tab.settings && this.state.tab.settings.maxRowsEnabled ? "col-span-6" : "col-span-12"}>
                                    <div className="text-xs font-medium mb-1">
                                        Table max rows
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm">
                                                {this.state.tab.settings && this.state.tab.settings.maxRowsEnabled ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.tab.settings && this.state.tab.settings.maxRowsEnabled ? true : false}
                                                    onSwitch={async () => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        this.state.tab.settings.maxRows = 100;
                                                        this.state.tab.settings.maxRowsEnabled = !this.state.tab.settings.maxRowsEnabled;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.tab.settings && this.state.tab.settings.maxRowsEnabled &&
                                    <div className="col-span-6">
                                        <div className="text-xs text-opacity-0 font-medium">
                                            Rows
                                        </div>
                                        <div className='flex flex-col w-full'>
                                            <InputTailwind
                                                disabled={!(this.state.tab.settings && this.state.tab.settings.maxRowsEnabled)}
                                                value={this.state.tab.settings && this.state.tab.settings.maxRows ? this.state.tab.settings.maxRows : ""}
                                                label=''
                                                placeholder='rows'
                                                onChange={(value) => {
                                                    this.state.tab.settings.maxRows = value;
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                            <div className="mt-4 mb-4">
                                <div className="w-full">
                                    <DropdownTailwind
                                        label={"Table font size"}
                                        selected={this.state.tab.settings && this.state.tab.settings.table_font_size && this.state.tab.settings.table_font_size.value ? this.state.tab.settings.table_font_size : { id: 1, title: "Small", name: "Small", subtitle: "Small font size (default)", value: "small", default: true }}
                                        options={[
                                            { id: 1, title: "Small", name: "Small", subtitle: "Small font size (default)", value: "small", default: true },
                                            { id: 2, title: "Medium", name: "Medium", subtitle: "Medium font size", value: "medium" }
                                        ]}
                                        onChange={(value) => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.table_font_size = value;
                                            this.props.onTab(this.state.tab);
                                        }}
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.tab.celltype && (this.state.tab.celltype.value === "table" || this.state.tab.celltype.value === "number") &&
                            <div className="mt-4 mb-4">
                                <div className="w-full">
                                    <div className="text-xs font-medium mb-1">
                                        Show text overlay if zero rows
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm">
                                                {this.state.tab.settings && this.state.tab.settings.zeroValueOverlay ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.tab.settings && this.state.tab.settings.zeroValueOverlay ? true : false}
                                                    onSwitch={async () => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        this.state.tab.settings.zeroValueOverlay = !this.state.tab.settings.zeroValueOverlay;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            this.state.tab.celltype && (this.state.tab.celltype.value === "table" || this.state.tab.celltype.value === "number") &&
                            this.state.tab.settings && this.state.tab.settings.zeroValueOverlay &&
                            <div className="mt-4 mb-4">
                                <div className="w-full">
                                    <div className="text-xs -mb-0.5 text-opacity-0 font-medium">
                                        Text (overlay)
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <TextArea
                                            value={this.state.tab.settings && this.state.tab.settings.zeroValueOverlayText ? this.state.tab.settings.zeroValueOverlayText : ""}
                                            label=''
                                            placeholder=''
                                            onChange={(event) => {
                                                this.state.tab.settings.zeroValueOverlayText = event.target.value;
                                                this.props.onTab(this.state.tab);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "number" &&
                            <div className="mb-4">
                                <DropdownTailwind
                                    label={"Font size"}
                                    selected={this.state.tab.settings && this.state.tab.settings.font_size && this.state.tab.settings.font_size.value ? this.state.tab.settings.font_size : { id: 1, title: "Small", name: "Small", subtitle: "Recommended for small cells", value: "small" }}
                                    options={[
                                        { id: 1, title: "Small", name: "Small", subtitle: "Recommended for small cells", value: "small" },
                                        { id: 2, title: "Medium", name: "Medium", subtitle: "Recommended for medium cells", value: "medium" },
                                        { id: 3, title: "Large", name: "Large", subtitle: "Recommended for large cells", value: "large" }
                                    ]}
                                    onChange={(value) => {
                                        if (!this.state.tab.settings) {
                                            this.state.tab.settings = { title: "" };
                                        }
                                        this.state.tab.settings.font_size = value;
                                        this.props.onTab(this.state.tab);
                                    }}
                                />
                            </div>
                        }

                        {
                            this.state.tab.celltype && this.state.tab.celltype.value === "image" &&
                            <div className="mb-4">
                                <DropdownTailwind
                                    label={"Media styling"}
                                    selected={this.state.tab.settings && this.state.tab.settings.media_style && this.state.tab.settings.media_style.value ? this.state.tab.settings.media_style : { id: 1, title: "Small", name: "Small", subtitle: "Small font size (default)", value: "small", default: true }}
                                    options={[
                                        { id: 1, title: "Fill", name: "Fill", subtitle: "The media is resized to fill the given cell", value: "fill" },
                                        { id: 2, title: "Contain", name: "Contain", subtitle: "Resized to fit within the given dimension", value: "contain" },
                                        { id: 3, title: "Cover", name: "Cover", subtitle: "The aspect ratio of the media is preserved", value: "cover" }
                                    ]}
                                    onChange={(value) => {
                                        if (!this.state.tab.settings) {
                                            this.state.tab.settings = { title: "" };
                                        }
                                        this.state.tab.settings.media_style = value;
                                        this.props.onTab(this.state.tab);
                                    }}
                                />
                            </div>
                        }

                        {/* CELL COLORS */}
                        <div className=''>
                            <div class="border-b-2 pb-1 mb-4 text-sm border-gray-600 font-medium pt-2 w-full">Cell styling</div>
                            <div className="grid grid-cols-12 gap-4 mb-4">
                                <div className={"col-span-4"}>
                                    <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Transparent background</div>
                                    <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className={`flex flex-1 font-medium text-xs ml-4`}>
                                            {this.state.tab.settings && this.state.tab.settings.transparent ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.tab.settings && this.state.tab.settings.transparent ? true : false}
                                                onSwitch={async () => {
                                                    if (!this.state.tab.settings) {
                                                        this.state.tab.settings = { title: "" };
                                                    }
                                                    this.state.tab.settings.transparent = !this.state.tab.settings.transparent;
                                                    if (this.state.tab.settings.transparent) {
                                                        this.state.tab.settings.shadow = false;
                                                    }
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    //!(this.state.tab.settings && this.state.tab.settings.transparent) &&
                                    <div className="col-span-4">
                                        <div className="text-xs font-medium mb-1">
                                            Background color
                                        </div>
                                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "background_color" ? "border-purple-500" : ""}`}>
                                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                <div
                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                    className="flex items-center col-span-3 p-1">
                                                    <span className={`pl-3`}>#</span>
                                                    <input
                                                        id="background_color"
                                                        className="w-full border-none p-2 bg-transparent"
                                                        type="text"
                                                        placeholder='FFFFFF'
                                                        value={this.state.tab.settings && this.state.tab.settings.background_color ? this.state.tab.settings.background_color : "ffffff"}
                                                        onClick={(e) => {
                                                            this.setState({ selectedId: e.target.id })
                                                        }}
                                                        onChange={async (e) => {
                                                            if (!this.state.tab.settings) {
                                                                this.state.tab.settings = { title: "" };
                                                            }
                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                            this.state.tab.settings.background_color = e.target.value;
                                                            this.props.onTab(this.state.tab);
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                    <input
                                                        style={{ width: 110, height: 65, bottom: -10 }}
                                                        className="absolute cursor-pointer"
                                                        type="color"
                                                        value={"#" + (this.state.tab.settings && this.state.tab.settings.background_color ? this.state.tab.settings.background_color : "ffffff")}
                                                        onChange={async (e) => {
                                                            if (!this.state.tab.settings) {
                                                                this.state.tab.settings = { title: "" };
                                                            }
                                                            let color_string = e.target.value.slice(1)
                                                            this.state.tab.settings.background_color = color_string;
                                                            this.props.onTab(this.state.tab);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-span-4">
                                    <div className="text-xs font-medium mb-1">
                                        Font color
                                    </div>
                                    <div className={`w-full  border-1.5 rounded-md ${this.state.selectedId === "font_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#fcfcfc" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="font_color"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='000000'
                                                    value={this.state.tab.settings && this.state.tab.settings.font_color ? this.state.tab.settings.font_color : "000000"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.tab.settings.font_color = e.target.value;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 110, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.tab.settings && this.state.tab.settings.font_color ? this.state.tab.settings.font_color : "000000")}
                                                    onChange={async (e) => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.tab.settings.font_color = color_string;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                //!(this.state.tab.settings && this.state.tab.settings.transparent) &&
                                <div className="text-xs font-medium mb-1 mt-4">
                                    Margins
                                </div>
                            }
                            {
                                //!(this.state.tab.settings && this.state.tab.settings.transparent) &&
                                <div className='grid grid-cols-4 w-full gap-4 mb-4'>
                                    <div className="col-span-1">
                                        <div onClick={() => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.disable_margin_top = !this.state.tab.settings.disable_margin_top;
                                            if (this.state.tab.settings.disable_margin_top) {
                                                this.state.tab.settings.shadow = false;
                                            }
                                            this.props.onTab(this.state.tab);
                                        }} className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_top) ? "border-purple-400 text-purple-400 " : "hover:border-gray-500") + ' flex cursor-pointer items-center flex-col h-12 justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md'}>
                                            <div className="flex flex-col items-center justify-center">
                                                <ArrowUpIcon className="w-4 mb-1" />
                                                <div className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_top) ? "bg-purple-400" : "bg-gray-700") + " rounded-md"} style={{ height: "1.5px", width: "20px" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div onClick={() => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.disable_margin_bottom = !this.state.tab.settings.disable_margin_bottom;
                                            if (this.state.tab.settings.disable_margin_bottom) {
                                                this.state.tab.settings.shadow = false;
                                            }
                                            this.props.onTab(this.state.tab);
                                        }} className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_bottom) ? "border-purple-400 text-purple-400 " : "hover:border-gray-500") + ' flex cursor-pointer items-center flex-col h-12 justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md'}>
                                            <div className="flex flex-col items-center justify-center">
                                                <div className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_bottom) ? "bg-purple-400" : "bg-gray-700") + " rounded-md"} style={{ height: "1.5px", width: "20px" }}></div>
                                                <ArrowDownIcon className="w-4 mt-1" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div onClick={() => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.disable_margin_left = !this.state.tab.settings.disable_margin_left;
                                            if (this.state.tab.settings.disable_margin_left) {
                                                this.state.tab.settings.shadow = false;
                                            }
                                            this.props.onTab(this.state.tab);
                                        }} className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_left) ? "border-purple-400 text-purple-400 " : "hover:border-gray-500") + ' flex cursor-pointer items-center flex-col h-12 justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md'}>
                                            <div className="flex flex-row items-center justify-center">
                                                <ArrowLeftIcon className="w-4 mr-1" />
                                                <div className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_left) ? "bg-purple-400" : "bg-gray-700") + " rounded-md"} style={{ height: "20px", width: "1.5px" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div onClick={() => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                            }
                                            this.state.tab.settings.disable_margin_right = !this.state.tab.settings.disable_margin_right;
                                            if (this.state.tab.settings.disable_margin_right) {
                                                this.state.tab.settings.shadow = false;
                                            }
                                            this.props.onTab(this.state.tab);
                                        }} className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_right) ? "border-purple-400 text-purple-400 " : "hover:border-gray-500") + ' flex cursor-pointer items-center flex-col h-12 justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md'}>
                                            <div className="flex flex-row items-center justify-center">
                                                <div className={(!(this.state.tab.settings && this.state.tab.settings.disable_margin_right) ? "bg-purple-400" : "bg-gray-700") + " rounded-md"} style={{ height: "20px", width: "1.5px" }}></div>
                                                <ArrowRightIcon className="w-4 ml-1" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                !(this.state.tab.settings && (this.state.tab.settings.disable_margin_left || this.state.tab.settings.disable_margin_right || this.state.tab.settings.disable_margin_bottom || this.state.tab.settings.disable_margin_top)) &&
                                !(this.state.tab.settings && this.state.tab.settings.transparent) &&
                                <div className="text-xs font-medium mb-1">
                                    Shadow
                                </div>
                            }
                            {
                                !(this.state.tab.settings && (this.state.tab.settings.disable_margin_left || this.state.tab.settings.disable_margin_right || this.state.tab.settings.disable_margin_bottom || this.state.tab.settings.disable_margin_top)) &&
                                !(this.state.tab.settings && this.state.tab.settings.transparent) &&
                                <div className='flex flex-col w-full'>
                                    <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className={`flex flex-1 text-sm ml-5 ${!this.state.public && "opacity-50"}`}>
                                            {this.state.tab.settings && this.state.tab.settings.shadow ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.tab.settings && this.state.tab.settings.shadow ? true : false}
                                                onSwitch={async () => {
                                                    if (!this.state.tab.settings) {
                                                        this.state.tab.settings = { title: "" };
                                                    }
                                                    this.state.tab.settings.shadow = !this.state.tab.settings.shadow;
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </SlideDown >
                </div >
            </>
        )
    }
}


export default AdvancedReportCellSlideIn;